import { ShippingInfo, ShippingMethod } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  shippingMethodModel,
  ShippingMethodModel,
} from 'models/shippingMethods/types';
import { toShippingMethodPrice } from 'models/shippingMethods/utilities';

type ToShippingMethod = (args: {
  shippingMethod: ShippingMethod;
  currency?: string;
}) => ShippingMethodModel | null;

type ShippingInfoToShippingMethod = (args: {
  shippingInfo?: ShippingInfo;
}) => ShippingMethodModel | null;

export const toShippingMethod: ToShippingMethod = ({
  shippingMethod,
  currency,
}) => {
  const price = toShippingMethodPrice({
    shippingRates: shippingMethod.zoneRates.find(z =>
      z.shippingRates.find(s => s.isMatching)
    )?.shippingRates,
    currency,
  });

  const model = {
    id: shippingMethod.id,
    name: shippingMethod.name,
    price,
  } as ShippingMethodModel;

  return pipe(
    shippingMethodModel.decode(model),
    fold(
      reportTypeErrors({
        id: shippingMethod.id,
        model: 'Shipping Methods',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const shippingInfoToShippingMethod: ShippingInfoToShippingMethod = ({
  shippingInfo,
}) => {
  if (!shippingInfo) {
    return null;
  }

  const { centAmount, fractionDigits } = shippingInfo.price;
  const regularPrice = centAmount / Math.pow(10, fractionDigits);
  const discountedPrice = shippingInfo.discountedPrice
    ? shippingInfo.discountedPrice.value.centAmount /
      Math.pow(10, fractionDigits)
    : regularPrice;

  const model = {
    id: shippingInfo.shippingMethod?.id,
    name: shippingInfo.shippingMethodName,
    price: {
      currencyCode: shippingInfo.price.currencyCode,
      regularPrice,
      discountedPrice: discountedPrice === 0 ? 0 : discountedPrice,
    },
  } as ShippingMethodModel;

  return pipe(
    shippingMethodModel.decode(model),
    fold(
      reportTypeErrors({
        id: [shippingInfo.shippingMethod?.id].join(''),
        model: 'Shipping Method',
        fallback: null,
      }),
      returnValidModel
    )
  );
};
