import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DimensionsState } from 'store/dimensions/types';
import { DefaultTheme } from 'styled-components';

export const initialState: DimensionsState = {
  width: undefined,
  height: undefined,
  breakpoint: undefined,
};

const { reducer, actions } = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {
    setBreakpoint(state, action: PayloadAction<keyof DefaultTheme['bpRange']>) {
      state.breakpoint = action.payload;
    },
    setDimensions(
      state,
      action: PayloadAction<
        Omit<DimensionsState, 'defaultOverlayPosition' | 'breakpoint'>
      >
    ) {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },
  },
});

export const { setBreakpoint, setDimensions } = actions;
export default reducer;
