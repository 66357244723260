import * as t from 'io-ts';

export const socialNetwork = t.keyof(
  {
    facebook: null,
    instagram: null,
    linkedin: null,
    pinterest: null,
    spotify: null,
    tiktok: null,
    twitter: null,
  },
  'SocialNetwork'
);

export type SocialNetwork = t.TypeOf<typeof socialNetwork>;

export const socialLinkModel = t.exact(
  t.interface({
    title: t.string,
    link: t.string,
    socialNetwork: socialNetwork,
  }),
  'SocialLink'
);

export type SocialLinkModel = t.TypeOf<typeof socialLinkModel>;
