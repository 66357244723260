import { createCommerceToolsApiClient } from 'lib/commerceTools/clientFactory';
import { CommerceToolsClientInstantiationArguments } from 'lib/commerceTools/types';

const instantiationArguments: CommerceToolsClientInstantiationArguments = {
  authHost: 'https://auth.europe-west1.gcp.commercetools.com',
  httpHost: 'https://api.europe-west1.gcp.commercetools.com',
  projectKey: `${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
  clientId: `${process.env.COMMERCETOOLS_USER_MANAGER_CLIENT_ID}`,
  clientSecret: `${process.env.COMMERCETOOLS_USER_MANAGER_CLIENT_SECRET}`,
};

export const commerceToolsPrivilegedClient = createCommerceToolsApiClient(
  instantiationArguments
);
