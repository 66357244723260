import * as t from 'io-ts';
import { linkModel } from 'models/contentful/link/types';

export const headerModel = t.exact(
  t.interface({
    title: t.string,
    description: t.string,
    actions: t.array(linkModel),
    blockType: t.literal('Header'),
  }),
  'Header'
);

export type HeaderModel = t.TypeOf<typeof headerModel>;
