import { createAsyncThunk } from '@reduxjs/toolkit';
import { joinCampaignRequest } from 'store/campaigns/api';
import { CampaignFormValues } from 'store/campaigns/types';
import { ThunkApi } from 'store/types';

export const joinCampaign = createAsyncThunk<
  void,
  CampaignFormValues,
  ThunkApi
>('campaigns/joinCampaign', async payload => joinCampaignRequest(payload));
