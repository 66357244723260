import { createSelector } from 'reselect';
import { CustomerState } from 'store/customer/types';
import { State } from 'store/types';

export enum CustomerErrorMessages {
  AlreadyExists = 'There is already an existing customer with the provided email.',
}

export const selectCustomer = (state: State): CustomerState => state.customer;

export const selectCustomerUserInformationFields = createSelector(
  selectCustomer,
  (
    customer: CustomerState
  ): Pick<CustomerState, 'email' | 'firstName' | 'lastName'> => ({
    email: customer.email,
    firstName: customer.firstName,
    lastName: customer.lastName,
  })
);

export const selectCustomerAddressIds = createSelector(
  selectCustomer,
  (customer: CustomerState): string[] => customer.addressIds.slice().sort()
);

export const selectCustomerAddressIdsHash = createSelector(
  selectCustomerAddressIds,
  (addressIds: string[]): string => addressIds.join(',')
);

export const selectCustomerErrors = createSelector(
  selectCustomer,
  (cart: CustomerState): CustomerState['errors'] => cart.errors
);

export const selectGlobalCustomerErrors = createSelector(
  selectCustomerErrors,
  (errors: CustomerState['errors']): string[] => {
    const excludedKeys: (keyof CustomerState['errors'])[] = ['createAccount'];

    return Object.entries(errors)
      .filter(
        ([key, message]) =>
          !excludedKeys.includes(key as keyof CustomerState['errors']) &&
          message !== ''
      )
      .map(([, message]) => message);
  }
);

export const selectCustomerAlreadyExistsError = createSelector(
  selectCustomerErrors,
  (errors: CustomerState['errors']): boolean =>
    errors.createAccount === CustomerErrorMessages.AlreadyExists
);
