import { Title } from 'components/Typography';
import styled, { css } from 'styled-components';

const BooleanInputLabel = styled(Title).attrs(() => ({
  variant: '1',
  size: 'XS',
}))<{ hasIcon?: boolean }>(
  ({ hasIcon }) => css`
    z-index: 1;
    flex: 1 1;
    user-select: none;
    min-height: 24px;
    padding-top: 1px;
    padding-left: ${hasIcon ? 12 : 16}px;
    cursor: pointer;
  `
);

export default BooleanInputLabel;
