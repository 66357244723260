import * as t from 'io-ts';
import { linkModel } from 'models/contentful/link/types';
import { storySourceModel } from 'models/contentful/storySource/types';

export const navigationLinkModel = t.interface({
  id: t.string,
  path: t.array(t.string),
  link: linkModel,
});

const navigationImageModel = t.interface({
  title: t.string,
  url: t.string,
  width: t.number,
  height: t.number,
});

const desktopNavigationLevel3Model = t.exact(
  t.intersection([
    navigationLinkModel,
    t.interface({
      primary: t.array(navigationLinkModel),
    }),
  ])
);

const mobileNavigationLevel3Model = t.exact(
  t.intersection([
    navigationLinkModel,
    t.interface({
      level: t.literal(3),
      primary: t.array(navigationLinkModel),
      image: t.union([navigationImageModel, t.null]),
    }),
  ])
);

const mobileNavigationLevel2Model = t.exact(
  t.intersection([
    navigationLinkModel,
    t.interface({
      level: t.literal(2),
      primary: t.array(mobileNavigationLevel3Model),
      image: t.union([navigationImageModel, t.null]),
    }),
  ])
);

const desktopNavigationLevel2Model = t.exact(
  t.intersection([
    navigationLinkModel,
    t.interface({
      primaryDesktopColumnOne: t.array(desktopNavigationLevel3Model),
      primaryDesktopColumnTwo: t.array(desktopNavigationLevel3Model),
      primaryDesktopColumnThree: t.array(desktopNavigationLevel3Model),
      desktopStory: t.union([storySourceModel, t.null]),
      desktopStoryColumnWidth: t.union([t.literal(2), t.literal(3)]),
    }),
  ])
);

export const mobileNavigationModel = t.exact(
  t.interface({
    level: t.literal(1),
    primary: t.array(mobileNavigationLevel2Model),
    secondaryMobile: t.array(linkModel),
    image: t.union([navigationImageModel, t.null]),
  })
);

export const desktopNavigationModel = t.exact(
  t.interface({
    primary: t.array(desktopNavigationLevel2Model),
    image: t.union([navigationImageModel, t.null]),
  })
);

export type DesktopNavigationModel = t.TypeOf<typeof desktopNavigationModel>;
export type MobileNavigationModel = t.TypeOf<typeof mobileNavigationModel>;
export type MobileNavigationLevel2Model = t.TypeOf<
  typeof mobileNavigationLevel2Model
>;
export type DesktopNavigationLevel2Model = t.TypeOf<
  typeof desktopNavigationLevel2Model
>;
export type MobileNavigationLevel3Model = t.TypeOf<
  typeof mobileNavigationLevel3Model
>;
export type DesktopNavigationLevel3Model = t.TypeOf<
  typeof desktopNavigationLevel3Model
>;
export type NavigationLinkModel = t.TypeOf<typeof navigationLinkModel>;
export type MobileNavigationLevel =
  | MobileNavigationModel
  | MobileNavigationLevel2Model
  | MobileNavigationLevel3Model;
