import {
  Color,
  TypographySize,
  TypographyVariant,
} from 'components/Typography/types';
import toResponsiveValue, {
  OptionallyResponsiveValue,
} from 'lib/toResponsiveValue';
import styled, { CSSObject, css } from 'styled-components';

interface SpecialProps {
  variant: OptionallyResponsiveValue<TypographyVariant>;
  size: OptionallyResponsiveValue<Extract<TypographySize, 'S' | 'M' | 'L'>>;
  textColor?: OptionallyResponsiveValue<Color>;
}

const sizes: Record<
  Extract<TypographySize, 'S' | 'M' | 'L'>,
  Pick<CSSObject, 'fontSize' | 'lineHeight'>
> = {
  S: {
    fontSize: 9,
    lineHeight: 11,
  },
  M: {
    fontSize: 11,
    lineHeight: 13,
  },
  L: {
    fontSize: 12,
    lineHeight: 14,
  },
};

const styles: Record<
  TypographyVariant,
  Pick<CSSObject, 'fontWeight' | 'textTransform' | 'letterSpacing'>
> = {
  '1': {
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.04em',
  },
  '2': {
    fontWeight: 400,
    textTransform: 'uppercase',
    letterSpacing: '0.04em',
  },
  '3': {
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.04em',
  },
};

export const Special = styled.span<SpecialProps>(
  ({ size: s, variant: v, textColor: c = 'black', theme: { bp, colors } }) => {
    const size = toResponsiveValue(s);
    const variant = toResponsiveValue(v);
    const color = toResponsiveValue(c);

    return css`
      margin: 0;
      font-feature-settings: 'ss03';
      font-size: ${sizes[size.xs].fontSize}px;
      line-height: ${sizes[size.xs].lineHeight}px;
      color: ${colors[color.xs]};
      font-weight: ${styles[variant.xs].fontWeight};
      letter-spacing: ${styles[variant.xs].letterSpacing};
      text-transform: ${styles[variant.xs].textTransform};

      ${bp.s} {
        font-size: ${sizes[size.s].fontSize}px;
        line-height: ${sizes[size.s].lineHeight}px;
        color: ${colors[color.s]};
        font-weight: ${styles[variant.s].fontWeight};
        letter-spacing: ${styles[variant.s].letterSpacing};
        text-transform: ${styles[variant.s].textTransform};
      }

      ${bp.m} {
        font-size: ${sizes[size.m].fontSize}px;
        line-height: ${sizes[size.m].lineHeight}px;
        color: ${colors[color.m]};
        font-weight: ${styles[variant.m].fontWeight};
        letter-spacing: ${styles[variant.m].letterSpacing};
        text-transform: ${styles[variant.m].textTransform};
      }

      ${bp.l} {
        font-size: ${sizes[size.l].fontSize}px;
        line-height: ${sizes[size.l].lineHeight}px;
        color: ${colors[color.l]};
        font-weight: ${styles[variant.l].fontWeight};
        letter-spacing: ${styles[variant.l].letterSpacing};
        text-transform: ${styles[variant.l].textTransform};
      }

      ${bp.xl} {
        font-size: ${sizes[size.xl].fontSize}px;
        line-height: ${sizes[size.xl].lineHeight}px;
        color: ${colors[color.xl]};
        font-weight: ${styles[variant.xl].fontWeight};
        letter-spacing: ${styles[variant.xl].letterSpacing};
        text-transform: ${styles[variant.xl].textTransform};
      }

      ${bp.xxl} {
        font-size: ${sizes[size.xxl].fontSize}px;
        line-height: ${sizes[size.xxl].lineHeight}px;
        color: ${colors[color.xxl]};
        font-weight: ${styles[variant.xxl].fontWeight};
        letter-spacing: ${styles[variant.xxl].letterSpacing};
        text-transform: ${styles[variant.xxl].textTransform};
      }

      > a[href] {
        color: inherit;
      }
    `;
  }
);
