import { countries, getCountryFromPath } from 'lib/locale';
import { OmetriaStoreId } from 'models/locales/types';
import { DownstreamCartState } from 'store/cart';

// docs are in https://support.ometria.com/hc/en-gb/articles/360011625378-JavaScript-interaction-API
interface OmetriaBasket {
  setId(id: string): void;
  setUrl(id: string): void;
  setTotal(total?: number, currency?: string): void;
  addLineItem(
    sku: string,
    quantity: number,
    price?: number,
    variantSku?: string
  ): void;
}

interface Ometria {
  init: (
    pageType: string,
    pageData: Record<string, unknown> | null,
    storeId: OmetriaStoreId
  ) => void;
  trackTransaction: (orderId: string) => void;
  Basket: new () => OmetriaBasket;
  trackAddToBasket: (productId: string, quantity: number) => void;
  setBasket: (basket: OmetriaBasket) => void;
  identify: (email: string) => void;
}

type SendOmetriaPageImpression = (args: {
  page: Page;
  locale: string;
  window?: Window;
}) => () => void;

type SendOmetriaProductImpression = (args: {
  parentKey: string | undefined;
  variantSku: string;
  locale: string;
  window?: Window;
}) => () => void;

type SetOmetriaBasket = (args: {
  cart: DownstreamCartState;
  locale: string;
  window?: Window;
}) => Promise<void>;

type TrackOmetriaTransaction = (args: {
  order: { number: string };
  window?: Window;
}) => void;

type TrackOmetriaAddToBasket = (args: {
  sku: string;
  quantity?: number;
  window?: Window;
}) => void;

declare global {
  interface Window {
    ometria: Ometria | undefined;
  }
}

export const getStoreID = (locale?: string): OmetriaStoreId =>
  countries[getCountryFromPath(locale, 'us')].ometria;

export enum Page {
  Homepage = 'homepage',
  Listing = 'listing',
  Basket = 'basket',
  Checkout = 'checkout',
  Confirmation = 'confirmation',
  Product = 'product',
}

export const sendOmetriaPageImpression: SendOmetriaPageImpression =
  ({ page, locale, window = global.window }) =>
  () => {
    if (typeof window?.ometria === 'undefined') {
      return;
    }

    window.ometria.init(page, null, getStoreID(locale));
  };

export const sendOmetriaProductImpression: SendOmetriaProductImpression =
  ({ parentKey, variantSku, locale, window = global.window }) =>
  () => {
    if (!variantSku || !parentKey) {
      return;
    }

    if (typeof window?.ometria === 'undefined') {
      return;
    }

    window.ometria.init(
      Page.Product,
      { pid: `${parentKey}`, vid: `${variantSku}` },
      getStoreID(locale)
    );
  };

export const setOmetriaBasket: SetOmetriaBasket = async ({
  cart,
  locale,
  window = global.window,
}) => {
  if (typeof window?.ometria === 'undefined' || !cart.id) {
    return;
  }

  const origin = [window?.location.origin].join('');

  const basket = new window.ometria.Basket();

  basket.setId(cart.id);
  basket.setUrl(`${origin}/${locale}/bag/${cart.id}`);
  basket.setTotal(
    cart.displayPrice?.discountedPrice,
    cart.displayPrice?.currencyCode
  );

  for (const key in cart.items) {
    basket.addLineItem(
      cart.products[cart.items[key].productId].parentKey as string,
      cart.items[key].quantity,
      cart.displayPrice?.discountedPrice,
      cart.items[key].variantSku
    );
  }

  window.ometria.setBasket(basket);
};

export const trackOmetriaTransaction: TrackOmetriaTransaction = ({
  order,
  window = global.window,
}) => {
  if (typeof window?.ometria === 'undefined') {
    return;
  }

  window.ometria.trackTransaction(order.number);
};

export const trackOmetriaAddToBasket: TrackOmetriaAddToBasket = ({
  sku,
  quantity = 1,
  window = global.window,
}) => {
  if (typeof window?.ometria === 'undefined') {
    return;
  }

  window.ometria.trackAddToBasket(sku, quantity);
};

export const ometriaIdentify = (email: string, window = global.window) => {
  if (typeof window?.ometria?.identify === 'undefined') {
    return;
  }

  window.ometria.identify(email);
};
