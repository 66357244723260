import { createSlice } from '@reduxjs/toolkit';
import { joinCampaign } from 'store/campaigns/thunks';
import { CampaignsState } from 'store/campaigns/types';

export const initialState: CampaignsState = {
  email: '',
  inProgress: {},
  errors: {},
  complete: {},
};

const { reducer, actions } = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCampaigns() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(joinCampaign.pending, (state, action) => {
        delete state.errors[action.meta.arg.pm_form];
        delete state.complete[action.meta.arg.pm_form];
        state.email = '';
        state.inProgress[action.meta.arg.pm_form] = true;
      })
      .addCase(joinCampaign.rejected, (state, action) => {
        delete state.inProgress[action.meta.arg.pm_form];
        state.email = '';
        state.errors[action.meta.arg.pm_form] = action.error.message as string;
      })
      .addCase(joinCampaign.fulfilled, (state, action) => {
        delete state.errors[action.meta.arg.pm_form];
        delete state.inProgress[action.meta.arg.pm_form];
        state.email = action.meta.arg.ue;
        state.complete[action.meta.arg.pm_form] = true;
      });
  },
});

export const { resetCampaigns } = actions;

export default reducer;
