import dynamic from 'next/dynamic';

export const Key = dynamic(() => import('components/Icons/FileTypes/Key'), {
  ssr: false,
});

export const Pdf = dynamic(() => import('components/Icons/FileTypes/Pdf'), {
  ssr: false,
});

export const Ppt = dynamic(() => import('components/Icons/FileTypes/Ppt'), {
  ssr: false,
});

export const Rtf = dynamic(() => import('components/Icons/FileTypes/Rtf'), {
  ssr: false,
});

export const Web = dynamic(() => import('components/Icons/FileTypes/Web'), {
  ssr: false,
});

export const Xls = dynamic(() => import('components/Icons/FileTypes/Xls'), {
  ssr: false,
});

export const Zip = dynamic(() => import('components/Icons/FileTypes/Zip'), {
  ssr: false,
});
