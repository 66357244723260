import type { Draft } from 'immer';

type WritableDraft<T> = {
  -readonly [K in keyof T]: Draft<T[K]>;
};

export const replaceDownstreamState =
  <P extends Record<string, unknown>, S extends P>(factoryArgs: {
    omit?: Partial<Record<keyof S, true>>;
  }) =>
  (state: WritableDraft<S>, payload?: P | null): void => {
    if (!payload) {
      return;
    }

    for (const key in payload) {
      if (!factoryArgs.omit?.[key]) {
        const k = key as keyof P as keyof S;
        if ((state[k] as S[keyof S]) !== (payload as S)[k]) {
          (state[k] as S[keyof S]) = (payload as S)[k];
        }
      }
    }
  };
