import {
  Color,
  TextAlign,
  TypographySize,
  TypographyVariant,
} from 'components/Typography/types';
import toResponsiveValue, {
  OptionallyResponsiveValue,
} from 'lib/toResponsiveValue';
import styled, { CSSObject, css } from 'styled-components';

export interface TitleProps {
  variant: OptionallyResponsiveValue<TypographyVariant>;
  size: OptionallyResponsiveValue<TypographySize>;
  textColor?: OptionallyResponsiveValue<Color>;
  textAlign?: OptionallyResponsiveValue<TextAlign>;
}

const sizes: Record<
  TypographySize,
  Pick<CSSObject, 'fontSize' | 'lineHeight'>
> = {
  '2XS': {
    fontSize: 14,
    lineHeight: 17,
  },
  XS: {
    fontSize: 16,
    lineHeight: 19,
  },
  S: {
    fontSize: 20,
    lineHeight: 24,
  },
  M: {
    fontSize: 24,
    lineHeight: 29,
  },
  L: {
    fontSize: 32,
    lineHeight: 38,
  },
  XL: {
    fontSize: 40,
    lineHeight: 48,
  },
};

const styles: Record<
  TypographyVariant,
  Pick<CSSObject, 'fontWeight' | 'textTransform' | 'letterSpacing'>
> = {
  '1': {
    fontWeight: 400,
    textTransform: 'none',
    letterSpacing: 'normal',
  },
  '2': {
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  '3': {
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 'normal',
  },
};

export const Title = styled.span<TitleProps>(
  ({
    textAlign: t,
    size: s,
    variant: v,
    textColor: c = 'black',
    theme: { bp, colors },
  }) => {
    const size = toResponsiveValue(s);
    const variant = toResponsiveValue(v);
    const color = toResponsiveValue(c);
    const textAlign = toResponsiveValue(t);

    return css`
      margin: 0;
      font-feature-settings: 'ss03';
      font-weight: ${styles[variant.xs].fontWeight};
      text-transform: ${styles[variant.xs].textTransform};
      letter-spacing: ${styles[variant.xs].letterSpacing};
      font-size: ${sizes[size.xs].fontSize}px;
      line-height: ${sizes[size.xs].lineHeight}px;
      color: ${colors[color.xs]};
      text-align: ${textAlign.xs};

      ${bp.s} {
        font-weight: ${styles[variant.s].fontWeight};
        text-transform: ${styles[variant.s].textTransform};
        letter-spacing: ${styles[variant.s].letterSpacing};
        font-size: ${sizes[size.s].fontSize}px;
        line-height: ${sizes[size.s].lineHeight}px;
        color: ${colors[color.s]};
        text-align: ${textAlign.s};
      }

      ${bp.m} {
        font-weight: ${styles[variant.m].fontWeight};
        text-transform: ${styles[variant.m].textTransform};
        letter-spacing: ${styles[variant.m].letterSpacing};
        font-size: ${sizes[size.m].fontSize}px;
        line-height: ${sizes[size.m].lineHeight}px;
        color: ${colors[color.m]};
        text-align: ${textAlign.m};
      }

      ${bp.l} {
        font-weight: ${styles[variant.l].fontWeight};
        text-transform: ${styles[variant.l].textTransform};
        letter-spacing: ${styles[variant.l].letterSpacing};
        font-size: ${sizes[size.l].fontSize}px;
        line-height: ${sizes[size.l].lineHeight}px;
        color: ${colors[color.l]};
      }

      ${bp.xl} {
        font-weight: ${styles[variant.xl].fontWeight};
        text-transform: ${styles[variant.xl].textTransform};
        letter-spacing: ${styles[variant.xl].letterSpacing};
        font-size: ${sizes[size.xl].fontSize}px;
        line-height: ${sizes[size.xl].lineHeight}px;
        color: ${colors[color.xl]};
        text-align: ${textAlign.xl};
      }

      ${bp.xxl} {
        font-weight: ${styles[variant.xxl].fontWeight};
        text-transform: ${styles[variant.xxl].textTransform};
        letter-spacing: ${styles[variant.xxl].letterSpacing};
        font-size: ${sizes[size.xxl].fontSize}px;
        line-height: ${sizes[size.xxl].lineHeight}px;
        color: ${colors[color.xxl]};
        text-align: ${textAlign.xxl};
      }

      > a[href] {
        color: inherit;
      }
    `;
  }
);
