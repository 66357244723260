import Gap from 'components/Gap';
import { Title } from 'components/Typography';
import { FC, memo } from 'react';

interface HelperTextProps {
  helperText?: string;
}

const InputHelperText: FC<HelperTextProps> = ({ helperText }) => {
  if (!helperText) {
    return null;
  }

  return (
    <>
      <Gap size={8} />
      <Title variant="1" size="2XS">
        {helperText}
      </Title>
    </>
  );
};

export default memo(InputHelperText);
