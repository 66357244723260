import { Slide, ToastContainer } from 'react-toastify';

export const containerId = 'countryMismatchToastContainer';

export const CountryMismatchToastContainer = () => (
  <ToastContainer
    containerId={containerId}
    autoClose={false}
    position="bottom-right"
    hideProgressBar
    closeButton={false}
    icon={false}
    draggable={false}
    transition={Slide}
    style={{
      width: 'auto',
      left: 'auto',
    }}
    bodyStyle={{ padding: 0 }}
    toastStyle={{ padding: 0, borderRadius: 0 }}
  />
);
