import {
  Color,
  TextAlign,
  TypographySize,
  TypographyVariant,
} from 'components/Typography/types';
import toResponsiveValue, {
  OptionallyResponsiveValue,
} from 'lib/toResponsiveValue';
import styled, { CSSObject, css } from 'styled-components';

interface StatementProps {
  variant: OptionallyResponsiveValue<Extract<TypographyVariant, '1'>>;
  size: OptionallyResponsiveValue<Exclude<TypographySize, 'XL'>>;
  textColor?: OptionallyResponsiveValue<Color>;
  textAlign?: OptionallyResponsiveValue<TextAlign>;
}

const sizes: Record<
  Exclude<TypographySize, 'XL'>,
  Pick<CSSObject, 'fontSize' | 'lineHeight'>
> = {
  '2XS': {
    fontSize: 14,
    lineHeight: 20,
  },
  XS: {
    fontSize: 16,
    lineHeight: 22,
  },
  S: {
    fontSize: 20,
    lineHeight: 28,
  },
  M: {
    fontSize: 24,
    lineHeight: 34,
  },
  L: {
    fontSize: 32,
    lineHeight: 45,
  },
};

export const Statement = styled.span<StatementProps>(
  ({
    textAlign: t,
    size: s,
    textColor: c = 'black',
    theme: { bp, colors },
  }) => {
    const size = toResponsiveValue(s);
    const color = toResponsiveValue(c);
    const textAlign = toResponsiveValue(t);

    return css`
      margin: 0;
      font-weight: 400;
      font-feature-settings: 'ss03';
      font-size: ${sizes[size.xs].fontSize}px;
      line-height: ${sizes[size.xs].lineHeight}px;
      color: ${colors[color.xs]};
      text-align: ${textAlign.xs};

      ${bp.s} {
        font-size: ${sizes[size.s].fontSize}px;
        line-height: ${sizes[size.s].lineHeight}px;
        color: ${colors[color.s]};
        text-align: ${textAlign.s};
      }

      ${bp.m} {
        font-size: ${sizes[size.m].fontSize}px;
        line-height: ${sizes[size.m].lineHeight}px;
        color: ${colors[color.m]};
        text-align: ${textAlign.m};
      }

      ${bp.l} {
        font-size: ${sizes[size.l].fontSize}px;
        line-height: ${sizes[size.l].lineHeight}px;
        color: ${colors[color.l]};
        text-align: ${textAlign.l};
      }

      ${bp.xl} {
        font-size: ${sizes[size.xl].fontSize}px;
        line-height: ${sizes[size.xl].lineHeight}px;
        color: ${colors[color.xl]};
        text-align: ${textAlign.xl};
      }

      ${bp.xxl} {
        font-size: ${sizes[size.xxl].fontSize}px;
        line-height: ${sizes[size.xxl].lineHeight}px;
        color: ${colors[color.xxl]};
        text-align: ${textAlign.xxl};
      }

      > a[href] {
        color: inherit;
      }
    `;
  }
);
