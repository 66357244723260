import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverlaysState } from 'store/overlays/types';

export const initialState: OverlaysState = {
  open: false,
  closeOnOutsideClick: true,
  name: 'none',
  props: {},
};

const { reducer, actions } = createSlice({
  name: 'overlays',
  initialState,
  reducers: {
    resetOverlay() {
      return initialState;
    },
    closeOverlay(state) {
      state.open = false;
    },
    openOverlay(
      state,
      action: PayloadAction<Required<Omit<OverlaysState, 'open'>>>
    ) {
      state.open = true;
      state.closeOnOutsideClick = action.payload.closeOnOutsideClick;
      state.name = action.payload.name;
      state.props = action.payload.props;
    },
  },
});

export const { resetOverlay, closeOverlay, openOverlay } = actions;
export default reducer;
