import styled from 'styled-components';

const InputContainer = styled.span`
  display: block;
  position: relative;

  select {
    padding-top: 0;
    padding-bottom: 0;
    height: 56px;
  }
`;

export default InputContainer;
