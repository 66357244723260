import { SelectProps } from 'components/Input/helpers';
import {
  InputContainer,
  InputError,
  InputHelperText,
  InputLabel,
  InputLoader,
  SelectField,
  TextInputBorder,
  TextInputPositioner,
} from 'components/Input/InputPartials';
import useInputField from 'hooks/useTextInput';
import { FC } from 'react';

const Select: FC<SelectProps> = ({
  onChange,
  label,
  name,
  helperText,
  error,
  disabled,
  loading,
  tabIndex,
  border = 'none',
  showError = true,
  options,
  value = '',
}) => {
  const uneditable = Boolean(disabled || loading);

  const { htmlFieldId, changeEvent } = useInputField({
    onChange,
  });

  return (
    <InputContainer>
      <InputLabel label={label} htmlFor={htmlFieldId} disabled={uneditable} />
      <TextInputPositioner border={border}>
        <InputLoader loading={loading}>
          <SelectField
            border={border}
            value={value}
            onChange={changeEvent}
            disabled={disabled}
            name={name}
            id={htmlFieldId}
            tabIndex={tabIndex}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectField>
        </InputLoader>
        <TextInputBorder
          border={border}
          hasError={Boolean(error) && showError === true}
          disabled={uneditable}
        />
      </TextInputPositioner>
      <InputHelperText helperText={helperText} />
      <InputError
        error={error}
        visible={showError}
        hasHelperText={Boolean(helperText)}
      />
    </InputContainer>
  );
};

export default Select;
