import Script from 'next/script';
import { FC } from 'react';

interface HelpScoutProps {
  enabled: boolean;
}

const HelpScout: FC<HelpScoutProps> = ({ enabled }) => {
  if (!enabled) {
    return null;
  }

  return (
    <>
      <Script
        id="hs-script"
        strategy="afterInteractive"
      >{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}</Script>
      <Script
        id="hs-init-script"
        strategy="afterInteractive"
      >{`window.Beacon('init', '361a8bac-a4c5-4596-a8b1-a935c0ebcdb0')`}</Script>
    </>
  );
};

export default HelpScout;
