import HeaderButton, {
  HeaderButtonProps,
} from 'components/HeaderButtons/HeaderButton';
import { Cross } from 'components/Icons';
import { FC } from 'react';

const CloseButton: FC<Omit<HeaderButtonProps, 'children'>> = ({
  className,
  color,
  ...buttonProps
}) => {
  return (
    <HeaderButton {...buttonProps} className={className} title="Close">
      <Cross strokeColor={color} />
    </HeaderButton>
  );
};

export default CloseButton;
