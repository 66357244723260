import * as t from 'io-ts';

interface ValidEmailBrand {
  readonly ValidEmail: unique symbol;
}

interface ValidOrderNumberBrand {
  readonly ValidOrderNumber: unique symbol;
}

interface AcceptablePasswordBrand {
  readonly AcceptablePassword: unique symbol;
}

const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ORDER_NUMBER_PATTERN = /^(#?PM-)?(\d{10})$/;

export const isEmail = (input?: string) => EMAIL_PATTERN.test([input].join(''));

export const isOrderNumber = (input?: string) =>
  ORDER_NUMBER_PATTERN.test([input].join(''));

export const isAcceptablePassword = (input?: string) => {
  if (!input) {
    return false;
  }

  if (input.length < 8) {
    return false;
  }

  const hasNonNumerals = Number(/[A-Za-z\W]/.test(input));
  const hasNumerals = Number(/\d/.test(input));

  if (hasNonNumerals + hasNumerals < 2) {
    return false;
  }

  return true;
};

export const validEmail = t.brand(
  t.string,
  (email): email is t.Branded<string, ValidEmailBrand> => isEmail(email),
  'ValidEmail'
);

export const validOrderNumber = t.brand(
  t.string,
  (orderNumber): orderNumber is t.Branded<string, ValidOrderNumberBrand> =>
    isOrderNumber(orderNumber),
  'ValidOrderNumber'
);

export const acceptablePassword = t.brand(
  t.string,
  (password): password is t.Branded<string, AcceptablePasswordBrand> =>
    isAcceptablePassword(password),
  'AcceptablePassword'
);
