import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ISiteSettings } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toFlashBanner from 'models/contentful/flashBanner/serializers';
import toProductList from 'models/contentful/productList/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  SiteSettingsModel,
  siteSettingsModel,
} from 'models/contentful/siteSettings/types';

export const defaultSiteSettingsModel: SiteSettingsModel = {
  flashBanner: null,
  searchSuggestions: [],
  browserTitle: '',
  metaTitle: '',
  metaDescription: '',
  metaImage: null,
  favicon: null,
  paymentMethods: [],
  cartProductRecomendations: null,
};

const toSiteSettings: ContentModeller<ISiteSettings, SiteSettingsModel> = ({
  content,
  locale,
}) => {
  const model = {
    ...content.fields,
    favicon: { url: content.fields.favicon.fields.file.url },
    metaImage: content.fields.metaImage
      ? { url: content.fields.metaImage.fields.file.url }
      : null,
    searchSuggestions: content.fields.searchSuggestions
      ? content.fields.searchSuggestions
      : [],
    flashBanner: content.fields.flashBanner
      ? toFlashBanner({ content: content.fields.flashBanner, locale })
      : null,
    paymentMethods: content.fields.paymentMethods ?? [],
    cartProductRecomendations: content.fields.cartProductRecomendations
      ? toProductList({
          content: content.fields.cartProductRecomendations,
          locale,
        })
      : null,
  } as SiteSettingsModel;

  return pipe(
    siteSettingsModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SiteSettings',
        id: content.sys.id,
        fallback: defaultSiteSettingsModel,
      }),
      returnValidModel
    )
  );
};

export default toSiteSettings;
