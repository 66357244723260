import { ProductVariant } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { logApiError } from 'lib/apiLogger';
import { countries, getApiLocale, getCountryFromPath } from 'lib/locale';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  AttributeName,
  getBooleanAttribute,
  toAttributes,
} from 'models/attributes/serializers';
import { PriceModel, VariantModel, variantModel } from 'models/variants/types';
import {
  deriveAvailability,
  deriveImages,
  deriveLowStock,
  derivePrice,
  deriveSize,
  deriveVideosFromAssets,
  isVariantOnPreorder,
} from 'models/variants/utilities';

type ToVariant = (args: {
  variant: ProductVariant | undefined;
  slug: string;
  locale: string;
}) => VariantModel | null;

type ToVariantMap = (args: {
  variants: ProductVariant[];
  slug: string;
  locale: string;
}) => Record<string, VariantModel>;

export const toVariant: ToVariant = ({ variant, slug, locale }) => {
  if (variant === undefined) {
    return null;
  }

  const apiLocale = getApiLocale(locale);
  const { language, country } = apiLocale;

  try {
    const attributes = variant.attributes ?? [];

    const {
      swatch,
      size,
      length,
      materialsText,
      careInstructionsText,
      variantProductDescription,
      gender,
      tag,
      preorderExpectedDispatch,
      finalSaleStores,
    } = toAttributes({ attributes, language });

    const { isOnStock, availableQuantity } = deriveAvailability(variant);

    const derivedSize = deriveSize({ size, length });

    const isFinalSale = (finalSaleStores || []).find(
      store => store.key === countries[getCountryFromPath(locale, 'gb')].store
    );

    const model: VariantModel = {
      id: variant.id,
      url: `/${locale}/p/${slug}/${swatch[0].key}`,
      sku: variant.sku + '',
      inStock: isOnStock,
      availableQuantity,
      preorder: isVariantOnPreorder(variant, apiLocale),
      preorderExpectedDispatch: preorderExpectedDispatch?.[0]?.value ?? '',
      lowStock: deriveLowStock(availableQuantity),
      price: derivePrice(variant, country) as PriceModel,
      images: deriveImages(variant.images),
      videos: deriveVideosFromAssets(variant.assets),
      swatch: swatch?.[0],
      size: derivedSize,
      materials: materialsText?.[0]?.value ?? '',
      careInstructions: careInstructionsText?.[0]?.value ?? '',
      variantProductDescription: variantProductDescription?.[0]?.value ?? '',
      gender: gender?.[0].value,
      tag: tag?.[0]?.value ?? '',
      available: getBooleanAttribute(attributes, AttributeName.Available, true),
      finalSale: !!isFinalSale,
    };

    return pipe(
      variantModel.decode(model),
      fold(
        reportTypeErrors({
          id: variant.sku as string,
          model: 'Variant',
          fallback: null,
        }),
        returnValidModel
      )
    );
  } catch (e) {
    logApiError(e);
    return null;
  }
};

export const toVariantMap: ToVariantMap = ({ variants, slug, locale }) =>
  variants.reduce<Record<string, VariantModel>>((acc, variant) => {
    const model = toVariant({ variant, slug, locale });

    if (model) {
      acc[model.sku] = model;
    }

    return acc;
  }, {});
