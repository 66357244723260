import type { BooleanInputProps } from 'components/Input/helpers';
import {
  BooleanInputButton,
  BooleanInputContainer,
  BooleanInputField,
  BooleanInputIcon,
  BooleanInputLabel,
  InputContainer,
  InputError,
  InputHelperText,
} from 'components/Input/InputPartials';
import { FC, useId } from 'react';
import styled, { css } from 'styled-components';

const CheckboxInputButton = styled(BooleanInputButton)`
  border-radius: 5px;
  &:after {
    border-style: solid;
    border-radius: 0 !important;
    width: 4px;
    height: 10px;
    top: 3px;
    left: 8px;
    transform: scale(0) rotate(45deg);
    border-width: 0 1.5px 1.5px 0;
    background-color: transparent;
  }
`;

const CheckboxInputField = styled(BooleanInputField)(
  ({ theme: { colors } }) => css`
    &:disabled:checked ~ [data-boolean-input-button]:after,
    &:not(:disabled):checked ~ [data-boolean-input-button]:after {
      width: 4px;
      height: 10px;
      top: 3px;
      left: 8px;
      transform: scale(1) rotate(45deg);
      background-color: transparent;
    }

    &:focus-visible ~ [data-boolean-input-button] {
      outline: Highlight auto 1px;
      outline: -webkit-focus-ring-color auto 1px;
      outline-offset: -2px;
    }

    &:disabled:checked ~ [data-boolean-input-button]:after {
      border-color: ${colors.grey6};
    }

    &:not(:disabled):checked ~ [data-boolean-input-button]:after {
      border-color: ${colors.white};
    }
  `
);

const CheckboxInput: FC<BooleanInputProps> = ({
  name,
  value,
  checked,
  disabled,
  icon,
  label,
  helperText,
  error,
  showError,
  children,
  onChange,
}) => {
  const htmlFieldId = useId();

  return (
    <InputContainer>
      <BooleanInputContainer>
        <CheckboxInputField
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          id={htmlFieldId}
          onChange={onChange}
          tabIndex={0}
        />
        <CheckboxInputButton htmlFor={htmlFieldId} />
        <BooleanInputIcon icon={icon} disabled={disabled} padded={false} />
        <BooleanInputLabel
          as="label"
          hasIcon={Boolean(icon)}
          htmlFor={htmlFieldId}
        >
          {children ? children : label}
        </BooleanInputLabel>
      </BooleanInputContainer>
      <InputHelperText helperText={helperText} />
      <InputError
        error={error}
        visible={showError}
        hasHelperText={Boolean(helperText)}
      />
    </InputContainer>
  );
};

export default CheckboxInput;
