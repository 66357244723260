import { createAsyncThunk } from '@reduxjs/toolkit';
import { withAuth } from 'store/auth/wrappers';
import {
  addDiscountCodeRequest,
  addExistingLineItemsRequest,
  addRequest,
  changeLineItemQuantityRequest,
  changeLineItemVariantRequest,
  mergeWithCustomerCartRequest,
  recalculateRequest,
  removeDiscountCodeRequest,
  retrieveCartRequest,
  retrieveOrCreateCartRequest,
  setBillingAddressRequest,
  setEmailRequest,
  setMarketingOptInRequest,
  setShippingAddressRequest,
  setShippingMethodRequest,
} from 'store/cart/api';
import type {
  Add,
  AddDiscountCode,
  BillingAddress,
  CartLocale,
  ChangeLineItemQuantity,
  ChangeVariant,
  DownstreamCartState,
  RemoveDiscountCode,
  SetEmail,
  SetMarketingOptIn,
  ShippingAddress,
  ShippingMethod,
  SignIn,
} from 'store/cart/types';
import type { ThunkApi } from 'store/types';

export const retrieveCart = createAsyncThunk<
  DownstreamCartState | null,
  string,
  ThunkApi
>('cart/retrieve', async (locale, store) => {
  const { auth, cart } = store.getState();

  if (!auth.accessToken) {
    return null;
  }

  if (!cart.id) {
    return null;
  }

  return retrieveCartRequest({
    id: cart.id,
    token: auth.accessToken,
    locale,
  });
});

export const retrieveOrCreateCart = createAsyncThunk<
  DownstreamCartState,
  string,
  ThunkApi
>('cart/retrieveOrCreate', async (locale, store) =>
  withAuth(store)(() =>
    retrieveOrCreateCartRequest({
      id: store.getState().cart.id,
      token: store.getState().auth.accessToken,
      anonymousId: store.getState().auth.anonymousId,
      customerEmail: store.getState().customer.email,
      locale,
    })
  )
);

export const recalculate = createAsyncThunk<
  DownstreamCartState,
  string,
  ThunkApi
>('cart/recalculate', async (locale, { dispatch, getState }) => {
  await dispatch(retrieveCart(locale));

  return await recalculateRequest({
    id: getState().cart.id,
    version: getState().cart.version,
    token: getState().auth.accessToken,
    locale,
  });
});

export const addLineItem = createAsyncThunk<
  DownstreamCartState,
  Add & CartLocale,
  ThunkApi
>(
  'cart/addLineItem',
  async (
    { locale, productId, variantId, quantity },
    { dispatch, getState }
  ) => {
    await dispatch(retrieveOrCreateCart(locale));

    return await addRequest({
      id: getState().cart.id,
      version: getState().cart.version,
      token: getState().auth.accessToken,
      locale,
      productId,
      variantId,
      quantity,
    });
  }
);

export const changeLineItemQuantity = createAsyncThunk<
  DownstreamCartState,
  ChangeLineItemQuantity & CartLocale,
  ThunkApi
>(
  'cart/changeLineItemQuantity',
  ({ locale, lineItemId, quantity }, { getState }) =>
    changeLineItemQuantityRequest({
      id: getState().cart.id,
      locale,
      token: getState().auth.accessToken,
      lineItemId,
      quantity,
    })
);

export const changeLineItemVariant = createAsyncThunk<
  DownstreamCartState,
  ChangeVariant & CartLocale,
  ThunkApi
>(
  'cart/changeLineItemVariant',
  ({ locale, lineItemId, variantId }, { getState }) =>
    changeLineItemVariantRequest({
      id: getState().cart.id,
      locale,
      lineItemId,
      variantId,
      token: getState().auth.accessToken,
      productId: getState().cart.items[lineItemId].productId,
      quantity: getState().cart.items[lineItemId].quantity,
    })
);

export const addDiscountCode = createAsyncThunk<
  DownstreamCartState,
  AddDiscountCode & CartLocale,
  ThunkApi
>('cart/addDiscountCode', ({ locale, code }, { getState }) =>
  addDiscountCodeRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    code: code.toUpperCase().trim(),
  })
);

export const removeDiscountCode = createAsyncThunk<
  DownstreamCartState,
  RemoveDiscountCode & CartLocale,
  ThunkApi
>('cart/removeDiscountCode', ({ locale, discountId, message }, { getState }) =>
  removeDiscountCodeRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    discountId,
    message,
  })
);

export const setEmail = createAsyncThunk<
  DownstreamCartState,
  SetEmail & CartLocale,
  ThunkApi
>('cart/setEmail', ({ locale, email }, { getState }) =>
  setEmailRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    email,
  })
);

export const setMarketingOptIn = createAsyncThunk<
  DownstreamCartState,
  SetMarketingOptIn & CartLocale,
  ThunkApi
>('cart/setMarketingOptIn', ({ locale, optIn }, { getState }) =>
  setMarketingOptInRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    optIn,
  })
);

export const mergeWithCustomerCart = createAsyncThunk<
  DownstreamCartState,
  SignIn & CartLocale,
  ThunkApi
>('cart/mergeWithCustomerCart', ({ locale, email, password }, { getState }) =>
  mergeWithCustomerCartRequest({
    locale,
    email,
    password,
    token: getState().auth.accessToken,
  })
);

export const setShippingAddress = createAsyncThunk<
  DownstreamCartState,
  ShippingAddress & CartLocale,
  ThunkApi
>(
  'cart/setShippingAddress',
  ({ locale, address, saveToBillingAddress }, { getState }) =>
    setShippingAddressRequest({
      id: getState().cart.id,
      locale,
      token: getState().auth.accessToken,
      address,
      saveToBillingAddress,
    })
);

export const setBillingAddress = createAsyncThunk<
  DownstreamCartState,
  BillingAddress & CartLocale,
  ThunkApi
>('cart/setBillingAddress', ({ locale, address }, { getState }) =>
  setBillingAddressRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    address,
  })
);

export const setShippingMethod = createAsyncThunk<
  DownstreamCartState,
  ShippingMethod & CartLocale,
  ThunkApi
>('cart/setShippingMethod', ({ locale, shippingMethodId }, { getState }) =>
  setShippingMethodRequest({
    id: getState().cart.id,
    locale,
    token: getState().auth.accessToken,
    shippingMethodId,
  })
);

export const setFirstShippingMethodReceived = createAsyncThunk<
  DownstreamCartState | undefined,
  CartLocale,
  ThunkApi
>('cart/setFirstShippingMethodReceived', ({ locale }, { getState }) => {
  const { auth, cart, checkout } = getState();

  if (
    cart.inProgress.retrieveCart ||
    checkout.inProgress.shippingMethods ||
    checkout.shippingMethods.length === 0 ||
    (cart.shippingMethod !== null &&
      checkout.shippingMethods.some(({ id }) => id === cart.shippingMethod?.id))
  ) {
    return;
  }

  return setShippingMethodRequest({
    id: cart.id,
    locale,
    token: auth.accessToken,
    shippingMethodId: checkout.shippingMethods[0].id,
  });
});

export const addExistingLineItems = createAsyncThunk<
  DownstreamCartState,
  CartLocale & { cartId: string },
  ThunkApi
>(
  'cart/addExistingLineItems',
  async ({ locale, cartId }, { dispatch, getState }) => {
    if (cartId === getState().cart.id) {
      throw new Error("We're sending you back to your bag.");
    }

    await dispatch(retrieveOrCreateCart(locale));

    return await addExistingLineItemsRequest({
      previousCartId: cartId,
      id: getState().cart.id,
      version: getState().cart.version,
      token: getState().auth.accessToken,
      locale,
    });
  }
);
