export type BreakpointKey = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type InterpolatedResponsiveValue<T> = Record<BreakpointKey, T>;

export type ResponsiveValue<T> = Partial<InterpolatedResponsiveValue<T>> & {
  xs: T;
};

export type OptionallyResponsiveValue<T> = T | ResponsiveValue<T>;

const toResponsiveValue = <T>(
  value: T | ResponsiveValue<T>
): InterpolatedResponsiveValue<T> => {
  if (typeof value === 'object' && value !== null) {
    const v = value as ResponsiveValue<T>;

    return {
      xs: v.xs,
      s: v.s ?? v.xs,
      m: v.m ?? v.s ?? v.xs,
      l: v.l ?? v.m ?? v.s ?? v.xs,
      xl: v.xl ?? v.l ?? v.m ?? v.s ?? v.xs,
      xxl: v.xxl ?? v.xl ?? v.l ?? v.m ?? v.s ?? v.xs,
    } as InterpolatedResponsiveValue<T>;
  }

  const v = value as T;

  return {
    xs: v,
    s: v,
    m: v,
    l: v,
    xl: v,
    xxl: v,
  };
};

export default toResponsiveValue;
