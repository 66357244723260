import { MyPayment } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  adyenResponseModel,
  AdyenResponseModel,
  createSessionRequestModel,
  createSessionResponseModel,
  getPaymentMethodsRequestModel,
  getPaymentMethodsResponseModel,
  makePaymentRequestModel,
  makePaymentResponseModel,
  paymentModel,
  PaymentModel,
  submitAdditionalPaymentDetailsResponseModel,
} from 'models/payments/types';
import { assignFieldsToModel, toPaymentDatum } from 'models/payments/utilities';

type ToAdyenResponse = (args: {
  adyenResponse: Record<string, unknown>;
}) => AdyenResponseModel | null;

type ToPayment = (args: { payment: MyPayment }) => PaymentModel | null;

export const toAdyenResponse: ToAdyenResponse = ({ adyenResponse }) =>
  pipe(
    adyenResponseModel.decode(adyenResponse),
    fold(
      reportTypeErrors({
        id: 'Adyen Response',
        model: 'Adyen Response',
        fallback: null,
      }),
      returnValidModel
    )
  );

export const toPayment: ToPayment = ({ payment }) => {
  if (!payment.custom?.fields) {
    return null;
  }

  const upstreamFields = {
    getPaymentMethodsRequest: toPaymentDatum({
      field: payment.custom.fields.getPaymentMethodsRequest,
      id: payment.id,
      validator: getPaymentMethodsRequestModel,
    }),
    getPaymentMethodsResponse: toPaymentDatum({
      field: payment.custom.fields.getPaymentMethodsResponse,
      id: payment.id,
      validator: getPaymentMethodsResponseModel,
    }),
    makePaymentRequest: toPaymentDatum({
      field: payment.custom.fields.makePaymentRequest,
      id: payment.id,
      validator: makePaymentRequestModel,
    }),
    makePaymentResponse: toPaymentDatum({
      field: payment.custom.fields.makePaymentResponse,
      id: payment.id,
      validator: makePaymentResponseModel,
    }),
    createSessionRequest: toPaymentDatum({
      field: payment.custom.fields.createSessionRequest,
      id: payment.id,
      validator: createSessionRequestModel,
    }),
    createSessionResponse: toPaymentDatum({
      field: payment.custom.fields.createSessionResponse,
      id: payment.id,
      validator: createSessionResponseModel,
    }),
    submitAdditionalPaymentDetailsResponse: toPaymentDatum({
      field: payment.custom.fields.submitAdditionalPaymentDetailsResponse,
      id: payment.id,
      validator: submitAdditionalPaymentDetailsResponseModel,
    }),
  };

  const model = assignFieldsToModel({ model: payment, fields: upstreamFields });

  return pipe(
    paymentModel.decode(model),
    fold(
      reportTypeErrors({
        id: payment.id,
        model: 'Payment',
        fallback: null,
      }),
      returnValidModel
    )
  );
};
