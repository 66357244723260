import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IHeader } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { HeaderModel, headerModel } from 'models/contentful/header/types';
import toLink from 'models/contentful/link/serializers';
import { LinkModel } from 'models/contentful/link/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toHeader: ContentModeller<IHeader, HeaderModel> = ({
  content,
  locale,
}) => {
  const model = {
    title: content.fields.title,
    description: content.fields.description ?? '',
    actions: (content.fields.actions ?? [])
      .map(link => toLink({ content: link, locale }))
      .filter((link): link is LinkModel => Boolean(link)),
    blockType: 'Header',
  };

  return pipe(
    headerModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Header',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toHeader;
