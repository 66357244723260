import * as t from 'io-ts';

const attributeChildModel = t.interface({
  id: t.string,
  label: t.string,
  inStock: t.boolean,
  lowStock: t.boolean,
  preorder: t.boolean,
  preorderExpectedDispatch: t.string,
  sku: t.string,
  url: t.string,
});

const attributeParentModel = t.interface({
  label: t.string,
  values: t.record(t.string, attributeChildModel),
});

const skuLookup = t.interface({
  swatch: t.string,
  size: t.string,
});

export const productTypeModel = t.interface({
  name: t.string,
  swatches: t.record(t.string, attributeParentModel),
  sizes: t.record(t.string, attributeParentModel),
  availableSwatches: t.array(t.string),
  availableSizes: t.array(t.string),
  skuLookup: t.record(t.string, skuLookup),
});

export type AttributeChildModel = t.TypeOf<typeof attributeChildModel>;
export type AttributeParentModel = t.TypeOf<typeof attributeParentModel>;
export type SkuLookup = t.TypeOf<typeof skuLookup>;
export type ProductTypeModel = t.TypeOf<typeof productTypeModel>;
