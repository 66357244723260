import {
  LineItem,
  LineItemReturnItem,
  Order,
  OrderPagedQueryResponse,
} from '@commercetools/platform-sdk';
import { commerceToolsPrivilegedClient } from 'lib/commerceTools/privilegedClient';
import { toKeyedObject } from 'lib/util';
import { ORDER_NUMBER_PATTERN } from 'lib/validation';

export const convertToCanonicalOrderNumber = (orderNumber: string): string => {
  const matches = orderNumber.match(ORDER_NUMBER_PATTERN);

  if (!matches?.[2]) {
    return '';
  }

  return 'PM-' + matches[2];
};

export const deriveReturnableQuantities = (
  order: Order
): Record<string, LineItem> => {
  const orderLineItems = toKeyedObject(
    order.lineItems
      .filter(
        i => !i.state?.find(s => s.state.obj?.key === 'lineitem-cancelled')
      )
      .map(lineItem => ({ ...lineItem })),
    'id'
  );

  if (order.orderState === 'Cancelled') {
    return {};
  }

  if (!order.returnInfo?.length) {
    return orderLineItems;
  }

  const returnableProducts = order.returnInfo
    ?.flatMap(({ items }) => items as LineItemReturnItem[])
    .reduce<Record<string, LineItem>>((acc, curr) => {
      (acc[curr.lineItemId].quantity as number) =
        acc[curr.lineItemId].quantity - curr.quantity;
      return acc;
    }, orderLineItems);

  for (const id in returnableProducts) {
    if (returnableProducts[id].quantity <= 0) {
      delete returnableProducts[id];
    }
  }

  return returnableProducts;
};

export const getOrder = async (orderNumber: string) => {
  const isGlobaleOrder = orderNumber.startsWith('GE');

  const orders = commerceToolsPrivilegedClient.orders();
  const orderQuery = isGlobaleOrder
    ? orders.get({
        queryArgs: {
          where: `custom(fields(ge_id="${orderNumber}"))`,
          expand: ['lineItems[*].supplyChannel', 'lineItems[*].state[*].state'],
        },
      })
    : orders
        .withOrderNumber({
          orderNumber: convertToCanonicalOrderNumber(orderNumber),
        })
        .get({
          queryArgs: {
            expand: [
              'lineItems[*].supplyChannel',
              'lineItems[*].state[*].state',
            ],
          },
        });
  const { body } = await orderQuery.execute();

  return isGlobaleOrder
    ? (body as OrderPagedQueryResponse).results[0]
    : (body as Order);
};
