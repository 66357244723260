import styled, { css, DefaultTheme } from 'styled-components';

interface TextInputBorderProps {
  border?: 'none' | 'stroke' | 'outline';
  disabled?: boolean;
  hasError?: boolean;
}

export const selectBorderColor = ({
  border,
  disabled,
  hasError,
}: TextInputBorderProps): keyof DefaultTheme['colors'] => {
  if (hasError) {
    return 'failure';
  }

  if (border === 'outline') {
    if (disabled) {
      return 'grey2';
    }

    return 'grey4';
  }

  if (border === 'stroke') {
    if (disabled) {
      return 'grey6';
    }

    return 'grey4';
  }

  return 'grey0';
};

const TextInputBorder = styled.span<TextInputBorderProps>(
  ({ border, disabled, hasError, theme: { colors } }) => css`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-width: ${border === 'stroke' ? '0 0 1px 0' : '1px'};
    border-style: solid;
    border-radius: ${border === 'stroke' ? '0' : '10px'};
    border-color: ${colors[selectBorderColor({ border, disabled, hasError })]};
    box-shadow: ${border !== 'stroke'
      ? 'inset 0px 4px 4px rgba(0, 0, 0, 0)'
      : 'none'};
    transition: border-color 200ms, box-shadow 200ms, color 200ms;
    pointer-events: none;
  `
);

export default TextInputBorder;
