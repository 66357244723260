import { BooleanInputProps } from 'components/Input/helpers';
import {
  BooleanInputButton,
  BooleanInputContainer,
  BooleanInputField,
  BooleanInputIcon,
  BooleanInputLabel,
} from 'components/Input/InputPartials';
import { FC, useId } from 'react';
import styled from 'styled-components';

const RadioInputButton = styled(BooleanInputButton)`
  border-radius: 50%;
`;

const RadioInput: FC<BooleanInputProps> = ({
  name,
  value,
  checked,
  disabled,
  icon,
  label,
  onChange,
}) => {
  const htmlFieldId = useId();

  return (
    <BooleanInputContainer>
      <BooleanInputField
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        id={htmlFieldId}
        onChange={onChange}
        tabIndex={0}
      />
      <RadioInputButton htmlFor={htmlFieldId} />
      <BooleanInputIcon icon={icon} disabled={disabled} padded={false} />
      <BooleanInputLabel
        as="label"
        hasIcon={Boolean(icon)}
        htmlFor={htmlFieldId}
      >
        {label}
      </BooleanInputLabel>
    </BooleanInputContainer>
  );
};

export default RadioInput;
