import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SidebarState } from 'store/sidebar/types';

export const initialState: SidebarState = {
  open: false,
  history: ['none'],
  returnToIds: [],
};

const { reducer, actions } = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openNav(state, action: PayloadAction<string>) {
      state.open = true;
      state.history = ['none', 'nav'];
      state.returnToIds.push(action.payload);
    },
    openAuth(state, action: PayloadAction<string>) {
      state.open = true;
      state.history.push('auth');
      state.returnToIds.push(action.payload);
    },
    openCreateAccount(state, action: PayloadAction<string>) {
      state.open = true;
      state.history.push('createAccount');
      state.returnToIds.push(action.payload);
    },
    openPrivacyPolicy(state, action: PayloadAction<string>) {
      state.open = true;
      state.history.push('privacyPolicy');
      state.returnToIds.push(action.payload);
    },
    openTermsAndConditions(state, action: PayloadAction<string>) {
      state.open = true;
      state.history.push('termsAndConditions');
      state.returnToIds.push(action.payload);
    },
    goBack(state) {
      if (state.history.length <= 2) {
        return;
      }
      state.returnToIds.pop();
      state.history.pop();
    },
    closeSidebar() {
      return initialState;
    },
    openResetPassword(state, action: PayloadAction<string>) {
      state.open = true;
      state.history.push('resetPassword');
      state.returnToIds.push(action.payload);
    },
  },
});

export const {
  openNav,
  openAuth,
  openCreateAccount,
  openPrivacyPolicy,
  openTermsAndConditions,
  openResetPassword,
  goBack,
  closeSidebar,
} = actions;

export default reducer;
