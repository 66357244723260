import * as t from 'io-ts';
import { attributeModel } from 'models/attributes/types';

export const priceModel = t.type({
  currencyCode: t.string,
  regularPrice: t.number,
  discountedPrice: t.number,
});

export const imageModel = t.type({
  url: t.string,
  label: t.union([t.string, t.null]),
  aspectRatio: t.number,
  contentType: t.string,
});

export const videoModel = t.type({
  url: t.string,
  label: t.union([t.string, t.null]),
  aspectRatio: t.number,
  contentType: t.union([t.string, t.null]),
});

export const variantModel = t.type({
  id: t.number,
  url: t.string,
  sku: t.string,
  availableQuantity: t.number,
  inStock: t.boolean,
  lowStock: t.boolean,
  preorder: t.boolean,
  preorderExpectedDispatch: t.string,
  images: t.array(imageModel),
  videos: t.array(videoModel),
  price: priceModel,
  swatch: attributeModel,
  size: attributeModel,
  materials: t.string,
  careInstructions: t.string,
  variantProductDescription: t.string,
  gender: t.string,
  tag: t.string,
  available: t.boolean,
  finalSale: t.boolean,
});

export type VideoModel = t.TypeOf<typeof videoModel>;
export type ImageModel = t.TypeOf<typeof imageModel>;
export type PriceModel = t.TypeOf<typeof priceModel>;
export type VariantModel = t.TypeOf<typeof variantModel>;
