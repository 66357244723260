import {
  CartDiscount,
  CartDiscountReference,
  DiscountCodeInfo,
  DiscountedLineItemPortion,
} from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import translate from 'lib/translate';
import { formatAsCurrency } from 'lib/util';
import {
  CartDiscountModel,
  DiscountModel,
  cartDiscountModel,
  discountModel,
} from 'models/discounts/types';
import { deriveDiscountAmount } from 'models/discounts/utilities';
import { Currency, Language } from 'models/locales/types';

type ToDiscount = (args: {
  discount: DiscountCodeInfo | undefined;
  language: Language;
  currency: Currency;
}) => DiscountModel | null;

type ToDiscountMap = (args: {
  discountCodes?: DiscountCodeInfo[];
  cartDiscounts?: DiscountedLineItemPortion[];
  language: Language;
  currency: Currency;
}) => {
  discountCodes: Record<string, DiscountModel>;
  cartDiscounts: Record<string, CartDiscountModel>;
};

type ToCartDiscount = (args: {
  discount: DiscountedLineItemPortion | undefined;
  language: Language;
  currency: Currency;
}) => CartDiscountModel | null;

type DeduplicateCartDiscounts = (args: {
  discountCodes: DiscountCodeInfo[];
  cartDiscounts: DiscountedLineItemPortion[];
}) => DiscountedLineItemPortion[];

export const toDiscount: ToDiscount = ({ discount, language, currency }) => {
  if (!discount?.discountCode.obj?.cartDiscounts) {
    return null;
  }

  const t = translate(language);
  const cartDiscounts: CartDiscountModel[] =
    discount.discountCode.obj.cartDiscounts
      .filter(cartDiscount => cartDiscount.obj !== undefined)
      .map(cartDiscount => {
        const d = cartDiscount.obj as CartDiscount;
        const model: CartDiscountModel = {
          label: t(d.name),
          amount: deriveDiscountAmount(d.value, currency),
          id: discount.discountCode.id,
          order: parseFloat(d.sortOrder),
          name: t(cartDiscount.obj?.name),
          target: cartDiscount.obj?.target?.type,
        };
        return model;
      });

  const model: DiscountModel = {
    id: discount.discountCode.id,
    name: t(discount.discountCode.obj?.name),
    state: discount.state,
    cartDiscounts,
  };

  return pipe(
    discountModel.decode(model),
    fold(
      reportTypeErrors({
        id: discount.discountCode.id,
        model: 'Discounts',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const toCartDiscount: ToCartDiscount = ({
  discount,
  language,
  currency,
}) => {
  const t = translate(language);

  const d = (discount?.discount as CartDiscountReference).obj;

  if (!d) {
    return null;
  }

  const model: CartDiscountModel = {
    label: t(d.description) || t(d.name),
    amount: `-${formatAsCurrency(
      currency,
      discount!.discountedAmount.centAmount /
        Math.pow(10, discount!.discountedAmount.fractionDigits)
    )}`,
    id: d.id,
    order: parseFloat(d.sortOrder),
    name: t(d.name),
    target: d.target?.type,
  };

  return pipe(
    cartDiscountModel.decode(model),
    fold(
      reportTypeErrors({
        id: d.id,
        model: 'Discounts',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const deduplicateCartDiscounts: DeduplicateCartDiscounts = ({
  discountCodes,
  cartDiscounts,
}) => {
  const cartDiscountMap = discountCodes
    .flatMap(d => d.discountCode.obj?.cartDiscounts)
    .reduce<Record<string, true>>((acc, curr) => {
      if (curr) {
        acc[curr.id] = true;
      }
      return acc;
    }, {});

  return cartDiscounts.filter(({ discount }) => !cartDiscountMap[discount.id]);
};

export const toDiscountMap: ToDiscountMap = ({
  discountCodes: dc = [],
  cartDiscounts: cd = [],
  language,
  currency,
}) => {
  const deduplicatedCartDiscounts = deduplicateCartDiscounts({
    discountCodes: dc,
    cartDiscounts: cd,
  });

  const discountCodes = dc.reduce<Record<string, DiscountModel>>(
    (acc, discount) => {
      const model = toDiscount({ discount, language, currency });

      if (model !== null) {
        acc[model.id] = model;
      }

      return acc;
    },
    {}
  );

  const cartDiscounts = deduplicatedCartDiscounts.reduce<
    Record<string, CartDiscountModel>
  >((acc, discount) => {
    const model = toCartDiscount({ discount, language, currency });

    if (model !== null) {
      acc[model.id] = model;
    }

    return acc;
  }, {});

  return {
    discountCodes,
    cartDiscounts,
  };
};
