import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { IFooter } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { footerModel, FooterModel } from 'models/contentful/footer/types';
import toLink from 'models/contentful/link/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import toSocialLink from 'models/contentful/socialLink/serializers';

export const defaultFooterModel: FooterModel = {
  socialLinks: [],
  columnOneTitle: '',
  columnOneLinks: [],
  columnTwoTitle: '',
  columnTwoLinks: [],
  joinTitle: 'Join the list',
  joinDescription:
    'Sign up for 10% off your first order and VIP access to the latest collections, events and promotions.',
};

const toFooter: ContentModeller<IFooter, FooterModel> = ({
  content,
  locale,
}) => {
  return pipe(
    footerModel.decode({
      socialLinks: content.fields.socialLinks
        ? content.fields.socialLinks?.map(item =>
            toSocialLink({ content: item, locale })
          )
        : [],
      columnOneTitle: content.fields.columnOneTitle,
      columnOneLinks: content.fields.columnOneLinks.map(item =>
        toLink({ content: item, locale })
      ),
      columnTwoTitle: content.fields.columnTwoTitle,
      columnTwoLinks: content.fields.columnTwoLinks.map(item =>
        toLink({ content: item, locale })
      ),

      joinTitle: content.fields.joinTitle || defaultFooterModel.joinTitle,
      joinDescription:
        content.fields.joinDescription || defaultFooterModel.joinDescription,
    }),
    fold(
      reportTypeErrors({
        model: 'Footer',
        id: content.sys.id,
        fallback: defaultFooterModel,
      }),
      returnValidModel
    )
  );
};

export default toFooter;
