import CartDiscountAdjuster from 'components/CartDiscountAdjuster';
import CartQuantityAdjuster from 'components/CartQuantityAdjuster';
import cookie from 'cookie';
import { addYear } from 'lib/locale';
import { setOmetriaBasket } from 'lib/ometria';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import {
  selectCartTotalCentAmount,
  selectInvalidDiscountCodes,
  selectOrderedCartItemIds,
  selectOrderedCartItemIdsHash,
} from 'store/cart/selectors';
import { useSelector } from 'store/hooks';

export const setGlobaleCartId = (cartId: string, w: Window) => () => {
  if (typeof w?.document?.cookie !== 'string') {
    return;
  }

  w.document.cookie = cookie.serialize('cartId', cartId, {
    path: '/',
    expires: addYear(new Date()),
  });
};

const CartListener: FC = () => {
  const router = useRouter();
  const cart = useSelector(state => state.cart);
  const cartId = useSelector(state => state.cart.id);
  const lineItemIds = useSelector(selectOrderedCartItemIds);
  const itemIdsHash = useSelector(selectOrderedCartItemIdsHash);
  const totalCentAmount = useSelector(selectCartTotalCentAmount);
  const invalidDiscountCodes = useSelector(selectInvalidDiscountCodes);

  useEffect(() => {
    setOmetriaBasket({ cart, locale: router.query.locale as string });
  }, [itemIdsHash + totalCentAmount]);

  useEffect(setGlobaleCartId(cartId, global.window), [cartId]);

  return (
    <>
      {invalidDiscountCodes.map(({ id, name }) => (
        <CartDiscountAdjuster
          key={id}
          id={id}
          name={name}
          itemCount={lineItemIds.length}
        />
      ))}
      {lineItemIds.map(lineItemId => (
        <CartQuantityAdjuster key={lineItemId} id={lineItemId} />
      ))}
    </>
  );
};

export default CartListener;
