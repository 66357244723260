import {
  Cart,
  MyCartUpdateAction,
  PaymentReference,
} from '@commercetools/platform-sdk';
import commerceTools from 'lib/commerceTools';
import { getClientSideFeatureFlags } from 'lib/featureFlags';
import { getApiLocale } from 'lib/locale';
import nullable from 'lib/nullable';
import { getOrigin, toKeyedObject } from 'lib/util';
import { isEmail } from 'lib/validation';
import zeroable from 'lib/zeroable';
import { toCartItemMap } from 'models/cartItems/serializers';
import { addExistingCartItemsModel } from 'models/cartItems/types';
import { deriveCartItemDisplayPrice } from 'models/cartItems/utilities';
import { toDiscountMap } from 'models/discounts/serializers';
import { CartDiscountModel } from 'models/discounts/types';
import { Currency } from 'models/locales/types';
import { toProductDetails } from 'models/productDetails/serializers';
import { ProductDetailsModel } from 'models/productDetails/types';
import { shippingInfoToShippingMethod } from 'models/shippingMethods/serializers';
import { RecalculateRequest } from 'store/cart';
import type {
  AddDiscountCodeRequest,
  AddExistingLineItemsRequest,
  AddRequest,
  ChangeLineItemQuantityRequest,
  ChangeLineItemVariantRequest,
  CreateNewCartRequestBody,
  DownstreamCartState,
  MergeWithCustomerCartRequest,
  ModelResponse,
  PreviousTransaction,
  RemoveDiscountCodeRequest,
  RetrieveCartMetaRequest,
  RetrieveCartProductDetailsRequest,
  RetrieveCartRequest,
  RetrieveOrCreateCartRequest,
  SetBillingAddressRequest,
  SetEmailRequest,
  SetMarketingOptinRequest,
  SetShippingAddressRequest,
  SetShippingMethodRequest,
} from 'store/cart/types';

export const expand = [
  'discountCodes[*].discountCode',
  'discountCodes[*].discountCode.cartDiscounts[*]',
  'shippingInfo.shippingMethod[*]',
  'shippingInfo[*].shippingMethod[*]',
  'shippingInfo.discountedPrice.includedDiscounts[*].discount',
  'lineItems[*].productType',
  'lineItems[*].discountedPricePerQuantity[*].discountedPrice.includedDiscounts[*].discount',
  'paymentInfo.payments[*].paymentMethodInterface',
];

export const retrieveCartProductDetailsRequest: RetrieveCartProductDetailsRequest =
  async ({ ids = [], locale }) => {
    const origin = getOrigin();

    if (ids.length === 0) {
      return {};
    }

    const productIds: string = Object.values(
      ids.reduce<Record<string, string>>((acc, curr) => {
        acc[curr] = `"${curr}"`;
        return acc;
      }, {})
    ).join(', ');

    try {
      const {
        body: { results: products },
      } = await commerceTools
        .productProjections()
        .get({
          queryArgs: {
            limit: 500,
            where: `id in (${productIds})`,
            priceCurrency: locale.currency,
            priceCountry: locale.country,
            storeProjection: locale.store,
            expand: [
              'masterVariant.attributes[*].value[*][*].value',
              'masterVariant.attributes[*].value[*][*].value.masterData.current.categories[*]',
              'productType',
              'categories[*]',
            ],
          },
        })
        .execute();

      return toKeyedObject(
        products
          .map(product =>
            toProductDetails({
              product,
              origin,
              locale,
            })
          )
          .filter(
            (product): product is ProductDetailsModel => product !== null
          ),
        'id'
      );
    } catch (e) {
      return {};
    }
  };

export const derivePreviousTransactions = (args: {
  cartId: string;
  payments?: PaymentReference[];
}): PreviousTransaction[] => {
  if (!args.payments) {
    return [];
  }

  return args.payments.reduce<PreviousTransaction[]>((acc, curr) => {
    curr.obj?.transactions.forEach(transaction => {
      if (
        // Leaving store-credit in so as not to break display of legacy transactions.
        transaction?.interactionId !== 'store-credit' &&
        transaction?.state !== 'Failure'
      ) {
        acc.push({
          cartId: args.cartId,
          paymentId: curr.id,
          transactionId: transaction.id,
        });
      }
    });

    return acc;
  }, []);
};

export const modelResponse: ModelResponse = async ({ cart, locale }) => {
  const { language } = getApiLocale(locale);

  const items = toCartItemMap({
    lineItems: cart.lineItems,
    locale,
  });

  const products = await retrieveCartProductDetailsRequest({
    ids: cart.lineItems.map(({ productId }) => productId),
    locale: getApiLocale(locale),
  });

  const { discountCodes, cartDiscounts } = toDiscountMap({
    discountCodes: cart.discountCodes,
    cartDiscounts: [],
    currency: cart.totalPrice.currencyCode as Currency,
    language,
  });

  const giftDiscounts: Record<string, CartDiscountModel> = {};

  for (const id in items) {
    if (!items[id].isGift) {
      continue;
    }

    for (const discountId in items[id].discounts) {
      giftDiscounts[discountId] = items[id].discounts[discountId];
    }
  }

  const previousTransactions = derivePreviousTransactions({
    cartId: cart.id,
    payments: cart.paymentInfo?.payments,
  });

  const taxInclusive =
    getClientSideFeatureFlags().isAvalaraEnabled === false
      ? true
      : cart.taxedPrice?.totalGross
      ? cart.taxedPrice.totalGross.centAmount === cart.totalPrice.centAmount
      : true;

  const customerId = cart.customerId ?? cart.anonymousId ?? '';

  const response: DownstreamCartState = {
    items,
    products,
    id: cart.id,
    country: [cart.country].join(),
    version: cart.version,
    customerId,
    email: [cart.customerEmail].join(''),
    itemIds: Object.keys(items),
    total: cart.totalPrice,
    displayPrice: deriveCartItemDisplayPrice(
      cart.taxedPrice?.totalGross ?? cart.totalPrice
    ),
    displayTax: deriveCartItemDisplayPrice(cart.taxedPrice?.totalTax),
    taxInclusive,
    totalIncludingTax: cart.taxedPrice?.totalGross ?? cart.totalPrice,
    shippingAddress: nullable(cart.shippingAddress),
    billingAddress: nullable(cart.billingAddress),
    shippingAmountIncludingTax: zeroable(
      cart.shippingInfo?.taxedPrice?.totalGross.centAmount
    ),
    shippingTaxPercentage: zeroable(cart.shippingInfo?.taxRate?.amount),
    shippingMethod: shippingInfoToShippingMethod({
      shippingInfo: cart.shippingInfo,
    }),
    discountCodes,
    previousTransactions,
    store: cart.store,
    cartDiscounts: { ...cartDiscounts, ...giftDiscounts },
  };

  return response;
};

export const createNewCartRequestBody: CreateNewCartRequestBody = async ({
  token,
  anonymousId,
  customerEmail,
  locale,
}) => {
  const { country, currency, store } = getApiLocale(locale);
  const request = { country, currency, store: { key: store } };

  if (customerEmail) {
    return {
      ...request,
      customerEmail,
    };
  }

  if (anonymousId) {
    return request;
  }

  try {
    const customer = await commerceTools
      .me()
      .get({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .execute();

    return {
      ...request,
      customerEmail: customer.body.email,
    };
  } catch (e) {
    return request;
  }
};

export const retrieveCartMetaRequest: RetrieveCartMetaRequest = async ({
  token,
  id,
}) => {
  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .get({
      headers: { Authorization: `Bearer ${token}` },
    })
    .execute();

  return {
    id: body.id,
    version: body.version,
  };
};

export const retrieveCartRequest: RetrieveCartRequest = async ({
  token,
  locale,
  id,
}) => {
  const response = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .get({
      headers: { Authorization: `Bearer ${token}` },
      queryArgs: { expand },
    })
    .execute();

  if (response.body.cartState?.toLowerCase() !== 'active') {
    throw new Error('Cart is not active');
  }

  return modelResponse({
    cart: response.body,
    locale,
  });
};

export const retrieveOrCreateCartRequest: RetrieveOrCreateCartRequest = async ({
  token,
  locale,
  anonymousId,
  customerEmail,
  id,
}) => {
  const newCartFallback = async () => {
    const newCartRequestBody = await createNewCartRequestBody({
      token,
      anonymousId,
      customerEmail,
      locale,
      inventoryMode: 'TrackOnly',
    });
    const response = await commerceTools
      .me()
      .carts()
      .post({
        headers: { Authorization: `Bearer ${token}` },
        queryArgs: {
          expand,
        },
        body: newCartRequestBody,
      })
      .execute();

    return modelResponse({
      cart: response.body,
      locale,
    });
  };

  if (id) {
    try {
      const response = await commerceTools
        .me()
        .carts()
        .withId({ ID: id })
        .get({
          headers: { Authorization: `Bearer ${token}` },
          queryArgs: { expand },
        })
        .execute();

      if (response.body.cartState?.toLowerCase() !== 'active') {
        throw new Error('Cart is not active, generating new cart');
      }

      // Graphene's UID's source of truth is the cart's customer or anonymous ID
      await fetch('/services/graphene/session', {
        method: 'POST',
        body: response.body.customerId ?? response.body.anonymousId,
      });

      return modelResponse({
        cart: response.body,
        locale,
      });
    } catch (e) {
      return await newCartFallback();
    }
  } else {
    return await newCartFallback();
  }
};

export const recalculateRequest: RecalculateRequest = async ({
  id,
  version,
  locale,
  token,
}) => {
  const response = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: { Authorization: `Bearer ${token}` },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'recalculate',
          },
        ],
      },
    })
    .execute();

  return modelResponse({
    cart: response.body,
    locale,
  });
};

export const addRequest: AddRequest = async ({
  id,
  token,
  locale,
  productId,
  variantId,
  quantity,
  version,
}) => {
  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'addLineItem',
            productId,
            variantId,
            quantity,
          },
        ],
      },
    })
    .execute();

  await fetch(`/services/graphene/add-to-cart`, {
    method: 'POST',
    body: JSON.stringify({
      productId,
      quantity,
    }),
  });

  return modelResponse({ cart: body, locale });
};

export const changeLineItemQuantityRequest: ChangeLineItemQuantityRequest =
  async ({ id, token, locale, lineItemId, quantity }) => {
    const { version } = await retrieveCartMetaRequest({ token, id });

    const { body } = await commerceTools
      .me()
      .carts()
      .withId({ ID: id })
      .post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        queryArgs: { expand },
        body: {
          version,
          actions: [
            {
              action: 'changeLineItemQuantity',
              lineItemId,
              quantity,
            },
          ],
        },
      })
      .execute();

    return modelResponse({ cart: body, locale });
  };

export const changeLineItemVariantRequest: ChangeLineItemVariantRequest =
  async ({ id, token, locale, productId, lineItemId, variantId, quantity }) => {
    const { version } = await retrieveCartMetaRequest({ token, id });

    const { body } = await commerceTools
      .me()
      .carts()
      .withId({ ID: id })
      .post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        queryArgs: { expand },
        body: {
          version,
          actions: [
            {
              action: 'removeLineItem',
              lineItemId,
            },
            {
              action: 'addLineItem',
              productId,
              variantId,
              quantity,
            },
          ],
        },
      })
      .execute();

    return modelResponse({ cart: body, locale });
  };

export const addDiscountCodeRequest: AddDiscountCodeRequest = async ({
  id,
  token,
  locale,
  code,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'addDiscountCode',
            code,
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const removeDiscountCodeRequest: RemoveDiscountCodeRequest = async ({
  id,
  token,
  locale,
  discountId,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'removeDiscountCode',
            discountCode: {
              id: discountId,
              typeId: 'discount-code',
            },
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const setEmailRequest: SetEmailRequest = async ({
  id,
  token,
  locale,
  email,
}) => {
  if (!isEmail(email)) {
    throw new Error('Invalid email address');
  }

  const { version } = await retrieveCartMetaRequest({ token, id });

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'setCustomerEmail',
            email,
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const setMarketingOptInRequest: SetMarketingOptinRequest = async ({
  id,
  token,
  locale,
  optIn,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'setCustomType',
            type: {
              key: 'order',
              typeId: 'type',
            },
            fields: {
              marketing_optin: optIn,
              marketing_sms_optin: optIn,
            },
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const mergeWithCustomerCartRequest: MergeWithCustomerCartRequest =
  async ({ token, locale, email, password }) => {
    const { body } = await commerceTools
      .me()
      .login()
      .post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          email,
          password,
        },
      })
      .execute();

    // Update Graphene UID cookie to reflect the customer's ID.
    await fetch('/services/graphene/session', {
      method: 'POST',
      body: body.cart?.customerId ?? body.cart?.anonymousId,
    });

    return modelResponse({ cart: body.cart as Cart, locale });
  };

export const setShippingAddressRequest: SetShippingAddressRequest = async ({
  id,
  token,
  locale,
  address,
  saveToBillingAddress,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });

  const actions: MyCartUpdateAction[] = [
    {
      action: 'setShippingMethod',
    },
    {
      action: 'setShippingAddress',
      address,
    },
  ];

  if (saveToBillingAddress) {
    actions.push({
      action: 'setBillingAddress',
      address,
    });
  }

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions,
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const setBillingAddressRequest: SetBillingAddressRequest = async ({
  id,
  token,
  locale,
  address,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });
  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'setBillingAddress',
            address,
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const setShippingMethodRequest: SetShippingMethodRequest = async ({
  id,
  token,
  locale,
  shippingMethodId,
}) => {
  const { version } = await retrieveCartMetaRequest({ token, id });

  const { body } = await commerceTools
    .me()
    .carts()
    .withId({ ID: id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryArgs: { expand },
      body: {
        version,
        actions: [
          {
            action: 'setShippingMethod',
            shippingMethod: {
              id: shippingMethodId,
              typeId: 'shipping-method',
            },
          },
        ],
      },
    })
    .execute();

  return modelResponse({ cart: body, locale });
};

export const addExistingLineItemsRequest: AddExistingLineItemsRequest = async ({
  id,
  previousCartId,
  token,
  locale,
  version,
}) => {
  try {
    const response = await fetch(
      `/services/retrieve-existing-cart/${previousCartId}`
    );
    const actions = await response.json();

    if (!addExistingCartItemsModel.is(actions)) {
      throw new Error();
    }

    const { body } = await commerceTools
      .me()
      .carts()
      .withId({ ID: id })
      .post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        queryArgs: { expand },
        body: {
          version,
          actions,
        },
      })
      .execute();

    return modelResponse({ cart: body, locale });
  } catch (e) {
    throw new Error(
      'Unfortunately we were unable to retrieve your previous bag.'
    );
  }
};
