import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnouncerState } from 'store/announcer/types';

export const initialState: AnnouncerState = {
  messages: {
    assertiveMessage1: '',
    assertiveMessage2: '',
    politeMessage1: '',
    politeMessage2: '',
  },
  alternateAssertive: false,
  alternatePolite: false,
};

const { reducer, actions } = createSlice({
  name: 'announcer',
  initialState,
  reducers: {
    announce(
      state,
      action: PayloadAction<{
        message: string;
        level: 'assertive' | 'polite';
        allowRepeat?: boolean;
      }>
    ) {
      if (action.payload.level === 'assertive') {
        const lastMessage = state.alternateAssertive
          ? state.messages.assertiveMessage1
          : state.messages.assertiveMessage2;
        if (
          action.payload.allowRepeat ||
          lastMessage !== action.payload.message
        ) {
          state.messages = {
            ...state.messages,
            assertiveMessage1: state.alternateAssertive
              ? ''
              : action.payload.message,
            assertiveMessage2: state.alternateAssertive
              ? action.payload.message
              : '',
          };
          state.alternateAssertive = !state.alternateAssertive;
        }
      }

      if (action.payload.level === 'polite') {
        const lastMessage = state.alternatePolite
          ? state.messages.politeMessage1
          : state.messages.politeMessage2;
        if (
          action.payload.allowRepeat ||
          lastMessage !== action.payload.message
        ) {
          state.messages = {
            ...state.messages,
            politeMessage1: state.alternatePolite ? '' : action.payload.message,
            politeMessage2: state.alternatePolite ? action.payload.message : '',
          };
          state.alternatePolite = !state.alternatePolite;
        }
      }
    },
  },
});

export const { announce } = actions;
export default reducer;
