import HeaderButton, {
  HeaderButtonProps,
} from 'components/HeaderButtons/HeaderButton';
import { Chevron } from 'components/Icons';
import { Special } from 'components/Typography';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const DesktopOnlyBackTitle = styled(Special)(
  ({ theme: { bp } }) => css`
    display: none;
    left: 24px;

    ${bp.l} {
      display: block;
    }
  `
);

const BackButton: FC<Omit<HeaderButtonProps, 'children'>> = ({
  className,
  ...buttonProps
}) => {
  return (
    <HeaderButton {...buttonProps} className={className} title="Back">
      <Chevron direction="left" />
      <DesktopOnlyBackTitle variant="1" size="L">
        Back
      </DesktopOnlyBackTitle>
    </HeaderButton>
  );
};

export default BackButton;
