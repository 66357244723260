import type { TextInputProps } from 'components/Input/helpers';
import styled, { css } from 'styled-components';

const TextInputPositioner = styled.span<Pick<TextInputProps, 'border'>>(
  ({ border, theme: { colors } }) => css`
    display: flex;
    flex-direction: row;
    height: 56px;
    position: relative;
    border-radius: ${border === 'stroke' ? '0' : '10px'};
    background-color: ${border === 'stroke' || border === 'outline'
      ? colors.white
      : colors.grey0};
  `
);

export default TextInputPositioner;
