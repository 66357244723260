import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { IFlashBanner } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  flashBannerModel,
  FlashBannerModel,
} from 'models/contentful/flashBanner/types';
import { generateLocalizedUrl } from 'models/contentful/link/serializers';
import { ContentModeller } from 'models/contentful/shared/types';

const toFlashBanner: ContentModeller<IFlashBanner, FlashBannerModel> = ({
  content,
  locale,
}) => {
  const model = {
    title: content.fields.title,
    link: generateLocalizedUrl(content.fields.link, locale),
  } as FlashBannerModel;

  return pipe(
    flashBannerModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Flash Banner',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toFlashBanner;
