import { LineItemReturnItem, Order } from '@commercetools/platform-sdk';
import { getApiLocale } from 'lib/locale';
import translate from 'lib/translate';
import { ReturnModel } from 'models/returns/types';
import { toVariant } from 'models/variants/serializers';

type ToReturns = (args: { order: Order; locale: string }) => ReturnModel[];

export const toReturns: ToReturns = ({ order, locale }) => {
  const { language } = getApiLocale(locale);
  const t = translate(language);

  if (!order.returnInfo) {
    return [];
  }

  return order.returnInfo.map(returnInfo => {
    const returnItems = returnInfo.items.map(returnItem => {
      const lineItemReturnItem = returnItem as LineItemReturnItem;
      const lineItem = order.lineItems.find(
        i => i.id == lineItemReturnItem.lineItemId
      );

      if (!lineItem) {
        throw Error(
          `No line matching lineItem for ${lineItemReturnItem.lineItemId}`
        );
      }

      const variant = toVariant({
        variant: lineItem.variant,
        slug: lineItem.productSlug?.en as string,
        locale,
      });

      if (!variant) {
        throw Error(
          `Unable to create variant for lineItem ${lineItemReturnItem.lineItemId}`
        );
      }

      return {
        slug: lineItem.productSlug?.en as string,
        name: t(lineItem.name),
        quantity: lineItemReturnItem.quantity,
        image: variant.images[0]?.url as string,
        size: variant.size,
        swatch: variant.swatch,
        itemReceived: lineItemReturnItem.shipmentState == 'BackInStock',
        refundSent: lineItemReturnItem.paymentState == 'Refunded',
      };
    });

    return {
      createdAt: returnInfo.items[0].createdAt as string,
      items: returnItems,
    };
  });
};
