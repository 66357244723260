import Alternates from 'components/Alternates';
import BreakpointListener from 'components/BreakpointListener';
import CampaignSuccessListener from 'components/CampaignCompleteListener';
import CartListener from 'components/CartListener';
import { CountryMismatchToastContainer } from 'components/CountryMismatchToast';
import DimensionsListener from 'components/DimensionsListener';
import ErrorListener from 'components/ErrorListener';
import Geolocate from 'components/Geolocate';
import GlobalStyles from 'components/GlobalStyles';
import HelpScout from 'components/HelpScout';
import LocaleListener from 'components/LocaleListener';
import MagentoContentStyles from 'components/MagentoContentStyles';
import MetaHead from 'components/MetaHead';
import NavigationProvider from 'components/NavigationProvider';
import SiteHead from 'components/SiteHead';
import fetchNav from 'lib/contentful/fetchNav';
import fetchSettings from 'lib/contentful/fetchSettings';
import { getLocaleFromPath } from 'lib/locale/locale';
import { FooterModel } from 'models/contentful/footer/types';
import { SiteSettingsModel } from 'models/contentful/siteSettings/types';
import {
  DesktopNavigationModel,
  MobileNavigationModel,
} from 'models/navigation/types';
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import store, { onBeforeLift, persistor } from 'store';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'theme/theme';

interface InitialProps {
  desktopNavigation: DesktopNavigationModel | null;
  mobileNavigation: MobileNavigationModel | null;
  siteSettings?: SiteSettingsModel;
  footer?: FooterModel;
}

export type PageProps<
  T extends Record<string, unknown> = Record<string, unknown>
> = {
  footer?: FooterModel;
  siteSettings?: SiteSettingsModel;
} & T;

type GetInitialProps = (
  context: AppContext
) => Promise<AppInitialProps & InitialProps>;

type OverloadedApp = typeof App & {
  getInitialProps: typeof getInitialProps;
};

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

const Modal = dynamic(() => import('components/Modal'), { ssr: false });

const Sidebar = dynamic(() => import('components/Sidebar'), { ssr: false });

const SkipLink = dynamic(() => import('components/SkipLink'), { ssr: false });
const Announcer = dynamic(() => import('components/Announcer'), { ssr: false });

const Autocomplete = dynamic(() => import('components/Autocomplete'), {
  ssr: false,
});

const initialState = store.getState();

const App: FC<AppProps<PageProps> & InitialProps> = props => {
  const { asPath, route, query } = useRouter();
  const locale = [query.locale].join('');

  const {
    Component,
    pageProps,
    desktopNavigation,
    mobileNavigation,
    siteSettings,
    footer,
  } = props;

  return (
    <>
      <SiteHead faviconUrl={siteSettings?.favicon?.url} />
      <Geolocate />
      <MetaHead {...siteSettings} />
      {route !== '/[locale]/p/[product]/[[...swatch]]' ? (
        <Alternates pathname={asPath} />
      ) : null}
      <NavigationProvider
        desktopNavigation={desktopNavigation}
        mobileNavigation={mobileNavigation}
      >
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <MagentoContentStyles />
          <Provider store={store} serverState={initialState}>
            <PersistGate
              loading={null}
              persistor={persistor}
              onBeforeLift={onBeforeLift(store, locale)}
            >
              {() => (
                <>
                  <SkipLink />
                  <Announcer />
                  <ErrorListener />
                  <CartListener />
                  <DimensionsListener />
                  <BreakpointListener />
                  <LocaleListener />
                  <CampaignSuccessListener />
                  <ToastContainer
                    autoClose={3000}
                    position="top-center"
                    hideProgressBar
                    closeButton={false}
                    icon={false}
                  />
                  <CountryMismatchToastContainer />
                  <Component
                    {...pageProps}
                    siteSettings={siteSettings}
                    footer={footer}
                  />
                  <Sidebar />
                  <Modal />
                  <Autocomplete />
                </>
              )}
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </NavigationProvider>
      <HelpScout enabled={true} />
    </>
  );
};

export const getInitialProps: GetInitialProps = async context => {
  const appProps = await NextApp.getInitialProps(context);

  const locale = getLocaleFromPath(context.router.asPath, 'en-us');

  const [navigation, settings] = await Promise.all([
    fetchNav(locale, global.window),
    fetchSettings(locale, global.window),
  ]);

  return {
    ...appProps,
    ...navigation,
    ...settings,
  };
};
(App as OverloadedApp).getInitialProps = getInitialProps;

export default App;
