import * as t from 'io-ts';
import { language } from 'models/locales/types';

export const enumerable = t.interface({
  name: t.string,
  value: t.interface({
    key: t.string,
    label: t.string,
  }),
});

export const localisedEnumerable = t.interface({
  name: t.string,
  value: t.interface({
    key: t.string,
    label: t.record(language, t.string),
  }),
});

export const localisedEnumerableArray = t.interface({
  name: t.string,
  value: t.array(
    t.interface({
      key: t.string,
      label: t.record(language, t.string),
    })
  ),
});

export const localisedText = t.interface({
  name: t.string,
  value: t.record(language, t.string),
});

export const text = t.interface({
  name: t.string,
  value: t.string,
});

export const textArray = t.interface({
  name: t.string,
  value: t.array(t.string),
});

export const number = t.interface({
  name: t.string,
  value: t.number,
});

export const attributeModel = t.interface({
  key: t.string,
  value: t.string,
});

export const attributesModel = t.record(t.string, t.array(attributeModel));

export type AttributeModel = t.TypeOf<typeof attributeModel>;
export type AttributesModel = t.TypeOf<typeof attributesModel>;
