import {
  GLOBAL_E_ORDER_MESSAGE,
  RetrieveReturnableProductsFromOrderResponse,
} from 'lib/returns/retrieveReturnableProductsFromOrder';
import {
  ModelResponse,
  RetrieveProductsFromOrderRequest,
  ReturnProductsRequest,
} from 'store/returns/types';

export const modelResponse: ModelResponse = ({
  email,
  orderNumber,
  orderState,
  items,
  returns,
  cancellations,
}) => ({
  email,
  orderNumber,
  orderState,
  items,
  itemIds: items ? Object.keys(items) : undefined,
  returns,
  cancellations,
});

export const retrieveProductsFromOrderRequest: RetrieveProductsFromOrderRequest =
  async ({ email, locale, orderNumber }) => {
    const response = await fetch(
      '/services/returns/retrieve-products-from-order',
      {
        method: 'post',
        body: new URLSearchParams(
          Object.entries({
            email: email.trim(),
            orderNumber: orderNumber.trim(),
            locale,
          })
        ).toString(),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const body =
      (await response.json()) as RetrieveReturnableProductsFromOrderResponse;

    if (body.success === false) {
      if (body.message === GLOBAL_E_ORDER_MESSAGE && body.redirectURL) {
        window.location.href = body.redirectURL;
      }
      throw new Error(body.message);
    }

    return modelResponse(body);
  };

export const returnProductsRequest: ReturnProductsRequest = async ({
  email,
  locale,
  orderNumber,
  items,
}) => {
  const itemsArray = Object.keys(items).map(key => ({
    lineItemId: key,
    ...items[key],
  }));

  const response = await fetch('/services/returns/return-products', {
    method: 'post',
    body: new URLSearchParams(
      Object.entries({
        email: email.trim(),
        orderNumber: orderNumber.trim(),
        locale,
        items: JSON.stringify(itemsArray),
      })
    ).toString(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  const body =
    (await response.json()) as RetrieveReturnableProductsFromOrderResponse;

  if (body.success === false) {
    throw new Error(body.message);
  }

  return modelResponse(body);
};
