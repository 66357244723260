import { Address } from '@commercetools/platform-sdk';
import { createSelector } from '@reduxjs/toolkit';
import { toKeyedObject } from 'lib/util';
import type { CartItemModel } from 'models/cartItems/types';
import type { CartDiscountModel, DiscountModel } from 'models/discounts/types';
import { AntiFraudCustomerData } from 'models/payments/types';
import { deriveAvsAddress, deriveShopperName } from 'models/payments/utilities';
import { ProductDetailsModel } from 'models/productDetails/types';
import { PersistedState } from 'redux-persist';
import type {
  CartAddressType,
  CartState,
  PreviousTransaction,
} from 'store/cart';
import type { State } from 'store/types';

export const generateHash = (ids: string[]): string => ids.join(',');

export const selectCart = (state: State): CartState & Partial<PersistedState> =>
  state.cart;

export const selectCartAddressType = (
  _state: State,
  props: { addressType: CartAddressType }
): CartAddressType => props.addressType;

export const selectId = (_state: State, props: { id: string }): string =>
  props.id;

export const selectCartItem = createSelector(
  selectCart,
  selectId,
  (cart: CartState, id: string): CartItemModel | null => {
    if (!cart.items[id]) {
      return null;
    }

    return cart.items[id];
  }
);

export const selectCartProduct = createSelector(
  selectCart,
  selectCartItem,
  (
    cart: CartState,
    cartItem: CartItemModel | null
  ): ProductDetailsModel | null => {
    if (cartItem === null || cart.products[cartItem.productId] === undefined) {
      return null;
    }

    return cart.products[cartItem.productId];
  }
);

export const selectCartItemIds = createSelector(
  selectCart,
  (cart: CartState): string[] => cart.itemIds
);

export const selectCartItems = createSelector(
  selectCart,
  (cart: CartState): Record<string, CartItemModel> => cart.items
);

export const selectProductIdForCartRecommendations = createSelector(
  selectCartItems,
  (items: Record<string, CartItemModel>): string | undefined => {
    let mostExpensiveItem: CartItemModel | undefined = undefined;

    if (Object.keys(items).length === 0) {
      return undefined;
    }

    for (const id in items) {
      if (
        mostExpensiveItem === undefined ||
        mostExpensiveItem.price.discountedPrice <
          items[id].price.discountedPrice
      ) {
        mostExpensiveItem = items[id];
      }
    }

    return `${mostExpensiveItem?.slug}-${mostExpensiveItem?.swatch.key}`;
  }
);

export const selectCartCount = createSelector(
  selectCartItems,
  (items: Record<string, CartItemModel>): number => {
    let n = 0;
    for (const id in items) {
      n += items[id].quantity;
    }
    return n;
  }
);

export const selectOrderedCartItemIds = createSelector(
  selectCartItemIds,
  selectCartItems,
  (ids: string[], items: Record<string, CartItemModel>): string[] =>
    ids
      .slice()
      .sort((a, b) => items[a].variantSku.localeCompare(items[b].variantSku))
);

export const selectOrderedCartItemIdsHash = createSelector(
  selectOrderedCartItemIds,
  generateHash
);

export const selectCartProductIds = createSelector(
  selectCart,
  (cart): string[] =>
    Object.values(cart.items)
      .map(({ productId }) => productId)
      .sort()
);

export const selectCartSubtotalBeforeCartwideDiscounts = createSelector(
  selectCartItems,
  (items: Record<string, CartItemModel>): number =>
    Object.values(items).reduce((acc, curr) => {
      acc += curr.price.discountedPrice * curr.quantity;
      return acc;
    }, 0)
);

export const selectDiscountCodes = createSelector(
  selectCart,
  (cart: CartState): Record<string, DiscountModel> => cart.discountCodes
);

export const selectCartDiscounts = createSelector(
  selectCart,
  (cart: CartState): Record<string, CartDiscountModel> => cart.cartDiscounts
);

export const selectCartDiscountsArray = createSelector(
  selectCartDiscounts,
  (cartDiscounts: Record<string, CartDiscountModel>): CartDiscountModel[] =>
    Object.values(cartDiscounts)
);

export const selectDiscountCodesArray = createSelector(
  selectDiscountCodes,
  (discounts: Record<string, DiscountModel>): DiscountModel[] =>
    Object.values(discounts)
);

export const selectDiscountCodesLineItems = createSelector(
  selectDiscountCodesArray,
  (discounts: DiscountModel[]): CartDiscountModel[] =>
    discounts.flatMap(discount => discount.cartDiscounts)
);

export const selectOrderedCartDiscountLineItems = createSelector(
  selectDiscountCodesLineItems,
  selectCartDiscountsArray,
  (
    discountCodes: CartDiscountModel[],
    cartDiscounts: CartDiscountModel[]
  ): CartDiscountModel[] => {
    return [...discountCodes, ...cartDiscounts].sort((a, b) =>
      a.order < b.order ? -1 : 1
    );
  }
);

export const selectCartAddress = createSelector(
  selectCart,
  selectCartAddressType,
  (cart: CartState, addressType: CartAddressType): Address | null => {
    if (addressType === 'billing' && cart.billingAddress) {
      return cart.billingAddress;
    }

    if (addressType === 'shipping' && cart.shippingAddress) {
      return cart.shippingAddress;
    }

    return null;
  }
);

export const selectUpdatingCartAddress = createSelector(
  selectCart,
  selectCartAddressType,
  (cart: CartState, addressType: CartAddressType): boolean => {
    if (addressType === 'billing') {
      return cart.inProgress.setBillingAddress;
    }

    if (addressType === 'shipping') {
      return cart.inProgress.setShippingAddress;
    }

    return false;
  }
);

export const selectAdyenAntiFraudData = createSelector(
  selectCart,
  (cart): AntiFraudCustomerData => {
    const antiFraudData: AntiFraudCustomerData = {
      shopperEmail: cart.email,
      shopperReference: cart.email,
      shopperName: deriveShopperName(cart.billingAddress),
      billingAddress: deriveAvsAddress(cart.billingAddress),
      deliveryAddress: deriveAvsAddress(cart.shippingAddress),
      telephoneNumber: cart.billingAddress?.phone,
    };

    for (const key in antiFraudData) {
      const k = key as keyof AntiFraudCustomerData;
      if (!antiFraudData[k]) {
        delete antiFraudData[k];
      }
    }

    return antiFraudData;
  }
);

export const selectCartTotalCentAmount = createSelector(
  selectCart,
  (cart): number => {
    return cart.totalIncludingTax?.centAmount || 0;
  }
);

export const selectCartPreviousTransactions = createSelector(
  selectCart,
  (cart): PreviousTransaction[] => cart.previousTransactions
);

export const selectCartPreviousTransactionsHash = createSelector(
  selectCartPreviousTransactions,
  (previousTransactions: PreviousTransaction[]): string =>
    previousTransactions
      .map(({ transactionId }) => transactionId)
      .sort()
      .join(',')
);

export const selectHasRetrievedCartAtLeastOnce = createSelector(
  selectCart,
  (cart: CartState): boolean =>
    cart.complete.retrieveCart || cart.complete.retrieveOrCreateCart
);

export const selectHasMissingCart = createSelector(
  selectCart,
  (cart: CartState & Partial<PersistedState>): boolean => {
    if (cart._persist && !cart._persist?.rehydrated) {
      return false;
    }

    if (!cart.id) {
      return true;
    }

    if (!cart.complete.retrieveCart && !cart.complete.retrieveOrCreateCart) {
      return false;
    }

    if (!cart.itemIds.length) {
      return true;
    }

    return false;
  }
);

export const selectHasInvalidCartForCheckout = createSelector(
  selectCart,
  selectHasMissingCart,
  (
    cart: CartState & Partial<PersistedState>,
    hasMissingCart: boolean
  ): boolean => {
    if (hasMissingCart) {
      return true;
    }

    if (!cart.email) {
      return true;
    }

    return false;
  }
);

export const selectCartErrors = createSelector(
  selectCart,
  (cart: CartState): CartState['errors'] => cart.errors
);

export const selectGlobalCartErrors = createSelector(
  selectCartErrors,
  (errors: CartState['errors']): string[] => {
    const excludedKeys: (keyof CartState['errors'])[] = [
      'mergeWithCustomerCart',
      'addDiscountCode',
    ];

    return Object.entries(errors)
      .filter(
        ([key, message]) =>
          !excludedKeys.includes(key as keyof CartState['errors']) &&
          message !== ''
      )
      .map(([, message]) => message);
  }
);

export const selectDiscountCodesThatDoNotMatchCart = createSelector(
  selectDiscountCodesArray,
  (discountCodes): { name: string; id: string }[] =>
    discountCodes
      .filter(({ state }) => state !== 'MatchesCart')
      .map(({ id, name }) => ({ id, name }))
);

export const selectUnusedCartLineItemDiscounts = createSelector(
  selectCartItems,
  selectOrderedCartDiscountLineItems,
  (items, discounts): { name: string; id: string }[] => {
    const itemDiscounts: string[] = Object.values(items)
      .filter(item => Object.values(item.discounts).length)
      .flatMap(item =>
        Object.values(item.discounts).map(discount => discount.name)
      );

    return discounts
      .filter(
        ({ name, target }) =>
          target === 'lineItems' && !itemDiscounts.includes(name)
      )
      .map(({ id, name }) => ({ id, name }));
  }
);

export const selectInvalidDiscountCodes = createSelector(
  selectDiscountCodesThatDoNotMatchCart,
  selectUnusedCartLineItemDiscounts,
  (nonMatchingDiscounts, unusedDiscounts): { name: string; id: string }[] =>
    Object.values(
      toKeyedObject(nonMatchingDiscounts.concat(unusedDiscounts), 'id')
    )
);

export const selectHasPreorderCartItems = createSelector(
  selectCartItems,
  (items: Record<string, CartItemModel>): boolean =>
    Object.values(items).some(item => item.isPreorder === true)
);
