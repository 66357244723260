const cartErrorMap: { [key: string]: string } = {
  'Address not geocoded.': 'Given address is invalid, please review',
};
const hiddenCharacter = '\u2008';
export const mapCartError = (currentError: string, newError: string) => {
  const mappedError = cartErrorMap[newError] || newError;
  if (currentError.replace(hiddenCharacter, '') === mappedError) {
    // Add hidden character to ensure that repeated errors get displayed
    return `${mappedError}${
      currentError.includes(hiddenCharacter) ? '' : hiddenCharacter
    }`;
  }
  return mappedError;
};
