import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchState } from 'store/search/types';

export const initialState: SearchState = {
  open: false,
  term: '',
  returnToId: null,
};

const { reducer, actions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    openSearch(state, action: PayloadAction<string>) {
      state.open = true;
      state.returnToId = action.payload;
    },
    closeSearch(state) {
      // Return focus to trigger element - delayed to ensure focus is actually returned to element
      const returnToId = state.returnToId;
      setTimeout(() => {
        returnToId &&
          document.getElementById(returnToId)?.focus({ preventScroll: true });
      }, 0);

      state.open = false;
      state.returnToId = initialState.returnToId;
    },
    clearSearch(state) {
      state.term = initialState.term;
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.term = action.payload;
    },
  },
});

export const { openSearch, closeSearch, clearSearch, setSearchTerm } = actions;
export default reducer;
