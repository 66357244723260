import {
  createCommerceToolsApiClient,
  createCommerceToolsAuthClient,
} from 'lib/commerceTools/clientFactory';
import type { CommerceToolsClientInstantiationArguments } from 'lib/commerceTools/types';

const instantiationArguments: CommerceToolsClientInstantiationArguments = {
  authHost: 'https://auth.europe-west1.gcp.commercetools.com',
  httpHost: 'https://api.europe-west1.gcp.commercetools.com',
  projectKey: `${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
  clientId: `${process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_ID}`,
  clientSecret: `${process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_SECRET}`,
};

export const commerceToolsAuthClient = createCommerceToolsAuthClient(
  instantiationArguments
);

export const commerceToolsClient = createCommerceToolsApiClient(
  instantiationArguments
);
