import { createSelector } from '@reduxjs/toolkit';
import { CampaignsState } from 'store/campaigns/types';
import { State } from 'store/types';

export const selectCampaigns = (state: State): CampaignsState =>
  state.campaigns;

export const selectSomeCampaignsHaveCompleted = createSelector(
  selectCampaigns,
  (campaigns): boolean =>
    Object.values(campaigns.complete).some(
      hasCompleted => hasCompleted === true
    )
);
