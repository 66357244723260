import Gap from 'components/Gap';
import { Title } from 'components/Typography';
import { FC, memo } from 'react';
import styled, { css } from 'styled-components';

interface LabelProps {
  htmlFor?: string;
  label?: string;
  disabled?: boolean;
}

const Label = styled(Title)<Pick<LabelProps, 'disabled'>>(
  ({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `
);

const InputLabel: FC<LabelProps> = ({ label, disabled, htmlFor }) => {
  if (!label) {
    return null;
  }

  return (
    <>
      <Label
        as="label"
        disabled={disabled}
        htmlFor={htmlFor}
        variant="1"
        size="XS"
      >
        {label}
      </Label>
      <Gap size={8} />
    </>
  );
};

export default memo(InputLabel);
