import { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

interface InputLoaderProps {
  loading?: boolean;
  children?: ReactNode;
}

const Loader = styled.span`
  display: flex;
  flex: 1;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
`;

const InputSpinner: FC = memo(() => (
  <svg
    width={19}
    height={19}
    fill="none"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9" stroke="#BEBEC1" />
    <path d="M18.5 9.5C18.5 4.25329 14.2467 0.5 9 0.5" stroke="#707071" />
    <animateTransform
      attributeName="transform"
      type="rotate"
      from="-360 0 0"
      to="0 0 0"
      dur="1s"
      repeatCount="indefinite"
    />
  </svg>
));

const InputLoader: FC<InputLoaderProps> = ({ children, loading }) => {
  if (loading) {
    return (
      <Loader>
        <InputSpinner />
      </Loader>
    );
  }

  return <>{children}</>;
};

export default InputLoader;
