import { ProductType } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  AttributeParentModel,
  productTypeModel,
  ProductTypeModel,
  SkuLookup,
} from 'models/productType/types';
import { deriveSizes, deriveSwatches } from 'models/productType/utilities';
import { VariantModel } from 'models/variants/types';

export const NO_PRODUCT_TYPE: ProductTypeModel = {
  name: '',
  swatches: {},
  sizes: {},
  availableSwatches: [],
  availableSizes: [],
  skuLookup: {},
};

type ToProductType = (args: {
  productType: ProductType;
  variants: Record<string, VariantModel>;
}) => ProductTypeModel;

export const toProductType: ToProductType = ({ productType, variants }) => {
  try {
    const swatches: Record<string, AttributeParentModel> = {};
    const sizes: Record<string, AttributeParentModel> = {};
    const skuLookup: Record<string, SkuLookup> = {};

    for (const v in variants) {
      const variant = variants[v];

      if ((!variant.preorder && !variant.inStock) || !variant.available) {
        continue;
      }

      if (!swatches[variant.swatch.key]) {
        swatches[variant.swatch.key] = {
          label: variant.swatch.value,
          values: {},
        };
      }

      if (!sizes[variant.size.key]) {
        sizes[variant.size.key] = {
          label: variant.size.value,
          values: {},
        };
      }

      skuLookup[variant.sku] = {
        swatch: variant.swatch.key,
        size: variant.size.key,
      };

      swatches[variant.swatch.key].values[variant.size.key] = {
        id: variant.size.key,
        label: variant.size.value,
        inStock: variant.inStock,
        lowStock: variant.lowStock,
        preorder: variant.preorder,
        preorderExpectedDispatch: variant.preorderExpectedDispatch,
        sku: variant.sku,
        url: variant.url,
      };

      sizes[variant.size.key].values[variant.swatch.key] = {
        id: variant.swatch.key,
        label: variant.swatch.value,
        inStock: variant.inStock,
        lowStock: variant.lowStock,
        preorder: variant.preorder,
        preorderExpectedDispatch: variant.preorderExpectedDispatch,
        sku: variant.sku,
        url: variant.url,
      };
    }

    const availableSwatches: string[] = deriveSwatches(
      productType,
      variants
    ).filter(swatch => Object.keys(swatches).includes(swatch));

    const availableSizes: string[] = deriveSizes(productType, variants);

    const model: ProductTypeModel = {
      name: productType.name,
      availableSwatches,
      availableSizes,
      swatches,
      sizes,
      skuLookup,
    };

    return pipe(
      productTypeModel.decode(model),
      fold(
        reportTypeErrors({
          model: 'productType',
          id: productType.name,
          fallback: NO_PRODUCT_TYPE,
        }),
        returnValidModel
      )
    );
  } catch (e) {
    return NO_PRODUCT_TYPE;
  }
};
