import Head from 'next/head';
import { FC, memo } from 'react';

interface SiteHeadProps {
  faviconUrl: string | undefined;
}

export const SiteHead: FC<SiteHeadProps> = ({ faviconUrl }) => (
  <Head>
    <link rel="icon" type="image/x-icon" href={faviconUrl} />
    <link rel="shortcut icon" type="image/x-icon" href={faviconUrl} />
  </Head>
);

export default memo(SiteHead);
