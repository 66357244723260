import { commerceToolsAuth } from 'lib/commerceTools';
import {
  LoginRequest,
  ResetPasswordRequest,
  SetPasswordRequest,
} from 'store/auth/types';

export const loginRequest: LoginRequest = async ({ email, password }) => {
  const response = await commerceToolsAuth.customerPasswordFlow({
    username: email,
    password,
  });

  return {
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
    expiresAt: response.expires_at,
  };
};

export const resetPasswordRequest: ResetPasswordRequest = async ({
  email,
  locale,
}) => {
  const { status } = await fetch('/services/reset-password', {
    method: 'post',
    body: new URLSearchParams(
      Object.entries({
        email,
        locale,
      })
    ).toString(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  if (status < 200 || status >= 300) {
    throw new Error("We're sorry, we were unable to reset your password");
  }
};

export const setPasswordRequest: SetPasswordRequest = async ({
  password,
  token,
}) => {
  if (!token) {
    throw new Error('Missing identity token');
  }

  if (!password) {
    throw new Error("Please ensure that you've filled in your new password");
  }

  const { status } = await fetch('/services/set-password', {
    method: 'post',
    body: new URLSearchParams(
      Object.entries({
        password,
        token,
      })
    ).toString(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  if (status < 200 || status >= 300) {
    throw new Error("We're sorry, we were unable to change your password");
  }
};
