import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { ILink } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toLink from 'models/contentful/link/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  socialLinkModel,
  SocialLinkModel,
} from 'models/contentful/socialLink/types';

export const toSocialNetwork = (url: string | undefined): string => {
  if (!url) {
    return '';
  }

  if (/facebook/i.test(url)) {
    return 'facebook';
  }

  if (/instagram/i.test(url)) {
    return 'instagram';
  }

  if (/linkedin/i.test(url)) {
    return 'linkedin';
  }

  if (/pinterest/i.test(url)) {
    return 'pinterest';
  }

  if (/spotify/i.test(url)) {
    return 'spotify';
  }

  if (/tiktok/i.test(url)) {
    return 'tiktok';
  }

  if (/twitter/i.test(url)) {
    return 'twitter';
  }

  return '';
};

const toSocialLink: ContentModeller<ILink, SocialLinkModel> = ({
  content,
  locale,
}) => {
  const link = toLink({ content, locale });

  const model = {
    ...link,
    socialNetwork: toSocialNetwork(link?.link),
  } as SocialLinkModel;

  return pipe(
    socialLinkModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SocialLink',
        id: content.sys?.id ?? content.fields.link,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSocialLink;
