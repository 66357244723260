import { Errors } from 'io-ts';
import { formatValidationErrors } from 'io-ts-reporters';
import { sendReport } from 'lib/sendReport';

type ReportTypeErrors = <T>(args: {
  id?: string;
  model: string;
  fallback: T;
}) => (errors: Errors) => T;

export const reportTypeErrors: ReportTypeErrors =
  ({ id, model, fallback }) =>
  (errors: Errors) => {
    const report = formatValidationErrors(errors).map(
      error => error.split(' but instead ')[0]
    );

    sendReport(
      [
        `Data Issues: Errors found in ${model}${id ? ` with ID ${id}` : ''}:`,
        ...report,
        '',
      ].join('\n')
    );

    return fallback;
  };
