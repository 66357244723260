import type { TextInputProps } from 'components/Input/helpers';
import TextInputBorder from 'components/Input/InputPartials/TextInputBorder';
import styled, { css } from 'styled-components';

const TextInputField = styled.input<
  Pick<TextInputProps, 'border'> & { hasIcon?: boolean }
>(
  ({ border, hasIcon, theme: { colors } }) => css`
    font-family: 'GTAmerica', sans-serif;
    appearance: none;
    flex: 1;
    padding-left: ${border === 'stroke' && !hasIcon ? 0 : 16}px;
    padding-right: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-style: normal;
    transition: color 200ms;
    background-color: transparent;
    border: 0;
    color: ${colors.black};

    &:disabled {
      color: ${colors.grey6};
      cursor: not-allowed;
    }

    &:not(:disabled):hover ~ ${TextInputBorder} {
      border-color: ${colors.black};
    }

    &:focus {
      outline: 0;

      ~ ${TextInputBorder} {
        border-color: ${colors.black};
        box-shadow: ${border !== 'stroke'
          ? 'inset 0px 4px 4px rgba(0, 0, 0, 0.08);'
          : 'none'};
      }
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    ::-webkit-search-cancel-button {
      display: none;
    }
  `
);

export default TextInputField;
