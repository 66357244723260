import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IProductList } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  ProductListModel,
  productListModel,
} from 'models/contentful/productList/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toProductList: ContentModeller<IProductList, ProductListModel> = ({
  content,
}) => {
  const model = {
    listTitle: content.fields.listTitle,
    skus: content.fields.skus,
  } as ProductListModel;

  return pipe(
    productListModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'ProductList',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toProductList;
