import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle(
  ({ theme: { colors, bp } }) => css`
    html,
    body {
      overscroll-behavior-x: none;
    }

    #nprogress {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9999999999;

      .bar {
        background-color: ${colors.brandRed};
      }

      .peg {
        box-shadow: 0 0 10px ${colors.brandRed}, 0 0 5px ${colors.brandRed};
      }
    }

    [data-reactoverlays] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      pointer-events: none;
    }

    .disable-scrolling {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      margin: 0;
      padding: 0;
      height: 100vh;
    }

    .gtm-iframe {
      display: none;
      visibility: hidden;
    }

    .osano-cm-widget {
      display: none;
    }

    @font-face {
      font-family: 'GTAmerica';
      src: url('/fonts/GTAmerica-Light.eot');
      src: url('/fonts/GTAmerica-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GTAmerica-Light.woff') format('woff'),
        url('/fonts/GTAmerica-Light.ttf') format('truetype'),
        url('/fonts/GTAmerica-Light.svg#UbuntuItalic') format('svg');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'GTAmerica';
      src: url('/fonts/GTAmerica-Medium.eot');
      src: url('/fonts/GTAmerica-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GTAmerica-Medium.woff') format('woff'),
        url('/fonts/GTAmerica-Medium.ttf') format('truetype'),
        url('/fonts/GTAmerica-Medium.svg#UbuntuItalic') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'GTAmerica';
      src: url('/fonts/GTAmerica-Regular.eot');
      src: url('//fonts/GTAmerica-Regular.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/GTAmerica-Regular.woff') format('woff'),
        url('/fonts/GTAmerica-Regular.ttf') format('truetype'),
        url('/fonts/GTAmerica-Regular.svg#UbuntuItalic') format('svg');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'GTAmerica';
      src: url('/fonts/GTAmerica-Thin.eot');
      src: url('/fonts/GTAmerica-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/GTAmerica-Thin.woff') format('woff'),
        url('/fonts/GTAmerica-Thin.ttf') format('truetype'),
        url('/fonts/GTAmerica-Thin.svg#UbuntuItalic') format('svg');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
    }

    *,
    *:before,
    *:after {
      font-smooth: auto;
      -webkit-font-smoothing: auto;

      ${bp.m} {
        font-smooth: grayscale;
        -webkit-font-smoothing: antialiased;
      }
    }

    body {
      font-family: 'GTAmerica', sans-serif;
      font-size: 13px;
      background-color: ${colors.white};
      margin: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .Toastify__toast--error {
      background: transparent !important;
      box-shadow: none !important;
    }

    div[data-testid="om-overlays-backdrop"] a[href="https://www.perfectmoment.com/privacy-policy"]
    {
      color: ${colors.white};
      text-decoration: none;
    }

    div[data-testid="om-overlays-backdrop"] a[href="https://www.perfectmoment.com/privacy-policy"]:hover
    {
      text-decoration: underline;
    }

    .ls-detail-container,
    .ls-detail .ls-detail-image-box video,
    .ls-detail .ls-detail-image-box img {
      border-radius: 0px !important;
    }
    .dh-widget-container,
    .ls-detail .ls-detail-caption,
    .ls-detail .ls-detail-link-box > a p,
    .ls-detail .ls-detail-avatar a {
      color: black !important;
      font-family: 'GTAmerica', sans-serif !important;
      font-weight: 300;
      font-size: 16px !important;
      line-height: 22px !important;
    }

    .ls-btn-close,
    .ls-btn-pre-img,
    .ls-btn-next-img {
      filter: none;
      @media screen and (max-width: 849px) {
        filter: brightness(0);
      }
    }
  `
);

export default GlobalStyles;
