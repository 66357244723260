import { TextInputProps } from 'components/Input/helpers';
import TextInput from 'components/Input/TextInput';
import { FC } from 'react';

type EmailInputProps = Omit<TextInputProps, 'type' | 'spellCheck' | 'name'> &
  Partial<Pick<TextInputProps, 'name'>>;

const EmailInput: FC<EmailInputProps> = ({ name = 'email', ...props }) => (
  <TextInput {...props} name={name} type="email" spellCheck={false} />
);

export default EmailInput;
