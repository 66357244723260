import { ProductType } from '@commercetools/platform-sdk';
import { VariantModel } from 'models/variants/types';

export const deriveAvailableEnumAttributes =
  (name: keyof Pick<VariantModel, 'swatch' | 'size'>) =>
  (
    productType: ProductType,
    variants: Record<string, VariantModel> = {}
  ): string[] => {
    const attribute = productType.attributes?.find(attr => attr.name === name);

    if (!attribute || attribute.type.name !== 'lenum') {
      return [];
    }

    const variantValues = Object.values(variants);

    const keys: string[] = attribute.type.values.map(({ key }) => key);

    if (variantValues.length) {
      return keys.filter(key =>
        variantValues.some(variant => variant[name]?.key === key)
      );
    }

    return keys;
  };

export const deriveSizes = deriveAvailableEnumAttributes('size');
export const deriveSwatches = deriveAvailableEnumAttributes('swatch');
