import { ContentTypes } from 'lib/contentful/fetchResource';

const defaultLocale = 'en-default';

const deriveLocalisedContentItem = <T extends ContentTypes>(
  items: T[] = [],
  locale: string
): T | undefined => {
  if (items.length === 1) {
    return items[0];
  }

  const localisedItem = items.find(i => {
    if ('locale' in i.fields && Array.isArray(i.fields.locale)) {
      return (i.fields.locale as string[]).includes(locale);
    }

    return false;
  });

  if (localisedItem) {
    return localisedItem;
  }

  const unlocalisedItem = items.find(i => {
    if ('locale' in i.fields && Array.isArray(i.fields.locale)) {
      return (i.fields.locale as string[]).includes(defaultLocale);
    }

    return false;
  });

  if (unlocalisedItem) {
    return unlocalisedItem;
  }

  const itemWithEmptyLocaleField = items.find(i => {
    if (
      'locale' in i.fields &&
      Array.isArray(i.fields.locale) &&
      i.fields.locale.length === 0
    ) {
      return true;
    }

    if (!('locale' in i.fields)) {
      return true;
    }

    return false;
  });

  if (itemWithEmptyLocaleField) {
    return itemWithEmptyLocaleField;
  }

  return undefined;
};

export default deriveLocalisedContentItem;
