import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { replaceDownstreamState } from 'lib/immer';
import { mapCartError } from 'store/cart/helpers';
import {
  addDiscountCode,
  addExistingLineItems,
  addLineItem,
  changeLineItemQuantity,
  changeLineItemVariant,
  mergeWithCustomerCart,
  recalculate,
  removeDiscountCode,
  retrieveCart,
  retrieveOrCreateCart,
  setBillingAddress,
  setEmail,
  setFirstShippingMethodReceived,
  setShippingAddress,
  setShippingMethod,
} from 'store/cart/thunks';
import { CartState, DownstreamCartState } from 'store/cart/types';

export const initialState: CartState = {
  id: '',
  country: '',
  lineItemsAwaitingQuantityChanges: {},
  itemIds: [],
  items: {},
  products: {},
  version: 0,
  total: null,
  displayPrice: null,
  displayTax: null,
  taxInclusive: true,
  totalIncludingTax: null,
  email: '',
  customerId: '',
  discountCodes: {},
  cartDiscounts: {},
  previousTransactions: [],
  billingAddress: null,
  shippingAmountIncludingTax: 0,
  shippingTaxPercentage: 0,
  shippingAddress: null,
  shippingMethod: null,
  inProgress: {
    addDiscountCode: false,
    addLineItem: false,
    addExistingLineItems: false,
    changeLineItemQuantity: false,
    changeLineItemVariant: false,
    mergeWithCustomerCart: false,
    recalculate: false,
    removeDiscountCode: false,
    retrieveCart: false,
    retrieveOrCreateCart: false,
    setBillingAddress: false,
    setEmail: false,
    setShippingAddress: false,
    setShippingMethod: false,
  },
  errors: {
    addDiscountCode: '',
    addLineItem: '',
    addExistingLineItems: '',
    changeLineItemQuantity: '',
    changeLineItemVariant: '',
    mergeWithCustomerCart: '',
    recalculate: '',
    removeDiscountCode: '',
    retrieveCart: '',
    retrieveOrCreateCart: '',
    setBillingAddress: '',
    setEmail: '',
    setShippingAddress: '',
    setShippingMethod: '',
  },
  complete: {
    retrieveCart: false,
    retrieveOrCreateCart: false,
  },
};

const replaceState = replaceDownstreamState<DownstreamCartState, CartState>({
  omit: {
    lineItemsAwaitingQuantityChanges: true,
    inProgress: true,
    errors: true,
    complete: true,
  },
});

export const transformCouponErrorMessage = (message: string) => {
  if (message.includes('is valid only from')) {
    if (message.includes('until')) {
      return message.split('is valid only')[0] + 'has expired';
    } else {
      return message.split('is valid only')[0] + 'is not valid yet';
    }
  } else if (message.includes('is valid only until')) {
    return message.split('is valid only')[0] + 'has expired';
  }

  return message;
};

const { actions, reducer } = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setError(
      state,
      action: PayloadAction<{
        error: keyof CartState['errors'];
        message: string;
      }>
    ) {
      state.errors[action.payload.error] = action.payload.message;
    },
    resetCart() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(retrieveCart.pending, state => {
        state.inProgress.retrieveCart = true;
      })
      .addCase(retrieveCart.rejected, (state, action) => {
        state.errors.retrieveCart = action.error.message as string;
        state.inProgress.retrieveCart = false;
        state.complete.retrieveCart = true;
      })
      .addCase(retrieveCart.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.retrieveCart = '';
        state.inProgress.retrieveCart = false;

        if (action.payload) {
          state.complete.retrieveCart = true;
        }
      });

    builder
      .addCase(retrieveOrCreateCart.pending, state => {
        state.inProgress.retrieveOrCreateCart = true;
      })
      .addCase(retrieveOrCreateCart.rejected, (state, action) => {
        state.errors.retrieveOrCreateCart = action.error.message as string;
        state.inProgress.retrieveOrCreateCart = false;
        state.complete.retrieveOrCreateCart = true;
      })
      .addCase(retrieveOrCreateCart.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.retrieveOrCreateCart = '';
        state.inProgress.retrieveOrCreateCart = false;
        state.complete.retrieveOrCreateCart = true;
      });

    builder
      .addCase(recalculate.pending, state => {
        state.inProgress.recalculate = true;
      })
      .addCase(recalculate.rejected, (state, action) => {
        state.errors.recalculate = action.error.message as string;
        state.inProgress.recalculate = false;
      })
      .addCase(recalculate.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.recalculate = '';
        state.inProgress.recalculate = false;
      });

    builder
      .addCase(addLineItem.pending, state => {
        state.inProgress.addLineItem = true;
      })
      .addCase(addLineItem.rejected, (state, action) => {
        state.errors.addLineItem = action.error.message as string;
        state.inProgress.addLineItem = false;
      })
      .addCase(addLineItem.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.addLineItem = '';
        state.inProgress.addLineItem = false;
      });

    builder
      .addCase(changeLineItemQuantity.pending, (state, action) => {
        state.inProgress.changeLineItemQuantity = true;
        state.lineItemsAwaitingQuantityChanges[action.meta.arg.lineItemId] =
          true;
      })
      .addCase(changeLineItemQuantity.rejected, (state, action) => {
        state.errors.changeLineItemQuantity = action.error.message as string;
        state.inProgress.changeLineItemQuantity = false;
        delete state.lineItemsAwaitingQuantityChanges[
          action.meta.arg.lineItemId
        ];
      })
      .addCase(changeLineItemQuantity.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.changeLineItemQuantity = '';
        state.inProgress.changeLineItemQuantity = false;
        delete state.lineItemsAwaitingQuantityChanges[
          action.meta.arg.lineItemId
        ];
      });

    builder
      .addCase(changeLineItemVariant.pending, (state, action) => {
        state.inProgress.changeLineItemVariant = true;
        state.lineItemsAwaitingQuantityChanges[action.meta.arg.lineItemId] =
          true;
      })
      .addCase(changeLineItemVariant.rejected, (state, action) => {
        state.errors.changeLineItemVariant = action.error.message as string;
        state.inProgress.changeLineItemVariant = false;
        delete state.lineItemsAwaitingQuantityChanges[
          action.meta.arg.lineItemId
        ];
      })
      .addCase(changeLineItemVariant.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.changeLineItemVariant = '';
        state.inProgress.changeLineItemVariant = false;
        delete state.lineItemsAwaitingQuantityChanges[
          action.meta.arg.lineItemId
        ];
      });

    builder
      .addCase(addDiscountCode.pending, state => {
        state.inProgress.addDiscountCode = true;
      })
      .addCase(addDiscountCode.rejected, (state, action) => {
        state.errors.addDiscountCode = transformCouponErrorMessage(
          action.error.message as string
        );
        state.inProgress.addDiscountCode = false;
      })
      .addCase(addDiscountCode.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.addDiscountCode = '';
        state.inProgress.addDiscountCode = false;
      });

    builder
      .addCase(removeDiscountCode.pending, state => {
        state.inProgress.removeDiscountCode = true;
      })
      .addCase(removeDiscountCode.rejected, (state, action) => {
        state.errors.removeDiscountCode = action.error.message as string;
        state.inProgress.removeDiscountCode = false;
      })
      .addCase(removeDiscountCode.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.removeDiscountCode = '';

        if (action.meta?.arg?.message)
          state.errors.addDiscountCode = action.meta.arg.message as string;

        state.inProgress.removeDiscountCode = false;
      });

    builder
      .addCase(mergeWithCustomerCart.pending, state => {
        state.inProgress.mergeWithCustomerCart = true;
      })
      .addCase(mergeWithCustomerCart.rejected, (state, action) => {
        state.errors.mergeWithCustomerCart = action.error.message as string;
        state.inProgress.mergeWithCustomerCart = false;
      })
      .addCase(mergeWithCustomerCart.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.mergeWithCustomerCart = '';
        state.inProgress.mergeWithCustomerCart = false;
      });

    builder
      .addCase(setShippingAddress.pending, state => {
        state.inProgress.setShippingAddress = true;
      })
      .addCase(setShippingAddress.rejected, (state, action) => {
        state.errors.setShippingAddress = mapCartError(
          state.errors.setShippingAddress,
          action.error.message as string
        );
        state.inProgress.setShippingAddress = false;
      })
      .addCase(setShippingAddress.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.setShippingAddress = '';
        state.inProgress.setShippingAddress = false;
      });

    builder
      .addCase(setBillingAddress.pending, state => {
        state.inProgress.setBillingAddress = true;
      })
      .addCase(setBillingAddress.rejected, (state, action) => {
        state.errors.setBillingAddress = action.error.message as string;
        state.inProgress.setBillingAddress = false;
      })
      .addCase(setBillingAddress.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.setBillingAddress = '';
        state.inProgress.setBillingAddress = false;
      });

    builder
      .addCase(setShippingMethod.pending, state => {
        state.inProgress.setShippingMethod = true;
      })
      .addCase(setShippingMethod.rejected, (state, action) => {
        state.errors.setShippingMethod = action.error.message as string;
        state.inProgress.setShippingMethod = false;
      })
      .addCase(setShippingMethod.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.setShippingMethod = '';
        state.inProgress.setShippingMethod = false;
      });

    builder
      .addCase(setFirstShippingMethodReceived.pending, state => {
        state.inProgress.setShippingMethod = true;
      })
      .addCase(setFirstShippingMethodReceived.rejected, (state, action) => {
        state.errors.setShippingMethod = action.error.message as string;
        state.inProgress.setShippingMethod = false;
      })
      .addCase(setFirstShippingMethodReceived.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.setShippingMethod = '';
        state.inProgress.setShippingMethod = false;
      });

    builder
      .addCase(setEmail.pending, state => {
        state.inProgress.setEmail = true;
        state.errors.setEmail = '';
      })
      .addCase(setEmail.rejected, (state, action) => {
        state.errors.setEmail = action.error.message as string;
        state.inProgress.setEmail = false;
      })
      .addCase(setEmail.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.setEmail = '';
        state.inProgress.setEmail = false;
      });

    builder
      .addCase(addExistingLineItems.pending, state => {
        state.inProgress.addExistingLineItems = true;
      })
      .addCase(addExistingLineItems.rejected, (state, action) => {
        state.errors.addExistingLineItems = action.error.message as string;
        state.inProgress.addExistingLineItems = false;
      })
      .addCase(addExistingLineItems.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.addExistingLineItems = '';
        state.inProgress.addExistingLineItems = false;
      });
  },
});

export const { setError, resetCart } = actions;

export default reducer;
