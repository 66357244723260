import {
  ILink,
  INavigationLevel2,
  INavigationLevel3,
  INavigationLink,
} from 'generated/contentful';
import toLink from 'models/contentful/link/serializers';
import { LinkModel } from 'models/contentful/link/types';
import { CONTENTFUL_URI, IMGIX_URI } from 'models/contentful/media/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import toStorySource from 'models/contentful/storySource/serializers';
import {
  DesktopNavigationLevel2Model,
  DesktopNavigationLevel3Model,
  MobileNavigationLevel2Model,
  MobileNavigationLevel3Model,
  NavigationLinkModel,
} from 'models/navigation/types';

const toNavigationLink: ContentModeller<
  INavigationLink,
  NavigationLinkModel
> = ({ content, parent, locale }) => {
  const { label, slug } = content.fields;

  return {
    id: content.sys.id,
    path: parent as string[],
    link: toLink({
      content: { fields: { title: label, link: slug } } as ILink,
      locale,
    }) as LinkModel,
  };
};

export const toMobileNavigationLevel2: ContentModeller<
  INavigationLevel2,
  MobileNavigationLevel2Model
> = ({ content, locale }) => {
  const { label, slug, image, navItems } = content.fields;

  const model = {
    id: content.sys.id,
    level: 2,
    image: image
      ? {
          title: image.fields.title,
          url: image.fields.file.url.replace(CONTENTFUL_URI, IMGIX_URI),
          height: image.fields.file.details.image?.height,
          width: image.fields.file.details.image?.width,
        }
      : null,
    path: [],
    link: toLink({
      content: { fields: { title: label, link: slug } } as ILink,
      locale,
    }),
    primary: navItems
      ? navItems.map(item => {
          return toMobileNavigationLevel3({
            content: item,
            parent: label,
            locale,
          });
        })
      : [],
  } as MobileNavigationLevel2Model;

  return model;
};

const toMobileNavigationLevel3: ContentModeller<
  INavigationLevel3,
  MobileNavigationLevel3Model
> = ({ content, parent, locale }) => {
  const { image, label, slug, navItems } = content.fields;

  const model = {
    id: content.sys.id,
    level: 3,
    image: image
      ? {
          title: image.fields.title,
          url: image.fields.file.url.replace(CONTENTFUL_URI, IMGIX_URI),
          height: image.fields.file.details.image?.height,
          width: image.fields.file.details.image?.width,
        }
      : null,
    path: [parent],
    link: toLink({
      content: { fields: { title: label, link: slug } } as ILink,
      locale,
    }) as LinkModel,
    primary: navItems
      ? navItems.map(item =>
          toNavigationLink({ content: item, parent: [parent, label], locale })
        )
      : [],
  } as MobileNavigationLevel3Model;

  return model;
};

export const toDesktopNavigationLevel2: ContentModeller<
  INavigationLevel2,
  DesktopNavigationLevel2Model
> = ({ content, locale }) => {
  const {
    label,
    slug,
    primaryDesktopColumnOne,
    primaryDesktopColumnTwo,
    primaryDesktopColumnThree,
    desktopStory,
    desktopStoryColumnWidth,
  } = content.fields;

  const model = {
    id: content.sys.id,
    link: toLink({
      content: { fields: { title: label, link: slug } } as ILink,
      locale,
    }) as LinkModel,
    path: [],
    primaryDesktopColumnOne: (primaryDesktopColumnOne ?? []).map(item =>
      toDesktopNavigationLevel3({ content: item, parent: label, locale })
    ),
    primaryDesktopColumnTwo: (primaryDesktopColumnTwo ?? []).map(item =>
      toDesktopNavigationLevel3({ content: item, parent: label, locale })
    ),
    primaryDesktopColumnThree: (primaryDesktopColumnThree ?? []).map(item =>
      toDesktopNavigationLevel3({ content: item, parent: label, locale })
    ),
    desktopStory: desktopStory
      ? toStorySource({ content: desktopStory, locale })
      : null,
    desktopStoryColumnWidth: desktopStoryColumnWidth === '3' ? 3 : 2,
  } as DesktopNavigationLevel2Model;

  return model;
};

const toDesktopNavigationLevel3: ContentModeller<
  INavigationLevel3,
  DesktopNavigationLevel3Model
> = ({ content, parent, locale }) => {
  const { label, slug, navItems } = content.fields;

  const model = {
    id: content.sys.id,
    link: toLink({
      content: { fields: { title: label, link: slug } } as ILink,
      locale,
    }) as LinkModel,
    path: [parent],
    primary: navItems
      ? navItems.map(item =>
          toNavigationLink({ content: item, parent: [parent, label], locale })
        )
      : [],
  } as DesktopNavigationLevel3Model;

  return model;
};
