import * as t from 'io-ts';
import { headerModel } from 'models/contentful/header/types';
import { mediaModel } from 'models/contentful/media/types';

const textColorModel = t.keyof({
  black: null,
  white: null,
});

const horizontalPositionModel = t.keyof({
  left: null,
  center: null,
  right: null,
});
const verticalPositionModel = t.keyof({
  start: null,
  center: null,
  end: null,
});

const responsiveTextColorModel = t.exact(
  t.interface({
    xs: textColorModel,
    s: t.union([textColorModel, t.null]),
    m: t.union([textColorModel, t.null]),
    l: t.union([textColorModel, t.null]),
    xl: t.union([textColorModel, t.null]),
    xxl: t.union([textColorModel, t.null]),
  }),
  'StorySource Responsive Text Color'
);

const responsiveHorizontalPositionModel = t.exact(
  t.interface({
    xs: t.union([horizontalPositionModel, t.null]),
    s: t.union([horizontalPositionModel, t.null]),
    m: t.union([horizontalPositionModel, t.null]),
    l: t.union([horizontalPositionModel, t.null]),
    xl: t.union([horizontalPositionModel, t.null]),
    xxl: t.union([horizontalPositionModel, t.null]),
  }),
  'StorySource Responsive Horizontal Position'
);

export const responsiveVerticalPositionModel = t.exact(
  t.interface({
    xs: t.union([verticalPositionModel, t.null]),
    s: t.union([verticalPositionModel, t.null]),
    m: t.union([verticalPositionModel, t.null]),
    l: t.union([verticalPositionModel, t.null]),
    xl: t.union([verticalPositionModel, t.null]),
    xxl: t.union([verticalPositionModel, t.null]),
  }),
  'StorySource Responsive Vertical Position'
);

export const storySourceModel = t.intersection([
  t.interface({
    media: mediaModel,
    header: headerModel,
    link: t.string,
    textColor: t.union([responsiveTextColorModel, textColorModel, t.null]),
    horizontalPosition: t.union([responsiveHorizontalPositionModel, t.null]),
    verticalPosition: t.union([responsiveVerticalPositionModel, t.null]),
    blockType: t.literal('StorySource'),
  }),
  t.partial({
    linkLabel: t.union([t.string, t.undefined]),
  }),
]);

export type ResponsiveHorizontalPositionModel = t.TypeOf<
  typeof responsiveHorizontalPositionModel
>;
export type ResponsiveVerticalPositionModel = t.TypeOf<
  typeof responsiveVerticalPositionModel
>;
export type StorySourceModel = t.TypeOf<typeof storySourceModel>;
