import { AnyAction } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { Dispatch, State } from 'store/types';

export type UseLazyDispatch = <
  TAction extends Parameters<Dispatch>[0] | AnyAction,
  TEvent extends Pick<Event, 'preventDefault'>
>() => (action: TAction, isDefaultPrevented?: boolean) => (e?: TEvent) => void;

export const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<Dispatch>();

export const useLazyDispatch: UseLazyDispatch =
  () =>
  (action, isDefaultPrevented = true) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useReduxDispatch<Dispatch>();
    return e => {
      if (isDefaultPrevented) {
        e?.preventDefault?.();
      }
      dispatch(action as Parameters<Dispatch>[0]);
    };
  };
