import type { BreakpointKey } from 'lib/toResponsiveValue';

type Breakpoints<T> = T[] & {
  s: T;
  m: T;
  l: T;
  xl: T;
  xxl: T;
};

const numericBreakpoints = [414, 768, 1024, 1440, 1920] as Breakpoints<number>;

numericBreakpoints.s = numericBreakpoints[0];
numericBreakpoints.m = numericBreakpoints[1];
numericBreakpoints.l = numericBreakpoints[2];
numericBreakpoints.xl = numericBreakpoints[3];
numericBreakpoints.xxl = numericBreakpoints[4];

const breakpoints = numericBreakpoints.map(
  breakpoint => breakpoint + 'px'
) as Breakpoints<string>;

breakpoints.s = breakpoints[0];
breakpoints.m = breakpoints[1];
breakpoints.l = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export const bp: Record<BreakpointKey, string> = {
  xs: `@media screen and (max-width: ${numericBreakpoints[0] - 1}px)`,
  s: `@media screen and (min-width: ${numericBreakpoints[0]}px)`,
  m: `@media screen and (min-width: ${numericBreakpoints[1]}px)`,
  l: `@media screen and (min-width: ${numericBreakpoints[2]}px)`,
  xl: `@media screen and (min-width: ${numericBreakpoints[3]}px)`,
  xxl: `@media screen and (min-width: ${numericBreakpoints[4]}px)`,
};

export const bplt: Record<BreakpointKey, string> = {
  xs: `@media screen and (max-width: ${numericBreakpoints[0] - 1}px)`,
  s: `@media screen and (max-width: ${numericBreakpoints[0] - 1}px)`,
  m: `@media screen and (max-width: ${numericBreakpoints[1] - 1}px)`,
  l: `@media screen and (max-width: ${numericBreakpoints[2] - 1}px)`,
  xl: `@media screen and (max-width: ${numericBreakpoints[3] - 1}px)`,
  xxl: `@media screen and (max-width: ${numericBreakpoints[4] - 1}px)`,
};

export const bpRange: Record<BreakpointKey, string> = {
  xs: `(max-width: ${numericBreakpoints[0] - 1}px)`,
  s: `(min-width: ${numericBreakpoints[0]}px) and (max-width: ${
    numericBreakpoints[1] - 1
  }px)`,
  m: `(min-width: ${numericBreakpoints[1]}px) and (max-width: ${
    numericBreakpoints[2] - 1
  }px)`,
  l: `(min-width: ${numericBreakpoints[2]}px) and (max-width: ${
    numericBreakpoints[3] - 1
  }px)`,
  xl: `(min-width: ${numericBreakpoints[3]}px) and (max-width: ${
    numericBreakpoints[4] - 1
  }px)`,
  xxl: `(min-width: ${numericBreakpoints[4]}px)`,
};

export const bpSrcSet: Omit<Record<BreakpointKey, number>, 'xxl'> = {
  xs: numericBreakpoints[0],
  s: numericBreakpoints[1],
  m: numericBreakpoints[2],
  l: numericBreakpoints[3],
  xl: numericBreakpoints[4],
};

export { numericBreakpoints };

export default breakpoints;
