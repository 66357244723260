import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ILink } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { LinkModel, linkModel } from 'models/contentful/link/types';
import { ContentModeller } from 'models/contentful/shared/types';

export const generateLocalizedUrl = (
  url: string | undefined,
  locale: string
): string => {
  if (typeof url !== 'string') {
    return '';
  }

  if (url.startsWith('mailto:')) {
    return url;
  }

  if (/^https?:\/\/(.)+$/i.test(url)) {
    return url;
  }

  if (/^[a-z0-9-]+(\/[a-z0-9-]+)*/i.test(url)) {
    return `/${locale}/${url}`;
  }

  if (url === '/') {
    return `/${locale}`;
  }

  if (/^\/[a-z0-9-]+(\/[a-z0-9-]+)*/i.test(url)) {
    return `/${locale}${url}`;
  }

  return url;
};

const toLink: ContentModeller<ILink, LinkModel> = ({ content, locale }) => {
  const model: LinkModel = {
    title: content.fields.title,
    link: generateLocalizedUrl(content.fields.link, locale),
  };

  return pipe(
    linkModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Link',
        // Patch for navigation serializer issue
        id: content.sys?.id ?? content.fields.link,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toLink;
