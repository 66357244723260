import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { INavigationTop } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toLink from 'models/contentful/link/serializers';
import { LinkModel } from 'models/contentful/link/types';
import { CONTENTFUL_URI, IMGIX_URI } from 'models/contentful/media/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  DesktopNavigationLevel2Model,
  desktopNavigationModel,
  DesktopNavigationModel,
  MobileNavigationLevel2Model,
  mobileNavigationModel,
  MobileNavigationModel,
} from 'models/navigation/types';
import {
  toDesktopNavigationLevel2,
  toMobileNavigationLevel2,
} from 'models/navigation/utilities';

export const toDesktopNavigation: ContentModeller<
  INavigationTop,
  DesktopNavigationModel
> = ({ content, locale }) => {
  const navigation: DesktopNavigationModel = {
    primary: Array.isArray(content.fields.navItems)
      ? (content.fields.navItems.map(item =>
          toDesktopNavigationLevel2({ content: item, locale })
        ) as DesktopNavigationLevel2Model[])
      : [],
    image: content.fields.image
      ? {
          title: content.fields.image.fields.title,
          url: content.fields.image.fields.file.url.replace(
            CONTENTFUL_URI,
            IMGIX_URI
          ),
          width: content.fields.image.fields.file.details.image
            ?.width as number,
          height: content.fields.image.fields.file.details.image
            ?.height as number,
        }
      : null,
  };

  return pipe(
    desktopNavigationModel.decode(navigation),
    fold(
      reportTypeErrors({
        model: 'navigation',
        id: 'navigation',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const toMobileNavigation: ContentModeller<
  INavigationTop,
  MobileNavigationModel
> = ({ content, locale }) => {
  const navigation: MobileNavigationModel = {
    level: 1,
    primary: (content.fields.mobilePrimary ?? []).map(item =>
      toMobileNavigationLevel2({ content: item, locale })
    ) as MobileNavigationLevel2Model[],
    secondaryMobile: (content.fields.mobileSecondary ?? [])
      .map(item => toLink({ content: item, locale }))
      .filter((link): link is LinkModel => Boolean(link)),
    image: content.fields.image
      ? {
          title: content.fields.image.fields.title,
          url: content.fields.image.fields.file.url.replace(
            CONTENTFUL_URI,
            IMGIX_URI
          ),
          width: content.fields.image.fields.file.details.image
            ?.width as number,
          height: content.fields.image.fields.file.details.image
            ?.height as number,
        }
      : null,
  };

  return pipe(
    mobileNavigationModel.decode(navigation),
    fold(
      reportTypeErrors({
        model: 'Mobile Navigation',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};
