import styled, { css } from 'styled-components';

const BooleanInputField = styled.input(
  ({ theme: { colors } }) => css`
    display: block;
    position: absolute;
    opacity: 0;
    left: -9999em;
    outline: 0;
    margin: 0;
    padding: 0;
    appearance: none;
    cursor: pointer;

    &:disabled {
      ~ [data-boolean-input-button] {
        background-color: ${colors.grey1};
        border-color: ${colors.grey1};
        cursor: not-allowed;
      }
    }

    &:disabled:checked {
      ~ [data-boolean-input-button] {
        background-color: ${colors.grey1};
        border-color: ${colors.grey1};

        &:after {
          background-color: ${colors.grey6};
          transform: scale(1);
        }
      }
    }

    &:not(:disabled):hover {
      ~ [data-boolean-input-button] {
        border-color: ${colors.black};
      }
    }

    &:not(:disabled):focus {
      ~ [data-boolean-input-button] {
        border-color: ${colors.black};
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.14);
      }
    }

    &:not(:disabled):checked {
      ~ [data-boolean-input-button] {
        border-color: ${colors.black};
        background-color: ${colors.black};

        &:after {
          background-color: ${colors.white};
          transform: scale(1);
        }
      }
    }
  `
);

export default BooleanInputField;
