import dynamic from 'next/dynamic';

export const Bag = dynamic(() => import('components/Icons/System/Bag'), {
  ssr: false,
});

export const BagCount = dynamic(
  () => import('components/Icons/System/BagCount'),
  { ssr: false }
);

export const Chevron = dynamic(
  () => import('components/Icons/System/Chevron'),
  { ssr: false }
);

export const ChevronSmall = dynamic(
  () => import('components/Icons/System/ChevronSmall'),
  { ssr: false }
);

export const Cross = dynamic(() => import('components/Icons/System/Cross'), {
  ssr: false,
});

export const CrossCircle = dynamic(
  () => import('components/Icons/System/CrossCircle'),
  { ssr: false }
);

export const CrossSmall = dynamic(
  () => import('components/Icons/System/CrossSmall'),
  { ssr: false }
);

export const DeliveryVan = dynamic(
  () => import('components/Icons/System/DeliveryVan'),
  { ssr: false }
);

export const Eye = dynamic(() => import('components/Icons/System/Eye'), {
  ssr: false,
});

export const EyeOff = dynamic(() => import('components/Icons/System/EyeOff'), {
  ssr: false,
});

export const File = dynamic(() => import('components/Icons/System/File'), {
  ssr: false,
});

export const Hamburger = dynamic(
  () => import('components/Icons/System/Hamburger'),
  { ssr: false }
);

export const Heart = dynamic(() => import('components/Icons/System/Heart'), {
  ssr: false,
});

export const HeartSmall = dynamic(
  () => import('components/Icons/System/HeartSmall'),
  { ssr: false }
);

export const Minus = dynamic(() => import('components/Icons/System/Minus'), {
  ssr: false,
});

export const MinusSmall = dynamic(
  () => import('components/Icons/System/MinusSmall'),
  { ssr: false }
);

export const Pause = dynamic(() => import('components/Icons/System/Pause'), {
  ssr: false,
});

export const Play = dynamic(() => import('components/Icons/System/Play'), {
  ssr: false,
});

export const Plus = dynamic(() => import('components/Icons/System/Plus'), {
  ssr: false,
});

export const PlusSmall = dynamic(
  () => import('components/Icons/System/PlusSmall'),
  { ssr: false }
);

export const Question = dynamic(
  () => import('components/Icons/System/Question'),
  { ssr: false }
);

export const QuestionFailure = dynamic(
  () => import('components/Icons/System/QuestionFailure'),
  { ssr: false }
);

export const QuestionFailureSmall = dynamic(
  () => import('components/Icons/System/QuestionFailureSmall'),
  { ssr: false }
);

export const QuestionSmall = dynamic(
  () => import('components/Icons/System/QuestionSmall'),
  { ssr: false }
);

export const Search = dynamic(() => import('components/Icons/System/Search'), {
  ssr: false,
});

export const Spinner = dynamic(
  () => import('components/Icons/System/Spinner'),
  { ssr: false }
);

export const Tick = dynamic(() => import('components/Icons/System/Tick'), {
  ssr: false,
});

export const TickSmall = dynamic(
  () => import('components/Icons/System/TickSmall'),
  { ssr: false }
);

export const Warning = dynamic(
  () => import('components/Icons/System/Warning'),
  { ssr: false }
);

export const WarningFailure = dynamic(
  () => import('components/Icons/System/WarningFailure'),
  { ssr: false }
);

export const WarningFailureSmall = dynamic(
  () => import('components/Icons/System/WarningFailureSmall'),
  { ssr: false }
);

export const WarningSmall = dynamic(
  () => import('components/Icons/System/WarningSmall'),
  { ssr: false }
);

export const Zoom = dynamic(() => import('components/Icons/System/Zoom'), {
  ssr: false,
});
