import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import {
  IOverlayTextStyleFields,
  IStorySource,
  IStorySourceFields,
} from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toHeader from 'models/contentful/header/serializers';
import { generateLocalizedUrl } from 'models/contentful/link/serializers';
import toMedia from 'models/contentful/media/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  StorySourceModel,
  storySourceModel,
} from 'models/contentful/storySource/types';

type StorySourceFieldsSubset = Pick<
  IStorySourceFields,
  | 'textColor'
  | 'overlayStyleXs'
  | 'overlayStyleS'
  | 'overlayStyleM'
  | 'overlayStyleL'
  | 'overlayStyleXl'
  | 'overlayStyleXxl'
>;

const getContentPositions = (
  verticalPosition: IOverlayTextStyleFields['verticalPosition'] | undefined
) => {
  if (!verticalPosition) return null;
  const contentPositions = {
    top: 'start',
    middle: 'center',
    bottom: 'end',
  };

  return contentPositions[verticalPosition];
};

const toOverlayStyles = ({
  textColor,
  overlayStyleXs: xs,
  overlayStyleS: s,
  overlayStyleM: m,
  overlayStyleL: l,
  overlayStyleXl: xl,
  overlayStyleXxl: xxl,
}: StorySourceFieldsSubset) => ({
  textColor: {
    xs: xs?.fields.textColor ?? textColor ?? 'black',
    s: s?.fields.textColor ?? null,
    m: m?.fields.textColor ?? null,
    l: l?.fields.textColor ?? null,
    xl: xl?.fields.textColor ?? null,
    xxl: xxl?.fields.textColor ?? null,
  },
  horizontalPosition: {
    xs: xs?.fields.horizontalPosition ?? 'center',
    s: s?.fields.horizontalPosition ?? null,
    m: m?.fields.horizontalPosition ?? null,
    l: l?.fields.horizontalPosition ?? null,
    xl: xl?.fields.horizontalPosition ?? null,
    xxl: xxl?.fields.horizontalPosition ?? null,
  },
  verticalPosition: {
    xs: getContentPositions(xs?.fields.verticalPosition) ?? 'center',
    s: getContentPositions(s?.fields.verticalPosition),
    m: getContentPositions(m?.fields.verticalPosition),
    l: getContentPositions(l?.fields.verticalPosition),
    xl: getContentPositions(xl?.fields.verticalPosition),
    xxl: getContentPositions(xxl?.fields.verticalPosition),
  },
});

const toStorySource: ContentModeller<IStorySource, StorySourceModel> = ({
  content,
  locale,
}) => {
  const {
    header,
    link,
    linkLabel,
    media,
    textColor,
    overlayStyleXs,
    overlayStyleS,
    overlayStyleM,
    overlayStyleL,
    overlayStyleXl,
    overlayStyleXxl,
  } = content.fields;
  const model = {
    header: toHeader({ content: header, locale }),
    link: generateLocalizedUrl(link, locale),
    linkLabel: linkLabel || '',
    media: toMedia({ content: media, locale }),
    blockType: 'StorySource',
    ...toOverlayStyles({
      textColor,
      overlayStyleXs,
      overlayStyleS,
      overlayStyleM,
      overlayStyleL,
      overlayStyleXl,
      overlayStyleXxl,
    }),
  } as StorySourceModel;

  return pipe(
    storySourceModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'StorySource',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toStorySource;
