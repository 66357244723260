import * as t from 'io-ts';
import { priceModel } from 'models/variants/types';

export const shippingMethodModel = t.interface({
  id: t.string,
  name: t.string,
  price: priceModel,
});

export type ShippingMethodModel = t.TypeOf<typeof shippingMethodModel>;
