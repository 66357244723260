import toResponsiveValue, {
  BreakpointKey,
  ResponsiveValue,
} from 'lib/toResponsiveValue';
import { bpSrcSet } from 'theme/breakpoints';

export const isProduction = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export const generateResponsiveImageUrl = (
  url: string,
  options?: { aspectRatio?: number; quality?: number }
): string => {
  const baseUrl = `${url}?auto=format`;

  if (options?.aspectRatio) {
    return `${baseUrl}&ar=${options?.aspectRatio}:1`;
  }

  return baseUrl;
};

export const generateResponsiveImageSrc = (
  url: string,
  options?: {
    isSet?: boolean;
    aspectRatio?: number;
    spans?: number | ResponsiveValue<number>;
  }
): string => {
  const spans: Record<BreakpointKey, number> = options?.spans
    ? toResponsiveValue(options.spans)
    : toResponsiveValue(1);

  const srcSet: string[] = [];

  let bp: keyof typeof bpSrcSet;

  const baseUrl = `${generateResponsiveImageUrl(url, options)}&fit=fill`;

  if (options?.isSet) {
    for (bp in bpSrcSet) {
      srcSet.push(
        `${baseUrl}&max-w=${bpSrcSet[bp] / spans[bp]} ${bpSrcSet[bp]}w`
      );
    }

    return srcSet.join(', ');
  }
  return `${baseUrl}&max-w=${bpSrcSet.l / spans.l}`;
};

export const generateProductImageAltText = (
  productName: string,
  index = 0,
  label: string | null = null
): string =>
  [productName, index, label]
    .filter(text => typeof text === 'string' || typeof text === 'number')
    .join(' ')
    .trim();

export const generateContentfulImageAltText = (filename: string): string =>
  filename.split('/').slice(-1)[0].split('.')[0].split('_').join(' ');

export const toKeyedObject = <T, K extends keyof T>(
  array: T[],
  id: K
): Record<string, T> =>
  array.reduce<Record<string, T>>((acc, curr) => {
    const key = curr[id] + '';
    acc[key] = curr;
    return acc;
  }, {});

export const unique = <T extends string | number | symbol>(array: T[]): T[] => {
  const intermediate = {} as Record<T, T>;

  for (const item of array) {
    intermediate[item] = item;
  }

  return Object.values(intermediate);
};

export const exclude = <T extends string | number | symbol>(
  base: T[],
  compare: T[]
): T[] => {
  const intermediate = {} as Record<T, T>;

  for (const item of base) {
    intermediate[item] = item;
  }

  for (const item of compare) {
    delete intermediate[item];
  }

  return Object.values(intermediate);
};

export const uniqueBy = <T extends Record<string, unknown>, K extends keyof T>(
  array: T[],
  key: K
): T[] => {
  const intermediate = {} as Record<string | number, true>;
  const returnArray: T[] = [];

  for (const item of array) {
    const intermediateKey =
      typeof item[key] === 'number' ? (item[key] as number) : `${item[key]}`;

    if (!intermediate[intermediateKey]) {
      intermediate[intermediateKey] = true;

      returnArray.push(item);
    }
  }

  return returnArray;
};

export const getDate = (): Date => new Date();

export const returnTrue = () => true;

export const noop = () => undefined;

export const getOrigin = () => [window?.location.origin].join('');

export const generateFastPseudoRandomId = (
  prefix: string,
  length: number
): string =>
  prefix +
  '-' +
  (Math.random().toString(36) + '00000000000000000').slice(2, length + 2);

export const callAll =
  (...fns: ((...args: never[]) => unknown)[]) =>
  () => {
    for (const fn of fns) {
      fn();
    }
  };

export const removeUndefinedTopLevelKeys = <T extends Record<string, unknown>>(
  obj: T
): T => {
  const copy = { ...obj };

  for (const key in copy) {
    if (copy[key] === undefined) {
      delete copy[key];
    }
  }

  return copy;
};

export const formatAsCurrency = (
  currencyCode?: string,
  amount?: number | string
) => {
  if (!currencyCode && !amount) return '';
  if (!currencyCode) return amount;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  const parts = currencyFormatter.formatToParts(0);
  const symbol = parts.find(part => part.type === 'currency')?.value || '';

  return amount !== undefined ? `${symbol}${amount}` : symbol;
};

export const getCountryIsoFromLocale = (locale: string): string | null => {
  const parts = locale.split('-');
  if (parts.length === 2) {
    return parts[1].toUpperCase();
  } else return null;
};
