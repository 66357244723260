import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { createSelector } from '@reduxjs/toolkit';
import { getCountrySelectOptions } from 'lib/locale/countries';
import { getApiLocale } from 'lib/locale/locale';
import { ApiLocale } from 'lib/locale/types';
import { toAdyenResponse } from 'models/payments/serializers';
import {
  AdyenResponseModel,
  GetPaymentMethodsRequestModel,
  GetPaymentMethodsResponseModel,
  MakePaymentRequestLineItem,
  MakePaymentRequestModel,
  MakePaymentResponseAdditionalActionModel,
  MakePaymentResponseModel,
  PaymentMethodType,
  PaymentModel,
  SubmitAdditionalPaymentDetailsResponseModel,
  failedPaymentResponseModel,
  makePaymentResponseAdditionalActionModel,
  paymentAuthorisedModel,
  paymentMethodType,
} from 'models/payments/types';
import { RegionsModel } from 'models/regions/types';
import {
  selectAdyenAntiFraudData,
  selectCart,
  selectCartAddressType,
  selectCartTotalCentAmount,
} from 'store/cart/selectors';
import { CartState } from 'store/cart/types';
import { CheckoutState, CheckoutStep } from 'store/checkout/types';
import { selectCustomer } from 'store/customer/selectors';
import { State } from 'store/types';

const MYRIAD = 10000;

const ENABLED_DEVELOPMENT_PAYMENT_METHODS = Object.keys(
  paymentMethodType.keys
) as PaymentMethodType[];

const ENABLED_PRODUCTION_PAYMENT_METHODS =
  ENABLED_DEVELOPMENT_PAYMENT_METHODS.slice();

export const generateHash = <T>(hashable: T): string =>
  JSON.stringify(hashable);

export const isPaymentAuthorised = <T>(paymentResponse: T): boolean =>
  paymentAuthorisedModel.is(paymentResponse);

export const selectLocale = (
  _state: State,
  props: { locale: string }
): string => props.locale;

export const selectEnvironment = (
  _state: State,
  props: { environment?: string }
) => [props?.environment ?? process.env.NEXT_PUBLIC_ENVIRONMENT].join('');

export const selectCheckout = (state: State): CheckoutState => state.checkout;

export const selectApiLocale = createSelector(
  selectLocale,
  (locale): ApiLocale => getApiLocale(locale)
);

export const selectLatestAllowedStep = createSelector(
  selectCart,
  selectCustomer,
  (cart, customer): CheckoutStep => {
    if (!cart.id) {
      return 'none';
    }

    const shippingAddressStep = customer.addressIds.length
      ? 'shippingAddressSelector'
      : 'shippingAddressForm';

    const billingAddressStep = customer.addressIds.length
      ? 'billingAddressSelector'
      : 'billingAddressForm';

    const hasBillingAddress = cart.billingAddress !== null;
    const hasShippingAddress = cart.shippingAddress !== null;
    const hasShippingMethod = cart.shippingMethod !== null;

    if (hasShippingAddress && hasShippingMethod && hasBillingAddress) {
      return 'payment';
    }

    if (hasShippingAddress && hasShippingMethod) {
      return billingAddressStep;
    }

    if (hasShippingAddress) {
      return 'shippingMethodSelector';
    }

    return shippingAddressStep;
  }
);

export const selectCancelledFormStep = createSelector(
  selectCustomer,
  selectCheckout,
  selectCart,
  selectLatestAllowedStep,
  selectCartAddressType,
  (
    customer,
    checkout,
    cart,
    latestAllowedStep,
    addressType
  ): CheckoutStep | undefined => {
    if (addressType === 'billing' && checkout.step === 'billingAddressForm') {
      if (customer.addressIds.length > 0) {
        return 'billingAddressSelector';
      }

      if (cart.billingAddress !== null) {
        return latestAllowedStep;
      }
    }

    if (addressType === 'shipping' && checkout.step === 'shippingAddressForm') {
      if (customer.addressIds.length > 0) {
        return 'shippingAddressSelector';
      }

      if (cart.shippingAddress !== null) {
        return latestAllowedStep;
      }
    }
  }
);

export const selectRegions = createSelector(
  selectCheckout,
  selectCartAddressType,
  (checkout, addressType): RegionsModel => {
    if (addressType === 'billing') {
      return checkout.billingRegions;
    }

    if (addressType === 'shipping') {
      return checkout.shippingRegions;
    }

    return [];
  }
);

export const selectRetrievingRegions = createSelector(
  selectCheckout,
  selectCartAddressType,
  (checkout, addressType): boolean => {
    if (addressType === 'billing') {
      return checkout.inProgress.billingRegions;
    }

    if (addressType === 'shipping') {
      return checkout.inProgress.shippingRegions;
    }

    return false;
  }
);

export const selectAllowedPaymentMethods = createSelector(
  selectApiLocale,
  selectEnvironment,
  ({ paymentMethods }, environment): PaymentMethodType[] => {
    const enabledMethods =
      environment === 'production'
        ? ENABLED_PRODUCTION_PAYMENT_METHODS
        : ENABLED_DEVELOPMENT_PAYMENT_METHODS;

    return enabledMethods.filter(method => paymentMethods[method] === true);
  }
);

export const selectPayment = createSelector(
  selectCheckout,
  (checkout): PaymentModel | null => checkout.payment
);

export const selectPaymentId = createSelector(
  selectPayment,
  (payment): string | null => payment?.id ?? null
);

export const selectPaymentMethodsRequest = createSelector(
  selectPayment,
  (payment): GetPaymentMethodsRequestModel | null =>
    payment?.custom.fields.getPaymentMethodsRequest ?? null
);

export const selectPaymentMethodsResponse = createSelector(
  selectPayment,
  (payment): GetPaymentMethodsResponseModel | null =>
    payment?.custom.fields.getPaymentMethodsResponse ?? null
);

export const selectAdyenOptions = createSelector(
  selectPaymentMethodsRequest,
  selectPaymentMethodsResponse,
  selectAllowedPaymentMethods,
  selectApiLocale,
  (
    paymentMethodsRequest,
    paymentMethodsResponse,
    allowPaymentMethods,
    { country }
  ): CoreOptions | null => {
    if (!paymentMethodsRequest || !paymentMethodsResponse) {
      return null;
    }

    return {
      showPayButton: true,
      clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
      environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
      paymentMethodsResponse,
      allowPaymentMethods,
      translations: {
        'en-US': {
          payButton: 'Complete order',
        },
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
        paypal: {
          style: {
            color: 'blue',
          },
        },
        applepay: {
          showPayButton: true,
          amount: {
            value: paymentMethodsRequest.amount.value,
            currency: paymentMethodsRequest.amount.currency,
          },
          countryCode: country,
          totalPriceLabel: 'Perfect Moment',
        },
      },
    };
  }
);

export const selectAdyenOptionsHash = createSelector(
  selectAdyenOptions,
  generateHash
);

export const selectMakePaymentResponse = createSelector(
  selectPayment,
  (payment): MakePaymentResponseModel | null =>
    payment?.custom.fields.makePaymentResponse ?? null
);

export const selectAdditionalAction = createSelector(
  selectMakePaymentResponse,
  (
    makePaymentResponse
  ): MakePaymentResponseAdditionalActionModel['action'] | null => {
    if (makePaymentResponseAdditionalActionModel.is(makePaymentResponse)) {
      return makePaymentResponse.action;
    }

    return null;
  }
);

export const selectAdditionalActionHash = createSelector(
  selectAdditionalAction,
  generateHash
);

export const selectSubmitAdditionalPaymentDetailsResponse = createSelector(
  selectPayment,
  (payment): SubmitAdditionalPaymentDetailsResponseModel | null =>
    payment?.custom.fields.submitAdditionalPaymentDetailsResponse ?? null
);

export const selectSubmitPaymentAuthorised = createSelector(
  selectMakePaymentResponse,
  isPaymentAuthorised
);

export const selectSubmitAdditionalPaymentDetailsAuthorised = createSelector(
  selectSubmitAdditionalPaymentDetailsResponse,
  isPaymentAuthorised
);

export const selectSubmitAdditionalPaymentDetailsFailed = createSelector(
  selectCheckout,
  selectSubmitAdditionalPaymentDetailsResponse,
  (checkout, submitAdditionalPaymentDetailsResponse): boolean =>
    failedPaymentResponseModel.is(submitAdditionalPaymentDetailsResponse) ||
    checkout.errors.sendAdditionalAdyenPaymentDetails !== ''
);

export const selectDropInPaymentAuthorised = createSelector(
  selectSubmitPaymentAuthorised,
  selectSubmitAdditionalPaymentDetailsAuthorised,
  (paymentAuthorised, additionalPaymentAuthorised): boolean =>
    paymentAuthorised || additionalPaymentAuthorised
);

export const selectCartTotalMatchesPlannedPaymentAmount = createSelector(
  selectCartTotalCentAmount,
  selectPayment,
  (cartTotal, payment): boolean =>
    cartTotal === payment?.amountPlanned.centAmount
);

export const selectPaymentLineItems = createSelector(
  selectCart,
  (cart: CartState): MakePaymentRequestLineItem[] =>
    Object.values(cart.items)
      .map<MakePaymentRequestLineItem>(item => ({
        amountIncludingTax: item.amountIncludingTax,
        description: item.name,
        id: item.variantSku,
        imageUrl: item.image,
        quantity: item.quantity,
        taxPercentage: item.taxPercentage * MYRIAD,
      }))
      .concat({
        amountIncludingTax: cart.shippingAmountIncludingTax,
        description: cart.shippingMethod?.name as string,
        id: cart.shippingMethod?.id as string,
        quantity: 1,
        taxPercentage: cart.shippingTaxPercentage * MYRIAD,
      })
);

export const selectAdyenResponse = (
  _state: State,
  props: {
    adyenResponse: { data: Record<string, unknown>; isValid?: boolean };
  }
): AdyenResponseModel | null =>
  toAdyenResponse({ adyenResponse: props.adyenResponse });

export const selectAdyenOrigin = createSelector(
  selectEnvironment,
  (environment): string => {
    if (environment === 'production') {
      return 'https://www.perfectmoment.com';
    }

    const w = global.window as Window | undefined;

    return [w?.location.origin].join('');
  }
);

export const selectMakePaymentRequest = createSelector(
  selectCart,
  selectAdyenOrigin,
  selectPaymentId,
  selectPaymentMethodsRequest,
  selectAdyenAntiFraudData,
  selectPaymentLineItems,
  selectAdyenResponse,
  selectLocale,
  selectApiLocale,
  (
    cart,
    origin,
    paymentId,
    paymentMethodsRequest,
    antiFraudData,
    lineItems,
    adyenResponse,
    locale,
    { country, language }
  ): MakePaymentRequestModel | null => {
    if (!paymentId || !paymentMethodsRequest || !adyenResponse?.data) {
      return null;
    }

    const { amount } = paymentMethodsRequest;

    const req = { ...adyenResponse.data.paymentMethod };

    if (req.type === 'paypal' && req.subtype === 'sdk') {
      delete req.subtype;
    }

    const request: MakePaymentRequestModel = {
      amount,
      reference: paymentId,
      browserInfo: adyenResponse.data.browserInfo as Record<string, unknown>,
      paymentMethod: req,
      additionalData: { allow3DS2: true },
      channel: 'Web',
      origin,
      returnUrl: [origin, locale, 'payment-pending', cart.id, paymentId].join(
        '/'
      ),
      merchantAccount: 'PerfectMomentLTDECOM',
      countryCode: country,
      shopperLocale: [language, country].join('_'),
      lineItems,
      ...antiFraudData,
    };

    return request;
  }
);

export const selectCountrySelectOptions = createSelector(
  selectCheckout,
  selectCart,
  (
    checkout: CheckoutState,
    cart: CartState
  ): { label: string; value: string }[] => {
    if (checkout.step === 'shippingAddressForm') {
      return getCountrySelectOptions(cart.country);
    }

    return getCountrySelectOptions();
  }
);
