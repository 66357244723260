import { INavigationTop } from 'generated/contentful';
import deriveLocalisedContentItem from 'lib/contentful/deriveLocalisedContentItem';
import fetchResource from 'lib/contentful/fetchResource';
import { sendReport } from 'lib/sendReport';
import {
  toDesktopNavigation,
  toMobileNavigation,
} from 'models/navigation/serializers';
import {
  DesktopNavigationModel,
  MobileNavigationModel,
} from 'models/navigation/types';

const fetchNav = async (
  locale: string,
  w: Window | undefined
): Promise<{
  mobileNavigation: MobileNavigationModel | null;
  desktopNavigation: DesktopNavigationModel | null;
}> => {
  if (typeof w !== 'undefined') {
    return {
      mobileNavigation: null,
      desktopNavigation: null,
    };
  }

  try {
    const navMenus = await fetchResource<INavigationTop>({
      type: 'navigationTop',
      limit: 2,
      query: {
        locale,
      },
    });

    const nav = deriveLocalisedContentItem(navMenus, locale);

    if (!nav?.fields) {
      throw new Error(`Navigation not found for ${locale}`);
    }

    return {
      mobileNavigation: toMobileNavigation({
        content: nav,
        locale,
      }),
      desktopNavigation: toDesktopNavigation({
        content: nav,
        locale,
      }),
    };
  } catch (error) {
    sendReport(error);

    return {
      mobileNavigation: null,
      desktopNavigation: null,
    };
  }
};

export default fetchNav;
