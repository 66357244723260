export const sendReport = (error: unknown, optionalParams?: unknown): void => {
  if (optionalParams) {
    global.console?.error(error, optionalParams);
  } else {
    global.console?.error(error);
  }
};

export const sendWarningReport = (
  error: unknown,
  optionalParams?: unknown
): void => {
  if (optionalParams) {
    global.console?.warn(error, optionalParams);
  } else {
    global.console?.warn(error);
  }
};
