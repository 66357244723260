import { ContentPageModel } from 'models/contentPages/types';
import Head from 'next/head';
import { FC } from 'react';

type MetaHeadProps = Partial<
  Pick<
    ContentPageModel,
    'browserTitle' | 'metaTitle' | 'metaDescription' | 'metaImage'
  >
>;

const MetaHead: FC<MetaHeadProps> = ({
  browserTitle,
  metaTitle,
  metaDescription,
  metaImage,
}) => (
  <Head>
    {browserTitle ? <title key="browserTitle">{browserTitle}</title> : null}

    {metaTitle ? (
      <meta key="metaTitle" name="title" content={metaTitle} />
    ) : null}

    {metaTitle || browserTitle ? (
      <meta
        key="ogTitle"
        property="og:title"
        content={metaTitle || browserTitle}
      />
    ) : null}

    {metaDescription ? (
      <>
        <meta
          key="metaDescription"
          name="description"
          content={metaDescription}
        />
        <meta
          key="ogDescription"
          property="og:description"
          content={metaDescription}
        />
      </>
    ) : null}

    {metaImage?.url ? (
      <meta key="ogImage" property="og:image" content={metaImage?.url} />
    ) : null}

    <meta name="p:domain_verify" content="405580c4175e397c693e4628964b40ff" />
  </Head>
);

export default MetaHead;
