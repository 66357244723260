import { createAsyncThunk } from '@reduxjs/toolkit';
import * as t from 'io-ts';
import { PaymentModel } from 'models/payments/types';
import { RegionsModel } from 'models/regions/types';
import { ShippingMethodModel } from 'models/shippingMethods/types';
import { selectCartTotalCentAmount } from 'store/cart';
import { recalculate, retrieveCart } from 'store/cart/thunks';
import {
  createAdyenPaymentRequest,
  retrieveShippingMethodsRequest,
  sendAdditionalAdyenPaymentDetailsRequest,
  sendAdyenPaymentRequest,
} from 'store/checkout/api';
import {
  selectCartTotalMatchesPlannedPaymentAmount,
  selectMakePaymentRequest,
} from 'store/checkout/selectors';
import type {
  CheckoutLocale,
  RetrieveRegionsRequest,
  SendAdditionalPaymentDetails,
  SendAdditionalPaymentDetailsAfterRedirect,
  SendPayment,
} from 'store/checkout/types';
import { retrieveCustomer } from 'store/customer/thunks';
import type { ThunkApi } from 'store/types';

const regionsModel = t.array(
  t.interface({ name: t.string, shortCode: t.string })
);

export const retrieveShippingMethods = createAsyncThunk<
  ShippingMethodModel[],
  void,
  ThunkApi
>('checkout/retrieveShippingMethods', async (_, { getState }) => {
  const { auth, cart } = getState();

  if (cart.shippingAddress === null) {
    return [];
  }

  return await retrieveShippingMethodsRequest({
    cartId: cart.id,
    token: auth.accessToken,
    currency: cart.total?.currencyCode,
  });
});

export const retrieveRegions = createAsyncThunk<
  RegionsModel | undefined,
  RetrieveRegionsRequest,
  ThunkApi
>('checkout/retrieveRegions', async ({ countryCode }) => {
  if (!countryCode) {
    return [];
  }

  const response = await fetch(`/services/regions/${countryCode}`);
  const json = await response.json();

  const regions = regionsModel.is(json) ? json : [];

  return regions;
});

export const createAdyenPayment = createAsyncThunk<
  PaymentModel | string | null,
  CheckoutLocale,
  ThunkApi
>('checkout/createAdyenPayment', async ({ locale }, { dispatch, getState }) => {
  await dispatch(recalculate(locale));
  await dispatch(retrieveCustomer());

  const state = getState();

  if (!state.cart.total) {
    return null;
  }

  const centAmount = selectCartTotalCentAmount(state);

  return await createAdyenPaymentRequest({
    locale,
    cartId: state.cart.id,
    customerEmail: state.cart.email,
    customerId: state.cart.customerId,
    cartVersion: state.cart.version,
    token: state.auth.accessToken,
    currencyCode: state.cart.total.currencyCode,
    centAmount: centAmount,
  });
});

export const sendAdyenPayment = createAsyncThunk<
  PaymentModel | null,
  SendPayment,
  ThunkApi
>(
  'checkout/sendAdyenPayment',
  async ({ adyenResponse, locale }, { dispatch, getState }) => {
    await dispatch(recalculate(locale));
    await dispatch(retrieveCustomer());

    const state = getState();

    if (selectCartTotalMatchesPlannedPaymentAmount(state) === false) {
      throw new Error(
        "Your bag's price changed before we could take payment. Please try again."
      );
    }

    const payload = selectMakePaymentRequest(state, {
      locale,
      adyenResponse,
    });

    if (!state.checkout.payment || !payload) {
      return null;
    }

    return await sendAdyenPaymentRequest({
      payload,
      paymentId: state.checkout.payment.id,
      version: state.checkout.payment.version,
      token: state.auth.accessToken,
    });
  }
);

export const sendAdditionalAdyenPaymentDetails = createAsyncThunk<
  PaymentModel | null,
  SendAdditionalPaymentDetails,
  ThunkApi
>(
  'checkout/sendAdditionalAdyenPaymentDetails',
  async ({ additionalPaymentDetails, locale }, { dispatch, getState }) => {
    await dispatch(retrieveCart(locale));
    await dispatch(retrieveCustomer());

    const state = getState();

    if (!state.checkout.payment || !additionalPaymentDetails) {
      return null;
    }

    return await sendAdditionalAdyenPaymentDetailsRequest({
      payload: additionalPaymentDetails,
      paymentId: state.checkout.payment.id,
      token: state.auth.accessToken,
    });
  }
);

export const sendAdditionalAdyenPaymentDetailsAfterRedirect = createAsyncThunk<
  PaymentModel | null,
  SendAdditionalPaymentDetailsAfterRedirect,
  ThunkApi
>(
  'checkout/sendAdditionalAdyenPaymentDetailsAfterRedirect',
  async ({ locale, redirectResult, paymentId }, { dispatch, getState }) => {
    await dispatch(retrieveCart(locale));
    await dispatch(retrieveCustomer());

    const state = getState();

    return await sendAdditionalAdyenPaymentDetailsRequest({
      payload: {
        details: { redirectResult: decodeURIComponent(redirectResult) },
      },
      token: state.auth.accessToken,
      paymentId,
    });
  }
);
