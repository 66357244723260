import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  generateAnonymousId,
  login,
  resetPassword,
  setPassword,
} from 'store/auth/thunks';
import { AuthState } from 'store/auth/types';

export const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  anonymousId: '',
  expiresAt: -1,
  complete: {
    changePassword: false,
  },
  inProgress: {
    login: false,
    changePassword: false,
  },
  errors: {
    login: '',
    changePassword: '',
  },
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    commitAuthCredentials(state, action: PayloadAction<Partial<AuthState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetAuth() {
      return initialState;
    },
    clearChangedPasswordState(state) {
      state.errors.changePassword = '';
      state.complete.changePassword = false;
      state.inProgress.changePassword = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(generateAnonymousId.fulfilled, (state, action) => {
      state.anonymousId = action.payload;
    });

    builder
      .addCase(login.pending, state => {
        state.errors.login = '';
        state.inProgress.login = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.errors.login = action.error.message as string;
        state.inProgress.login = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.expiresAt = action.payload.expiresAt;
        state.anonymousId = '';
        state.errors.login = '';
        state.inProgress.login = false;
      });

    builder
      .addCase(resetPassword.pending, state => {
        state.errors.changePassword = '';
        state.inProgress.changePassword = true;
        state.complete.changePassword = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.errors.changePassword = action.error.message as string;
        state.inProgress.changePassword = false;
        state.complete.changePassword = false;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.inProgress.changePassword = false;
        state.errors.changePassword = '';
        state.complete.changePassword = true;
      });

    builder
      .addCase(setPassword.pending, state => {
        state.errors.changePassword = '';
        state.inProgress.changePassword = true;
        state.complete.changePassword = false;
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.errors.changePassword = action.error.message as string;
        state.inProgress.changePassword = false;
        state.complete.changePassword = false;
      })
      .addCase(setPassword.fulfilled, state => {
        state.errors.changePassword = '';
        state.inProgress.changePassword = false;
        state.complete.changePassword = true;
      });
  },
});

export const { commitAuthCredentials, resetAuth, clearChangedPasswordState } =
  actions;

export default reducer;
