import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createAdyenPayment,
  retrieveRegions,
  retrieveShippingMethods,
  sendAdditionalAdyenPaymentDetails,
  sendAdditionalAdyenPaymentDetailsAfterRedirect,
  sendAdyenPayment,
} from 'store/checkout/thunks';
import { CheckoutState, CheckoutStep } from 'store/checkout/types';

export const initialState: CheckoutState = {
  step: 'none',
  shippingMethods: [],
  billingRegions: [],
  shippingRegions: [],
  payment: null,
  inProgress: {
    shippingMethods: false,
    billingRegions: false,
    shippingRegions: false,
    createPayment: false,
    sendPayment: false,
    sendAdditionalAdyenPaymentDetails: false,
  },
  errors: {
    shippingMethods: '',
    billingRegions: '',
    shippingRegions: '',
    createPayment: '',
    sendPayment: '',
    sendAdditionalAdyenPaymentDetails: '',
  },
};

const { reducer, actions } = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<CheckoutStep>) {
      state.step = action.payload;
    },
    resetCheckout() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(retrieveShippingMethods.pending, state => {
        state.errors.shippingMethods = initialState.errors.shippingMethods;
        state.inProgress.shippingMethods = true;
      })
      .addCase(retrieveShippingMethods.rejected, (state, action) => {
        if (action.error.message) {
          state.errors.shippingMethods = action.error.message;
        }
        state.inProgress.shippingMethods =
          initialState.inProgress.shippingMethods;
      })
      .addCase(retrieveShippingMethods.fulfilled, (state, action) => {
        state.shippingMethods = action.payload;
        state.errors.shippingMethods = initialState.errors.shippingMethods;
        state.inProgress.shippingMethods =
          initialState.inProgress.shippingMethods;
      });

    builder
      .addCase(retrieveRegions.pending, (state, action) => {
        switch (action.meta.arg.addressType) {
          case 'billing':
            state.billingRegions = initialState.billingRegions;
            state.errors.billingRegions = initialState.errors.billingRegions;
            state.inProgress.billingRegions = true;
            break;
          case 'shipping':
            state.billingRegions = initialState.shippingRegions;
            state.errors.billingRegions = initialState.errors.shippingRegions;
            state.inProgress.shippingRegions = true;
            break;
        }
      })
      .addCase(retrieveRegions.rejected, (state, action) => {
        switch (action.meta.arg.addressType) {
          case 'billing':
            if (action.error.message) {
              state.errors.billingRegions = action.error.message;
            }
            state.inProgress.billingRegions = false;
            break;
          case 'shipping':
            if (action.error.message) {
              state.errors.shippingRegions = action.error.message;
            }
            state.inProgress.shippingRegions = false;
            break;
        }
      })
      .addCase(retrieveRegions.fulfilled, (state, action) => {
        switch (action.meta.arg.addressType) {
          case 'billing':
            if (action.payload) {
              state.billingRegions = action.payload;
            }
            state.errors.billingRegions = initialState.errors.billingRegions;
            state.inProgress.billingRegions = false;
            break;
          case 'shipping':
            if (action.payload) {
              state.shippingRegions = action.payload;
            }
            state.errors.shippingRegions = initialState.errors.shippingRegions;
            state.inProgress.shippingRegions = false;
            break;
        }
      });

    builder
      .addCase(createAdyenPayment.pending, state => {
        state.payment = initialState.payment;
        state.errors.createPayment = initialState.errors.createPayment;
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.createPayment = true;
      })
      .addCase(createAdyenPayment.rejected, (state, action) => {
        if (action.error.message) {
          state.errors.createPayment = action.error.message;
        }
        state.inProgress.createPayment = false;
      })
      .addCase(createAdyenPayment.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.payment = action.payload;
        }
        state.errors.createPayment = initialState.errors.createPayment;
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.createPayment = false;
      });

    builder
      .addCase(sendAdyenPayment.pending, state => {
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.sendPayment = true;
      })
      .addCase(sendAdyenPayment.rejected, (state, action) => {
        if (action.error.message) {
          state.errors.sendPayment = action.error.message;
        }
        state.inProgress.sendPayment = false;
      })
      .addCase(sendAdyenPayment.fulfilled, (state, action) => {
        if (action.payload) {
          state.payment = action.payload;
        }
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.sendPayment = false;
      });

    builder
      .addCase(sendAdditionalAdyenPaymentDetails.pending, state => {
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.sendPayment = true;
      })
      .addCase(sendAdditionalAdyenPaymentDetails.rejected, (state, action) => {
        if (action.error.message) {
          state.errors.sendPayment = action.error.message;
        }
        state.inProgress.sendPayment = false;
      })
      .addCase(sendAdditionalAdyenPaymentDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.payment = action.payload;
        }
        state.errors.sendPayment = initialState.errors.sendPayment;
        state.inProgress.sendPayment = false;
      });

    builder
      .addCase(
        sendAdditionalAdyenPaymentDetailsAfterRedirect.pending,
        state => {
          state.errors.sendAdditionalAdyenPaymentDetails =
            initialState.errors.sendAdditionalAdyenPaymentDetails;
          state.inProgress.sendAdditionalAdyenPaymentDetails = true;
        }
      )
      .addCase(
        sendAdditionalAdyenPaymentDetailsAfterRedirect.rejected,
        (state, action) => {
          if (action.error.message) {
            state.errors.sendAdditionalAdyenPaymentDetails =
              action.error.message;
          }
          state.inProgress.sendAdditionalAdyenPaymentDetails = false;
        }
      )
      .addCase(
        sendAdditionalAdyenPaymentDetailsAfterRedirect.fulfilled,
        (state, action) => {
          if (action.payload) {
            state.payment = action.payload;
          }
          state.errors.sendAdditionalAdyenPaymentDetails =
            initialState.errors.sendAdditionalAdyenPaymentDetails;
          state.inProgress.sendAdditionalAdyenPaymentDetails = false;
        }
      );
  },
});

export const { setStep, resetCheckout } = actions;
export default reducer;
