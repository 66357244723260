import {
  Color,
  TypographySize,
  TypographyVariant,
} from 'components/Typography/types';
import toResponsiveValue, {
  OptionallyResponsiveValue,
} from 'lib/toResponsiveValue';
import styled, { CSSObject, css } from 'styled-components';

interface LongformProps {
  variant: OptionallyResponsiveValue<Extract<TypographyVariant, '1'>>;
  size: OptionallyResponsiveValue<Exclude<TypographySize, 'XL'>>;
  textColor?: OptionallyResponsiveValue<Color>;
}

const sizes: Record<
  Exclude<TypographySize, 'XL'>,
  Pick<CSSObject, 'fontSize' | 'lineHeight'>
> = {
  '2XS': {
    fontSize: 14,
    lineHeight: 21,
  },
  XS: {
    fontSize: 16,
    lineHeight: 24,
  },
  S: {
    fontSize: 20,
    lineHeight: 30,
  },
  M: {
    fontSize: 24,
    lineHeight: 36,
  },
  L: {
    fontSize: 32,
    lineHeight: 48,
  },
};

export const Longform = styled.span<LongformProps>(
  ({ size: s, textColor: c = 'black', theme: { bp, colors } }) => {
    const size = toResponsiveValue(s);
    const color = toResponsiveValue(c);

    return css`
      margin: 0;
      font-weight: 400;
      font-feature-settings: 'ss03';
      font-size: ${sizes[size.xs].fontSize}px;
      line-height: ${sizes[size.xs].lineHeight}px;
      color: ${colors[color.xs]};

      ${bp.s} {
        font-size: ${sizes[size.s].fontSize}px;
        line-height: ${sizes[size.s].lineHeight}px;
        color: ${colors[color.s]};
      }

      ${bp.m} {
        font-size: ${sizes[size.m].fontSize}px;
        line-height: ${sizes[size.m].lineHeight}px;
        color: ${colors[color.m]};
      }

      ${bp.l} {
        font-size: ${sizes[size.l].fontSize}px;
        line-height: ${sizes[size.l].lineHeight}px;
        color: ${colors[color.l]};
      }

      ${bp.xl} {
        font-size: ${sizes[size.xl].fontSize}px;
        line-height: ${sizes[size.xl].lineHeight}px;
        color: ${colors[color.xl]};
      }

      ${bp.xxl} {
        font-size: ${sizes[size.xxl].fontSize}px;
        line-height: ${sizes[size.xxl].lineHeight}px;
        color: ${colors[color.xxl]};
      }

      > a[href] {
        color: inherit;
      }
    `;
  }
);
