import styled from 'styled-components';

const BooleanInputContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
`;

export default BooleanInputContainer;
