import { DefaultTheme } from 'styled-components';
import { bp, bplt, bpRange, bpSrcSet } from 'theme/breakpoints';

export const defaultTheme: DefaultTheme = {
  bp,
  bplt,
  bpRange,
  bpSrcSet,
  colors: {
    white: '#FFFFFF',
    grey0: '#F9F9FA',
    grey1: '#F2F3F4',
    grey2: '#ECECEF',
    grey3: '#E7E8EB',
    grey4: '#DFE0E4',
    grey5: '#BEBEC2',
    grey6: '#707072',
    grey7: '#2D2D2E',
    black: '#000000',
    brandRed: '#E72D2A',
    brandGrey: '#D8D7DF',
    success: '#19943B',
    warning: '#FFC300',
    failure: '#B60218',
    legacyGrey: '#323232',
    pink: '#f7e5e7',
  },
};
