import { Spinner, TickSmall } from 'components/Icons';
import {
  ButtonHTMLAttributes,
  ReactNode,
  createElement,
  forwardRef,
} from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: keyof HTMLElementTagNameMap;
  status?: ButtonStatus;
  variant?: keyof typeof buttonVariantMap;
}

export enum ButtonStatus {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
}

const TRANSITION = 125;

const BaseButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;

  &:focus {
    border: none;
  }
`;

const DefaultButton = styled(BaseButton)(
  ({ theme }) => css`
    font-weight: 400;
    width: 100%;
    border-radius: 10px;
    padding: 16px 24px;
    text-align: center;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    transition: background-color ${TRANSITION}ms, border-color ${TRANSITION}ms,
      box-shadow ${TRANSITION}ms, color ${TRANSITION}ms;

    &:hover {
      background-color: ${theme.colors.grey3};
      color: ${theme.colors.grey7};
      border-color: transparent;
    }

    &:focus {
      border-color: ${theme.colors.grey4};
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }

    &:active {
      color: ${theme.colors.white};
      background-color: ${theme.colors.grey6};
      border-color: ${theme.colors.grey6};
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  `
);

const Primary = styled(DefaultButton)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.grey7};
    border-color: ${theme.colors.grey7};

    &:disabled:hover {
      opacity: 0.5;
      cursor: default;
      color: ${theme.colors.white};
      background-color: ${theme.colors.grey7};
      border-color: ${theme.colors.grey7};
    }
  `
);

const Secondary = styled(DefaultButton)(
  ({ theme }) => css`
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.black};

    &:disabled:hover {
      opacity: 0.5;
      cursor: default;
    }

    &:hover {
      background-color: ${theme.colors.grey3};
      color: ${theme.colors.grey7};
      border-color: transparent;
    }
  `
);

const Selector = styled(BaseButton)(
  ({ theme }) => css`
    background-color: transparent;
    border-radius: 0;
    color: grey7;
    width: 100%;
    padding: 8px 0;
    margin: 16px;

    ${theme.bp.xs} {
      margin: 0;
    }
  `
);

const ButtonLink = styled(BaseButton)(
  ({ theme }) => css`
    background-color: transparent;
    color: ${theme.colors.black};
    padding: 0;
  `
);

const InlineInputButton = styled(BaseButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.grey0};
    color: ${theme.colors.black};
    border-radius: 10px;
    margin: 1px;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    &:focus {
      box-shadow: inset 0 0 0px 1px ${theme.colors.grey7},
        0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  `
);

const SelectedRadioInput = styled(Secondary)(
  ({ theme }) => css`
    display: flex;
    align-items: stretch;
    flex-direction: row;
    border-radius: 5px;
    white-space: wrap;
    user-select: none;
    padding: 24px;

    &:focus {
      border-color: ${theme.colors.black};
    }
  `
);

const RadioInput = styled(SelectedRadioInput)(
  ({ theme }) => css`
    border-color: ${theme.colors.grey2};
  `
);

const DisabledRadioInput = styled(SelectedRadioInput)(
  ({ theme }) => css`
    border-color: ${theme.colors.grey2};
    color: ${theme.colors.grey5};

    &:hover {
      border-color: ${theme.colors.grey2};
      background-color: ${theme.colors.white};
      color: ${theme.colors.grey5};
    }
  `
);

const NewRadioInput = styled(BaseButton)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 32px;
    border-radius: 5px;
    white-space: nowrap;
    user-select: none;
    appearance: none;
    background-color: ${theme.colors.white};
    padding: 8px;
    box-shadow: inset 0 0 0 1px ${theme.colors.grey2};
    transition: box-shadow 175ms, color 175ms;
    color: ${theme.colors.black};
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 0 1px ${theme.colors.grey6};
    }

    &:focus {
      box-shadow: inset 0 0 0 1px ${theme.colors.grey6};
    }
  `
);

const NewSelectedRadioInput = styled(NewRadioInput)(
  ({ theme }) => css`
    cursor: default;
    pointer-events: none;
    box-shadow: inset 0 0 0 2px ${theme.colors.black};

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  `
);

const NewDisabledRadioInput = styled(NewSelectedRadioInput)(
  ({ theme }) => css`
    box-shadow: inset 0 0 0 1px ${theme.colors.grey2};
    color: ${theme.colors.grey5};
  `
);

export const buttonVariantMap = {
  default: DefaultButton,
  primary: Primary,
  secondary: Secondary,
  selector: Selector,
  buttonLink: ButtonLink,
  inlineInputButton: InlineInputButton,
  selectedRadioInput: SelectedRadioInput,
  radioInput: RadioInput,
  disabledRadioInput: DisabledRadioInput,
  newRadioInput: NewRadioInput,
  newSelectedRadioInput: NewSelectedRadioInput,
  newDisabledRadioInput: NewDisabledRadioInput,
} as const;

const getContent = (children: ReactNode, status: ButtonStatus) => {
  switch (status) {
    case ButtonStatus.LOADING:
      return <Spinner strokeColor="black" />;
    case ButtonStatus.COMPLETE:
      return <TickSmall strokeColor="white" />;
    default:
      return children;
  }
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, status = ButtonStatus.DEFAULT, variant = 'default', ...props },
    ref
  ) =>
    createElement(
      buttonVariantMap[variant],
      { ...props, ref },
      getContent(children, status)
    )
);

export const loadingToButtonStatus = (loading?: boolean) =>
  loading ? ButtonStatus.LOADING : ButtonStatus.DEFAULT;

export default Button;
