import dynamic from 'next/dynamic';

export const Facebook = dynamic(
  () => import('components/Icons/Social/Facebook'),
  { ssr: false }
);

export const Instagram = dynamic(
  () => import('components/Icons/Social/Instagram'),
  { ssr: false }
);

export const LinkedIn = dynamic(
  () => import('components/Icons/Social/LinkedIn'),
  { ssr: false }
);

export const Pinterest = dynamic(
  () => import('components/Icons/Social/Pinterest'),
  { ssr: false }
);

export const Spotify = dynamic(
  () => import('components/Icons/Social/Spotify'),
  { ssr: false }
);

export const TikTok = dynamic(() => import('components/Icons/Social/TikTok'), {
  ssr: false,
});

export const Twitter = dynamic(
  () => import('components/Icons/Social/Twitter'),
  { ssr: false }
);
