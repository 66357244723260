import type { SelectProps } from 'components/Input/helpers';
import TextInputBorder from 'components/Input/InputPartials/TextInputBorder';
import styled, { css } from 'styled-components';

const SelectField = styled.select<Pick<SelectProps, 'border'>>(
  ({ border, theme: { colors } }) => css`
    font-family: 'GTAmerica', sans-serif;
    appearance: none;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-style: normal;
    transition: color 200ms;
    background-color: transparent;
    border: 0;
    color: ${colors.black};

    ~ ${TextInputBorder}:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 999;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: ${colors.black};
      margin-top: -2px;
      margin-right: 10px;
    }

    &:disabled {
      color: ${colors.grey6};
      cursor: not-allowed;
    }

    &:not(:disabled):hover ~ ${TextInputBorder} {
      border-color: ${colors.black};
    }

    &:focus {
      outline: 0;

      ~ ${TextInputBorder} {
        border-color: ${colors.black};
        box-shadow: ${border !== 'stroke'
          ? 'inset 0px 4px 4px rgba(0, 0, 0, 0.08);'
          : 'none'};
      }
    }
  `
);

export default SelectField;
