import TextInputBorder from 'components/Input/InputPartials/TextInputBorder';
import { FC, MouseEventHandler, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface InputActionProps {
  border?: 'none' | 'stroke' | 'outline';
  title?: string;
  visible?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  describedById?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TextInputActionButton = styled.button<
  Pick<InputActionProps, 'border' | 'disabled'>
>(
  ({ border, theme: { colors } }) => css`
    appearance: none;
    background-color: transparent;
    border-radius: 10px;
    position: relative;
    transition: border-color 200ms, z-index 0ms 200ms, opacity 200ms;
    z-index: 1;
    padding: 0 16px;
    margin: 0 1px;
    border: ${border === 'stroke' ? 'none' : '1px solid transparent'};
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 4%;
    text-transform: uppercase;
    opacity: 1;
    color: ${colors.black};

    &:disabled {
      color: ${colors.grey6};
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:not(:disabled):hover {
      border: ${border === 'stroke' ? 'none' : `1px solid ${colors.black}`};
      transition: border-color 200ms, opacity 200ms;
      z-index: 2;

      ~ ${TextInputBorder} {
        border-color: ${!border || border === 'none'
          ? colors.grey0
          : colors.grey4};
        box-shadow: none;
      }
    }

    &:active {
      outline: 0;
    }
  `
);

const TextInputAction: FC<InputActionProps> = ({
  onClick,
  border,
  disabled,
  children,
  visible,
  title = 'Action',
  describedById,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <TextInputActionButton
      border={border}
      title={title}
      onClick={onClick}
      disabled={disabled}
      aria-describedby={describedById}
    >
      {children}
    </TextInputActionButton>
  );
};

export default TextInputAction;
