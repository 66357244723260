import { FC, useCallback, useEffect } from 'react';
import { setDimensions } from 'store/dimensions';
import { useDispatch } from 'store/hooks';
import { Dispatch } from 'store/types';
import { debounce } from 'ts-debounce';

export const setWindowDimensions =
  (dispatch: Dispatch, w: Window | null = global.window) =>
  () => {
    if (w) {
      dispatch(setDimensions({ width: w.innerWidth, height: w.innerHeight }));
    }
  };

export const handleResizeCallback = (
  dispatch: Dispatch,
  w: Window | null = global.window
) => debounce(setWindowDimensions(dispatch, w), 300);

export const createListeners =
  (handleResize: () => void, w: Window | null = global.window) =>
  () => {
    if (w) {
      w.addEventListener('resize', handleResize);
    }

    return () => {
      if (w) {
        w.removeEventListener('resize', handleResize);
      }
    };
  };

const DimensionsListener: FC = () => {
  const dispatch = useDispatch();
  const handleResize = useCallback(handleResizeCallback(dispatch), [dispatch]);
  useEffect(setWindowDimensions(dispatch), [dispatch]);
  useEffect(createListeners(handleResize), [handleResize]);
  return null;
};

export default DimensionsListener;
