import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginRequest,
  resetPasswordRequest,
  setPasswordRequest,
} from 'store/auth/api';
import {
  DownstreamAuthState,
  Login,
  ResetPassword,
  SetPassword,
} from 'store/auth/types';
import { ThunkApi } from 'store/types';

export const generateAnonymousId = createAsyncThunk<string, void, ThunkApi>(
  'auth/generateAnonymousId',
  async () => {
    const response = await fetch('/services/graphene/session', {
      method: 'POST',
    });
    const { grapheneUid } = await response.json();
    return grapheneUid;
  }
);

export const login = createAsyncThunk<DownstreamAuthState, Login, ThunkApi>(
  'auth/login',
  ({ email, password }) =>
    loginRequest({
      email,
      password,
    })
);

export const resetPassword = createAsyncThunk<void, ResetPassword, ThunkApi>(
  'auth/resetPassword',
  ({ email, locale }) => resetPasswordRequest({ email, locale })
);

export const setPassword = createAsyncThunk<void, SetPassword, ThunkApi>(
  'auth/setPassword',
  ({ password, token }) =>
    setPasswordRequest({
      password,
      token,
    })
);
