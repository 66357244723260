import commerceTools from 'lib/commerceTools';
import { getApiLocale } from 'lib/locale';
import { toPayment } from 'models/payments/serializers';
import {
  CreateSessionRequestModel,
  GetPaymentMethodsRequestModel,
  paymentAuthorisedModel,
  paymentErrorModel,
  paymentRejectedModel,
} from 'models/payments/types';
import { toShippingMethod } from 'models/shippingMethods/serializers';
import { ShippingMethodModel } from 'models/shippingMethods/types';
import {
  CreateAdyenPaymentRequest,
  RetrieveShippingMethodsRequest,
  SendAdditionalAdyenPaymentDetailsRequest,
  SendAdyenPaymentRequest,
} from 'store/checkout/types';

export const retrieveShippingMethodsRequest: RetrieveShippingMethodsRequest =
  async ({ token, currency, cartId }) => {
    const { body } = await commerceTools
      .shippingMethods()
      .matchingCart()
      .get({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        queryArgs: {
          cartId,
        },
      })
      .execute();

    return body.results
      .map(shippingMethod => toShippingMethod({ shippingMethod, currency }))
      .filter((result): result is ShippingMethodModel => result !== null);
  };

export const createAdyenPaymentRequest: CreateAdyenPaymentRequest = async ({
  cartId,
  customerEmail,
  customerId,
  cartVersion,
  token,
  locale,
  centAmount,
  currencyCode,
}) => {
  const { country, shopperLocale } = getApiLocale(locale);

  const getPaymentMethodsRequest: GetPaymentMethodsRequestModel = {
    countryCode: country,
    shopperLocale,
    amount: {
      currency: currencyCode,
      value: centAmount,
    },
  };

  const { body: payment } = await commerceTools
    .me()
    .payments()
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        amountPlanned: {
          currencyCode,
          centAmount,
        },
        paymentMethodInfo: {
          paymentInterface: 'ctp-adyen-integration',
        },
        custom: {
          type: {
            typeId: 'type',
            key: 'ctp-adyen-integration-web-components-payment-type',
          },
          fields: {
            adyenMerchantAccount: 'PerfectMomentLTDECOM',
            commercetoolsProjectKey:
              process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY,
            getPaymentMethodsRequest: JSON.stringify(getPaymentMethodsRequest),
            cart: { id: cartId, typeId: 'cart' },
          },
        },
      },
    })
    .execute();

  const createSessionRequest: CreateSessionRequestModel = {
    amount: {
      currency: currencyCode,
      value: centAmount,
    },
    countryCode: country,
    shopperLocale,
    shopperEmail: customerEmail,
    shopperReference: customerId,
    reference: payment.id,
    returnUrl: [
      window?.location.origin as string,
      locale,
      'payment-pending',
      cartId,
      payment.id,
    ].join('/'),
    merchantAccount: 'PerfectMomentLTDECOM',
  };

  const { body: paymentWithSession } = await commerceTools
    .me()
    .payments()
    .withId({ ID: payment.id })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version: payment.version,
        actions: [
          {
            action: 'setCustomField',
            name: 'createSessionRequest',
            value: JSON.stringify(createSessionRequest),
          },
        ],
      },
    })
    .execute();

  await commerceTools
    .me()
    .carts()
    .withId({ ID: cartId })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version: cartVersion,
        actions: [
          {
            action: 'addPayment',
            payment: { typeId: 'payment', id: paymentWithSession.id },
          },
        ],
      },
    })
    .execute();

  return toPayment({ payment: paymentWithSession });
};

export const sendAdyenPaymentRequest: SendAdyenPaymentRequest = async ({
  paymentId,
  token,
  version,
  payload,
}) => {
  if (!paymentId) {
    return null;
  }

  const { body: makePaymentResponse } = await commerceTools
    .me()
    .payments()
    .withId({ ID: paymentId })
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version,
        actions: [
          {
            action: 'setCustomField',
            name: 'makePaymentRequest',
            value: JSON.stringify(payload),
          },
        ],
      },
    })
    .execute();

  const payment = toPayment({
    payment: makePaymentResponse,
  });

  const field = payment?.custom.fields.makePaymentResponse;

  if (paymentErrorModel.is(field)) {
    throw new Error(field.message);
  }

  if (paymentRejectedModel.is(field)) {
    throw new Error(field.refusalReason);
  }

  return payment;
};

export const sendAdditionalAdyenPaymentDetailsRequest: SendAdditionalAdyenPaymentDetailsRequest =
  async ({ paymentId, token, payload }) => {
    if (!paymentId) {
      return null;
    }

    const { body: existingPaymentResponse } = await commerceTools
      .me()
      .payments()
      .withId({ ID: paymentId })
      .get({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .execute();

    const existingPayment = toPayment({
      payment: existingPaymentResponse,
    });

    if (
      paymentAuthorisedModel.is(
        existingPayment?.custom.fields.submitAdditionalPaymentDetailsResponse
      )
    ) {
      return existingPayment;
    }

    const { body: submitAdditionalPaymentDetailsResponse } = await commerceTools
      .me()
      .payments()
      .withId({ ID: paymentId })
      .post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          version: existingPaymentResponse.version,
          actions: [
            {
              action: 'setCustomField',
              name: 'submitAdditionalPaymentDetailsRequest',
              value: JSON.stringify(payload),
            },
          ],
        },
      })
      .execute();

    const payment = toPayment({
      payment: submitAdditionalPaymentDetailsResponse,
    });

    const field = payment?.custom.fields.submitAdditionalPaymentDetailsResponse;

    if (paymentErrorModel.is(field)) {
      throw new Error(field.message);
    }

    if (paymentRejectedModel.is(field)) {
      throw new Error(field.refusalReason);
    }

    return payment;
  };
