import { countries } from 'lib/locale';
import Head from 'next/head';
import { FC, memo } from 'react';

interface Props {
  pathname: string;
}

const Alternates: FC<Props> = ({ pathname }) => {
  const storelessPathname = pathname.replace(/\/en\-[a-zA-Z]{2}\/?/, '/');

  const pagePath = storelessPathname === '/' ? '' : storelessPathname;

  return (
    <Head>
      <link
        key="x-default"
        rel="alternate"
        hrefLang="x-default"
        href={`https://www.perfectmoment.com/en-us${pagePath}`}
      />
      {Object.keys(countries).map(countryCode => {
        return (
          <link
            key={countryCode}
            rel="alternate"
            hrefLang={`en-${countryCode}`}
            href={`https://www.perfectmoment.com/en-${countryCode}${pagePath}`}
          />
        );
      })}
    </Head>
  );
};

export default memo(Alternates);
