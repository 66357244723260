import toResponsiveValue, { ResponsiveValue } from 'lib/toResponsiveValue';
import styled, { css } from 'styled-components';
import { bp } from 'theme/breakpoints';

export type GapSize =
  | 0
  | 4
  | 8
  | 16
  | 18
  | 24
  | 32
  | 40
  | 48
  | 64
  | 72
  | 80
  | 96
  | 128
  | 160;

interface GapProps {
  size: GapSize | ResponsiveValue<GapSize>;
  children?: undefined;
}

const Gap = styled.span<GapProps>(({ size: s }) => {
  const size = toResponsiveValue(s);

  return css`
    display: block;
    width: 100%;
    height: ${size.xs}px;

    ${bp.s} {
      height: ${size.s}px;
    }

    ${bp.m} {
      height: ${size.m}px;
    }

    ${bp.l} {
      height: ${size.l}px;
    }

    ${bp.xl} {
      height: ${size.xl}px;
    }

    ${bp.xxl} {
      height: ${size.xxl}px;
    }
  `;
});

export default Gap;
