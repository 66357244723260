import * as t from 'io-ts';

export const imageModel = t.interface(
  {
    url: t.string,
  },
  'Image'
);

export type ContentModeller<
  TInput,
  TReturn,
  TAdditionalArguments extends Record<string, unknown> = Record<string, unknown>
> = (
  args: { content: TInput; locale: string } & TAdditionalArguments
) => TReturn | null;
