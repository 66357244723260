import { triggerAddToCart, triggerRemoveFromCart } from 'lib/gtag';
import { CartItemModel } from 'models/cartItems/types';
import { MutableRefObject } from 'react';

export const quantityChangeEffect =
  (previousQuantity: MutableRefObject<number>, lineItem: CartItemModel) =>
  () => {
    const quantity = lineItem.quantity;

    if (quantity > previousQuantity.current) {
      triggerAddToCart(
        lineItem.productId,
        lineItem.name,
        lineItem.variantSku,
        lineItem.price,
        quantity - previousQuantity.current,
        global.window
      );
    } else if (quantity < previousQuantity.current) {
      triggerRemoveFromCart(
        lineItem.productId,
        lineItem.name,
        lineItem.variantSku,
        lineItem.price,
        previousQuantity.current - quantity,

        global.window
      );
    }
    previousQuantity.current = quantity;
  };
