import { CrossCircle } from 'components/Icons';
import { TextInputProps } from 'components/Input/helpers';
import {
  InputContainer,
  InputError,
  InputHelperText,
  InputIcon,
  InputLabel,
  InputLoader,
  TextInputAction,
  TextInputBorder,
  TextInputField,
  TextInputPositioner,
} from 'components/Input/InputPartials';
import useInputField from 'hooks/useTextInput';
import { FC } from 'react';

const TextInput: FC<TextInputProps> = ({
  autoComplete,
  action,
  onChange,
  onKeyDown,
  onFocus,
  clearable,
  label,
  name,
  helperText,
  error,
  disabled,
  loading,
  tabIndex,
  type = 'text',
  spellCheck = true,
  icon = null,
  showError = true,
  border = 'none',
  value = '',
  placeholder = '',
  actionTitle = 'Action',
  role,
  inputMode,
  required,
  describedById,
}) => {
  const uneditable = Boolean(disabled || loading);

  const { htmlFieldId, changeEvent, clearEvent, actionEvent } = useInputField({
    onChange,
    action,
  });

  return (
    <InputContainer>
      <InputLabel label={label} htmlFor={htmlFieldId} disabled={uneditable} />
      <TextInputPositioner border={border}>
        <InputLoader loading={loading}>
          <InputIcon
            icon={icon}
            disabled={uneditable}
            padded={border !== 'stroke'}
            htmlFor={htmlFieldId}
          />
          <TextInputField
            autoComplete={autoComplete}
            type={type}
            placeholder={placeholder}
            border={border}
            value={value}
            onChange={changeEvent}
            disabled={disabled}
            name={name}
            spellCheck={spellCheck}
            hasIcon={Boolean(icon)}
            id={htmlFieldId}
            tabIndex={tabIndex}
            inputMode={inputMode}
            aria-required={required}
            role={role}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
          />
        </InputLoader>
        <TextInputAction
          visible={value.length > 0 && clearable === true}
          onClick={clearEvent}
          title="Clear"
          disabled={uneditable}
        >
          <CrossCircle />
        </TextInputAction>
        <TextInputAction
          visible={Boolean(action)}
          onClick={actionEvent(value)}
          title={actionTitle}
          disabled={uneditable}
          describedById={describedById}
        >
          {actionTitle}
        </TextInputAction>
        <TextInputBorder
          border={border}
          hasError={Boolean(error) && showError === true}
          disabled={uneditable}
        />
      </TextInputPositioner>
      <InputHelperText helperText={helperText} />
      <InputError
        error={error}
        visible={showError}
        hasHelperText={Boolean(helperText)}
      />
    </InputContainer>
  );
};

export default TextInput;
