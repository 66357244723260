import { Address } from '@commercetools/platform-sdk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { login } from 'store/auth/thunks';
import { withAuth } from 'store/auth/wrappers';
import { joinCampaign } from 'store/campaigns/thunks';
import {
  changePasswordRequest,
  createAccountRequest,
  retrieveCustomerRequest,
  saveAddressRequest,
  saveUserInformationRequest,
} from 'store/customer/api';
import {
  DownstreamCustomerState,
  Password,
  SignUp,
  UserInformation,
} from 'store/customer/types';
import { ThunkApi } from 'store/types';

export const retrieveCustomer = createAsyncThunk<
  DownstreamCustomerState | undefined,
  void,
  ThunkApi
>('customer/retrieve', async (_, store) => {
  if (store.getState().auth.anonymousId || !store.getState().auth.accessToken) {
    return;
  }

  return withAuth(store)(() =>
    retrieveCustomerRequest({
      token: store.getState().auth.accessToken,
    })
  );
});

export const saveUserInformation = createAsyncThunk<
  DownstreamCustomerState | undefined,
  UserInformation,
  ThunkApi
>('customer/saveUserInformation', async (userInfo, { dispatch, getState }) => {
  const { auth } = getState();

  if (auth.anonymousId) {
    return;
  }

  await dispatch(retrieveCustomer());

  const { customer } = getState();

  return await saveUserInformationRequest({
    token: auth.accessToken,
    version: customer.version,
    ...userInfo,
  });
});

export const changePassword = createAsyncThunk<
  DownstreamCustomerState | undefined,
  Password,
  ThunkApi
>(
  'customer/changePassword',
  async ({ currentPassword, newPassword }, { dispatch, getState }) => {
    const { auth } = getState();

    if (auth.anonymousId) {
      return;
    }

    await dispatch(retrieveCustomer());

    const { customer } = getState();

    const response = await changePasswordRequest({
      token: auth.accessToken,
      version: customer.version,
      currentPassword,
      newPassword,
    });

    await dispatch(
      login({
        email: customer.email,
        password: newPassword,
      })
    );

    return response;
  }
);

export const saveAddress = createAsyncThunk<
  DownstreamCustomerState | undefined,
  Address,
  ThunkApi
>('customer/saveAddress', async (address, { dispatch, getState }) => {
  const { auth } = getState();

  if (auth.anonymousId) {
    return;
  }

  await dispatch(retrieveCustomer());

  const { customer } = getState();

  return await saveAddressRequest({
    token: auth.accessToken,
    version: customer.version,
    address,
  });
});

export const createAccount = createAsyncThunk<
  DownstreamCustomerState | undefined,
  SignUp,
  ThunkApi
>(
  'customer/createAccount',
  async (
    { firstName, lastName, email, password, phone_number, subscribe },
    { dispatch, getState }
  ) => {
    const { auth } = getState();

    const trimmedEmail = email.trim();

    const customer = await createAccountRequest({
      email: trimmedEmail,
      password,
      firstName,
      lastName,
      phone_number,
      token: auth.accessToken,
    });

    if (!subscribe) {
      return customer;
    }

    dispatch(
      joinCampaign({
        ue: trimmedEmail,
        phone_number: phone_number,
        firstname: firstName,
        lastname: lastName,
        gdpr: true,
        pm_form: 'checkout_confirmation',
      })
    );

    return customer;
  }
);
