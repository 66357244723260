import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetCart } from 'store/cart/cart';
import { resetCheckout } from 'store/checkout/checkout';
import { retrieveConfirmationRequest } from 'store/confirmation/api';
import {
  DownstreamConfirmationState,
  RetrieveConfirmation,
} from 'store/confirmation/types';
import { ThunkApi } from 'store/types';

export const retrieveConfirmation = createAsyncThunk<
  DownstreamConfirmationState | undefined,
  RetrieveConfirmation,
  ThunkApi
>(
  'confirmation/retrieveConfirmation',
  async ({ cartId, locale }, { dispatch, getState }) => {
    const payload = await retrieveConfirmationRequest({
      cartId,
      locale,
      token: getState().auth.accessToken,
    });

    if (payload.cartId === getState().cart.id) {
      dispatch(resetCheckout());
      dispatch(resetCart());
    }

    return payload;
  }
);
