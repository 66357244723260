import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { removeDiscountCode } from 'store/cart/thunks';
import { useDispatch } from 'store/hooks';
import { Dispatch } from 'store/types';

interface CartDiscountAdjusterProps {
  id: string;
  name: string;
  itemCount: number;
}

type RemoveUnassignableDiscount = (args: {
  dispatch: Dispatch;
  locale: string;
  id: string;
  name: string;
  itemCount: number;
}) => () => void;

export const removeUnassignableDiscount: RemoveUnassignableDiscount =
  ({ dispatch, locale, id, name }) =>
  () => {
    dispatch(
      removeDiscountCode({
        discountId: id,
        locale,
        message: `${name} could not be applied to your bag.`,
      })
    );
  };

const CartDiscountAdjuster: FC<CartDiscountAdjusterProps> = ({
  id,
  name,
  itemCount,
}) => {
  const { query } = useRouter();
  const dispatch = useDispatch();

  useEffect(
    removeUnassignableDiscount({
      dispatch,
      locale: [query.locale].join(''),
      id,
      name,
      itemCount,
    }),
    [id]
  );

  return null;
};

export default CartDiscountAdjuster;
