import { LineItem } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { getApiLocale } from 'lib/locale';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import translate from 'lib/translate';
import { toKeyedObject } from 'lib/util';
import zeroable from 'lib/zeroable';
import {
  AddExistingCartItemsModel,
  cartItemModel,
  CartItemModel,
} from 'models/cartItems/types';
import { deriveCartItemDisplayPrice } from 'models/cartItems/utilities';
import { toDiscountMap } from 'models/discounts/serializers';
import { Currency } from 'models/locales/types';
import { toVariant } from 'models/variants/serializers';
import { PriceModel } from 'models/variants/types';
import { deriveAvailability, toPrice } from 'models/variants/utilities';

type ToCartItem = (args: {
  lineItem: LineItem;
  locale: string;
}) => CartItemModel | null;

type ToCartItemMap = (args: {
  lineItems?: LineItem[];
  locale: string;
}) => Record<string, CartItemModel>;

type ToExistingCartItems = (args: {
  lineItems: LineItem[];
}) => AddExistingCartItemsModel;

export const toCartItem: ToCartItem = ({ lineItem, locale }) => {
  const { language } = getApiLocale(locale);
  const t = translate(language);

  const variant = toVariant({
    variant: lineItem.variant,
    slug: lineItem.productSlug?.en as string,
    locale,
  });

  if (!variant) {
    return null;
  }

  const { cartDiscounts } = toDiscountMap({
    discountCodes: [],
    cartDiscounts:
      lineItem.discountedPricePerQuantity?.[0]?.discountedPrice
        ?.includedDiscounts,
    language,
    currency: lineItem.totalPrice.currencyCode as Currency,
  });

  const model: CartItemModel = {
    lineItemId: lineItem.id,
    productId: lineItem.productId,
    slug: [lineItem.productSlug?.en].join(''),
    name: t(lineItem.name),
    productType: [lineItem.productType.obj?.name].join(''),
    quantity: lineItem.quantity,
    price: toPrice(lineItem.price),
    taxPercentage: zeroable(lineItem.taxRate?.amount),
    amountIncludingTax: zeroable(lineItem.price?.value.centAmount),
    subtotal: deriveCartItemDisplayPrice(lineItem.taxedPrice?.totalNet),
    totalPrice: deriveCartItemDisplayPrice(lineItem.totalPrice) as PriceModel,
    variant: variant.id,
    variantSku: variant.sku,
    size: variant.size,
    swatch: variant.swatch,
    image: variant.images[0]?.url,
    isGift: lineItem.lineItemMode === 'GiftLineItem',
    isPreorder: variant.preorder,
    isFinalSale: variant.finalSale,
    discounts: { ...cartDiscounts },
  };

  return pipe(
    cartItemModel.decode(model),
    fold(
      reportTypeErrors({
        id: lineItem.variant.sku as string,
        model: 'Cart Product',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const toCartItemMap: ToCartItemMap = ({ lineItems = [], locale }) =>
  toKeyedObject(
    lineItems
      .map(lineItem => toCartItem({ lineItem, locale }))
      .filter((item): item is CartItemModel => item !== null),
    'lineItemId'
  );

export const toExistingCartItems: ToExistingCartItems = ({ lineItems }) =>
  lineItems.reduce<AddExistingCartItemsModel>((acc, lineItem) => {
    const { availableQuantity } = deriveAvailability(lineItem.variant);

    if (availableQuantity === 0) {
      return acc;
    }

    acc.push({
      action: 'addLineItem',
      productId: lineItem.productId,
      variantId: lineItem.variant.id,
      quantity: Math.min(lineItem.quantity, availableQuantity),
    });

    return acc;
  }, []);
