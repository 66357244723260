import { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

interface InputIconProps {
  icon?: ReactElement | null;
  padded?: boolean;
  htmlFor?: string;
  disabled?: boolean;
  className?: string;
}

const IconContainer = styled.label<Pick<InputIconProps, 'padded' | 'disabled'>>(
  ({ padded, disabled }) => css`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    transition: opacity 200ms;
    padding-left: ${padded ? 16 : 0}px;
    opacity: ${disabled ? 0.5 : 1};
    cursor: ${disabled ? 'not-allowed' : 'text'};
  `
);

const InputIcon: FC<InputIconProps> = ({
  icon,
  padded,
  htmlFor,
  disabled,
  className,
}) => {
  if (!icon) {
    return null;
  }

  return (
    <IconContainer
      className={className}
      htmlFor={htmlFor}
      padded={padded}
      disabled={disabled}
      aria-hidden={true}
    >
      {icon}
    </IconContainer>
  );
};

export default InputIcon;
