import { displayErrors } from 'lib/errors';
import { useEffect } from 'react';
import { selectGlobalCartErrors } from 'store/cart/selectors';
import { selectGlobalCustomerErrors } from 'store/customer/selectors';
import { useSelector } from 'store/hooks';

const ErrorListener = () => {
  const loginError = useSelector(state => state.auth.errors.login);

  const paymentSubmissionError = useSelector(
    state => state.checkout.errors.sendPayment
  );

  const globalCustomerErrors = useSelector(selectGlobalCustomerErrors);
  const globalCartErrors = useSelector(selectGlobalCartErrors);

  useEffect(displayErrors([loginError]), [loginError]);
  useEffect(displayErrors([paymentSubmissionError]), [paymentSubmissionError]);
  useEffect(displayErrors(globalCustomerErrors), [globalCustomerErrors]);
  useEffect(displayErrors(globalCartErrors), [globalCartErrors]);

  return null;
};

export default ErrorListener;
