import { FC, useEffect } from 'react';
import { resetCampaigns } from 'store/campaigns';
import { selectSomeCampaignsHaveCompleted } from 'store/campaigns/selectors';
import { useDispatch, useSelector } from 'store/hooks';
import { openOverlay } from 'store/overlays';

const CampaignSuccessListener: FC = () => {
  const dispatch = useDispatch();

  const someCampaignsHaveCompleted = useSelector(
    selectSomeCampaignsHaveCompleted
  );

  useEffect(() => {
    if (someCampaignsHaveCompleted) {
      dispatch(
        openOverlay({
          closeOnOutsideClick: true,
          name: 'campaignSuccess',
          props: {},
        })
      );
      dispatch(resetCampaigns());
    }
  }, [someCampaignsHaveCompleted]);

  return null;
};

export default CampaignSuccessListener;
