import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecentProductEntry, RecentState } from 'store/recent/types';

export const initialState: RecentState = {
  recentProducts: [],
};

const { reducer, actions } = createSlice({
  name: 'recent',
  initialState,
  reducers: {
    addRecentProduct(state, action: PayloadAction<RecentProductEntry>) {
      const array = [...state.recentProducts, action.payload];
      state.recentProducts = [
        ...new Map(array.map(item => [item.productKey, item])).values(),
      ];
    },

    clearRecent(state) {
      state.recentProducts = [];
    },
  },
});

export const { addRecentProduct, clearRecent } = actions;
export default reducer;
