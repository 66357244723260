import type { BreakpointKey } from 'lib/toResponsiveValue';
import { noop } from 'lib/util';
import { FC, useEffect } from 'react';
import { setBreakpoint } from 'store/dimensions';
import { useDispatch } from 'store/hooks';
import { Dispatch } from 'store/types';
import { DefaultTheme, useTheme } from 'styled-components';

type DeprecatedMediaQueryAddListener =
  | MediaQueryList['addListener']
  | undefined;
type MediaQueryAddListener = MediaQueryList['addEventListener'] | undefined;
type DeprecatedMediaQueryRemoveListener =
  | MediaQueryList['removeListener']
  | undefined;
type MediaQueryRemoveListener =
  | MediaQueryList['removeEventListener']
  | undefined;

export const setBreakpointCallback =
  (breakpoint: BreakpointKey, dispatch: Dispatch) =>
  (e: { matches: boolean }) => {
    if (!e.matches) {
      return;
    }

    dispatch(setBreakpoint(breakpoint));
  };

export const removeBreakpointListeners =
  (
    mediaQueries: Record<BreakpointKey, MediaQueryList>,
    events: Record<BreakpointKey, ReturnType<typeof setBreakpointCallback>>
  ) =>
  (): void => {
    for (const key in mediaQueries) {
      const bp = key as BreakpointKey;

      // iOS 13 and below supports only removeListener rather than removeEventListener
      if (
        mediaQueries[bp]?.removeListener as DeprecatedMediaQueryRemoveListener
      ) {
        mediaQueries[bp].removeListener(events[bp]);
      } else if (
        mediaQueries[bp]?.removeEventListener as MediaQueryRemoveListener
      ) {
        mediaQueries[bp].removeEventListener('change', events[bp]);
      } else {
        noop();
      }
    }
  };

export const addBreakpointListeners =
  (
    bpRange: DefaultTheme['bpRange'],
    dispatch: Dispatch,
    w: Window | undefined
  ) =>
  (): (() => void) => {
    if (typeof w === 'undefined' || typeof w.matchMedia === 'undefined') {
      return () => undefined;
    }

    const mediaQueries = {} as Record<BreakpointKey, MediaQueryList>;
    const events = {} as Record<
      BreakpointKey,
      ReturnType<typeof setBreakpointCallback>
    >;

    for (const key in bpRange) {
      const bp = key as BreakpointKey;

      mediaQueries[bp] = w.matchMedia(bpRange[bp]);

      // Set the current breakpoint immediately if applicable
      setBreakpointCallback(
        bp,
        dispatch
      )({ matches: mediaQueries[bp]?.matches });

      // Add this breakpoint callback to a list of breakpoint callbacks to ensure that it can be removed
      events[bp] = setBreakpointCallback(bp, dispatch);

      // iOS 13 and below supports only addListener rather than addEventListener
      if (mediaQueries[bp]?.addListener as DeprecatedMediaQueryAddListener) {
        mediaQueries[bp].addListener(events[bp]);
      } else if (mediaQueries[bp]?.addEventListener as MediaQueryAddListener) {
        mediaQueries[bp].addEventListener('change', events[bp]);
      } else {
        noop();
      }
    }

    return removeBreakpointListeners(mediaQueries, events);
  };

export const useBreakpointListener = (): void => {
  const { bpRange } = useTheme();
  const dispatch = useDispatch();

  useEffect(addBreakpointListeners(bpRange, dispatch, global.window), [
    bpRange,
    dispatch,
  ]);
};

const BreakpointListener: FC = () => {
  useBreakpointListener();

  return null;
};

export default BreakpointListener;
