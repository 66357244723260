import Gap from 'components/Gap';
import { WarningFailure, WarningFailureSmall } from 'components/Icons';
import { Title } from 'components/Typography';
import { FC, ReactNode, memo } from 'react';
import styled from 'styled-components';

interface InputErrorProps {
  error?: ReactNode;
  visible?: boolean;
  hasHelperText?: boolean;
  smallIcon?: boolean;
}

const ErrorContainer = styled.span`
  display: flex;
  flex-direction: row;
`;

const ErrorIcon = styled.span`
  flex: 0 1;
`;

const Error = styled(Title)`
  padding-top: 3px;
  padding-left: 5px;
  flex: 1 0;
`;

const InputError: FC<InputErrorProps> = ({
  error,
  visible,
  hasHelperText,
  smallIcon = true,
}) => {
  if (!error || !visible) {
    return null;
  }

  return (
    <>
      <Gap size={hasHelperText ? 4 : 8} />
      <ErrorContainer>
        <ErrorIcon>
          {smallIcon ? <WarningFailureSmall /> : <WarningFailure />}
        </ErrorIcon>
        <Error variant="1" size="2XS" aria-live="assertive" aria-atomic={true}>
          {error}
        </Error>
      </ErrorContainer>
    </>
  );
};

export default memo(InputError);
