import Gap from 'components/Gap';
import type { BooleanGroupInputProps } from 'components/Input/helpers';
import {
  InputContainer,
  InputError,
  InputHelperText,
  InputLabel,
} from 'components/Input/InputPartials';
import RadioInput from 'components/Input/RadioInput';
import { FC, Fragment } from 'react';

const RadioGroupInput: FC<BooleanGroupInputProps> = ({
  label,
  disabled,
  helperText,
  error,
  options,
  name,
  value,
  showError = true,
  onChange,
}) => (
  <InputContainer>
    <InputLabel label={label} disabled={disabled} />
    {options.map((option, index, array) => (
      <Fragment key={option.value + option.label}>
        <RadioInput
          name={name}
          value={option.value}
          disabled={disabled}
          checked={option.value === value}
          label={option.label}
          icon={option.icon}
          onChange={onChange}
        />
        <Gap size={index === array.length - 1 ? 0 : 8} />
      </Fragment>
    ))}
    <InputHelperText helperText={helperText} />
    <InputError
      error={error}
      visible={showError}
      hasHelperText={Boolean(helperText)}
    />
  </InputContainer>
);

export default RadioGroupInput;
