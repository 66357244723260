import { IFooter, ISiteSettings } from 'generated/contentful';
import deriveLocalisedContentItem from 'lib/contentful/deriveLocalisedContentItem';
import fetchResource from 'lib/contentful/fetchResource';
import { sendReport } from 'lib/sendReport';
import toFooter, {
  defaultFooterModel,
} from 'models/contentful/footer/serializers';
import { FooterModel } from 'models/contentful/footer/types';
import toSiteSettings, {
  defaultSiteSettingsModel,
} from 'models/contentful/siteSettings/serializers';
import { SiteSettingsModel } from 'models/contentful/siteSettings/types';

const fetchSettings = async (
  locale: string,
  w: Window | undefined
): Promise<{
  siteSettings: SiteSettingsModel;
  footer: FooterModel;
}> => {
  if (typeof w !== 'undefined') {
    return {
      siteSettings: defaultSiteSettingsModel,
      footer: defaultFooterModel,
    };
  }

  try {
    const siteSettingsContent = await fetchResource<ISiteSettings>({
      type: 'siteSettings',
      query: {
        locale,
      },
    });

    const footerContent = await fetchResource<IFooter>({
      type: 'footer',
      query: {
        locale,
      },
    });

    return {
      siteSettings: toSiteSettings({
        content: deriveLocalisedContentItem(
          siteSettingsContent,
          locale
        ) as ISiteSettings,
        locale,
      }) as SiteSettingsModel,
      footer: toFooter({
        content: deriveLocalisedContentItem(footerContent, locale) as IFooter,
        locale,
      }) as FooterModel,
    };
  } catch (error) {
    sendReport(error);

    return {
      siteSettings: defaultSiteSettingsModel,
      footer: defaultFooterModel,
    };
  }
};

export default fetchSettings;
