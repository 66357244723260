import { ProductProjection } from '@commercetools/platform-sdk';
import { commerceToolsClient } from 'lib/commerceTools/publicClient';
import search from 'lib/commerceTools/search';

type SearchByCategories = (args: {
  keys: string[];
  locale: string;
}) => Promise<Record<string, ProductProjection>>;

const searchByCategories: SearchByCategories = async ({ keys, locale }) => {
  if (!keys.length) {
    return {};
  }

  const categories = await commerceToolsClient
    .categories()
    .get({
      queryArgs: {
        where: `key in (${keys.map(key => `"${key}"`)})`,
      },
    })
    .execute();

  if (categories.body.results.length === 0) {
    return {};
  }

  return await search({
    query: {
      'filter.query': [
        `categories.id:${categories.body.results
          .map(res => `"${res.id}"`)
          .join(',')}`,
      ],
    },
    locale,
  });
};

export default searchByCategories;
