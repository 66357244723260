/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC } from 'react';

const Geolocate: FC = () => {
  const router = useRouter();

  if (router.route === '/404') {
    return null;
  }

  return (
    <Script
      strategy="beforeInteractive"
      src={`/services/geolocate?url=${encodeURIComponent(`${router.asPath}`)}`}
    />
  );
};

export default Geolocate;
