import { type ProductProjection } from '@commercetools/platform-sdk';
import { type ByProjectKeyProductProjectionsSearchRequestBuilder } from '@commercetools/platform-sdk/dist/declarations/src/generated/client/search/by-project-key-product-projections-search-request-builder';
import commerceTools from 'lib/commerceTools';
import { getClientSideFeatureFlags } from 'lib/featureFlags';
import { getApiLocale } from 'lib/locale';

type QueryArgs = NonNullable<
  Required<
    Parameters<ByProjectKeyProductProjectionsSearchRequestBuilder['get']>
  >[0]['queryArgs']
>;

type SearchProducts = (args: {
  query: QueryArgs;
  locale: string;
  allowOutOfStock?: boolean;
}) => Promise<Record<string, ProductProjection>>;

const search: SearchProducts = async ({
  query,
  locale,
  allowOutOfStock = false,
}) => {
  const { country, currency, store } = getApiLocale(locale);

  const { body } = await commerceTools
    .productProjections()
    .search()
    .get({
      queryArgs: {
        ...query,
        expand: ([] as string[])
          .concat(query['expand'] ?? [])
          .concat([
            'masterVariant.attributes[*].value[*][*].value',
            'masterVariant.attributes[*].value[*][*].value.masterData.current.categories[*]',
            'productType',
            'categories[*]',
          ]),
        limit: query.limit ?? 500,
        markMatchingVariants: true,
        'filter.query': ([] as string[])
          .concat(query['filter.query'] ?? [])
          .concat([
            'variants.prices:exists',
            `variants.attributes.includedStores.key:"${store}"`,
          ])
          .concat(
            allowOutOfStock
              ? []
              : [
                  'variants.attributes.available:true',
                  'variants.availability.isOnStockInChannels:range("0" to "z")',
                ]
          ),
        priceCountry: country,
        priceCurrency: currency,
        storeProjection: store,
        staged: getClientSideFeatureFlags().isStaging,
      },
    })
    .execute();

  const results: Record<string, ProductProjection> = {};

  for (const result of body.results) {
    results[result.key as string] = result;
  }

  return results;
};

export default search;
