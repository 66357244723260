import dynamic from 'next/dynamic';

export const AfterPay = dynamic(
  () => import('components/Icons/PaymentMethods/AfterPay'),
  { ssr: false }
);

export const AliPay = dynamic(
  () => import('components/Icons/PaymentMethods/AliPay'),
  { ssr: false }
);

export const Amex = dynamic(
  () => import('components/Icons/PaymentMethods/Amex'),
  { ssr: false }
);

export const ApplePay = dynamic(
  () => import('components/Icons/PaymentMethods/ApplePay'),
  { ssr: false }
);

export const Jcb = dynamic(
  () => import('components/Icons/PaymentMethods/Jcb'),
  { ssr: false }
);

export const Klarna = dynamic(
  () => import('components/Icons/PaymentMethods/Klarna'),
  { ssr: false }
);

export const Maestro = dynamic(
  () => import('components/Icons/PaymentMethods/Maestro'),
  { ssr: false }
);

export const Mastercard = dynamic(
  () => import('components/Icons/PaymentMethods/Mastercard'),
  { ssr: false }
);

export const PayPal = dynamic(
  () => import('components/Icons/PaymentMethods/PayPal'),
  { ssr: false }
);

export const UnionPay = dynamic(
  () => import('components/Icons/PaymentMethods/UnionPay'),
  { ssr: false }
);

export const Visa = dynamic(
  () => import('components/Icons/PaymentMethods/Visa'),
  { ssr: false }
);

export const WeChatPay = dynamic(
  () => import('components/Icons/PaymentMethods/WeChatPay'),
  { ssr: false }
);
