import * as t from 'io-ts';

export const linkModel = t.exact(
  t.interface({
    title: t.string,
    link: t.string,
  }),
  'Link'
);

export type LinkModel = t.TypeOf<typeof linkModel>;
