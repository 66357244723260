import styled, { css } from 'styled-components';

const BooleanInputButton = styled.label.attrs(() => ({
  'data-boolean-input-button': true,
}))(
  ({ theme: { colors } }) => css`
    display: block;
    position: relative;
    flex: 0 0 auto;
    width: 22px;
    height: 22px;
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.grey4};
    background-color: ${colors.white};
    transition: background-color 200ms, border-color 200ms, box-shadow 200ms;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0);
    outline: 0;
    appearance: none;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: transform 200ms, background-color 200ms;
      background-color: ${colors.white};
      transform: scale(0);
      pointer-events: none;
    }
  `
);

export default BooleanInputButton;
