import { Eye, EyeOff } from 'components/Icons';
import type { TextInputProps } from 'components/Input/helpers';
import TextInput from 'components/Input/TextInput';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

type PasswordInputProps = Omit<TextInputProps, 'type' | 'icon' | 'name'> &
  Partial<Pick<TextInputProps, 'name'>>;

const PasswordVisibilityToggle = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  appearance: none;
  line-height: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const PasswordInput: FC<PasswordInputProps> = ({
  name = 'password',
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    e => {
      e.preventDefault();
      setPasswordVisible(!passwordVisible);
    },
    [setPasswordVisible, passwordVisible]
  );

  const icon = useMemo(
    () => (
      <PasswordVisibilityToggle
        type="button"
        disabled={props.disabled}
        onClick={togglePasswordVisibility}
        title={passwordVisible ? 'Hide password' : 'Show password'}
        data-testid="password-visibility-toggle"
      >
        {passwordVisible ? <Eye /> : <EyeOff />}
      </PasswordVisibilityToggle>
    ),
    [passwordVisible, props.disabled]
  );

  return (
    <TextInput
      {...props}
      name={name}
      icon={icon}
      type={passwordVisible ? 'text' : 'password'}
      spellCheck={false}
    />
  );
};

export default PasswordInput;
