import { MAXIMUM_CART_QUANTITY } from 'components/CartItem/helpers/constants';
import {
  ChangeQuantityAction,
  ChangeVariantAction,
  GetQuantities,
  RemoveAction,
} from 'components/CartItem/helpers/types';
import { triggerRemoveFromCart } from 'lib/gtag';
import {
  changeLineItemQuantity,
  changeLineItemVariant,
} from 'store/cart/thunks';

export const getQuantities: GetQuantities = available => {
  if (!available) {
    return [];
  }

  const length = Math.min(available, MAXIMUM_CART_QUANTITY);
  const arr = [...new Array(length)].map((_, i) => i + 1);
  return arr;
};

export const changeVariantAction: ChangeVariantAction =
  ({ dispatch, lineItemId, variants, locale }) =>
  e => {
    if (!e.selectedItem || !variants[e.selectedItem.sku].id) {
      return;
    }

    dispatch(
      changeLineItemVariant({
        lineItemId,
        variantId: variants[e.selectedItem.sku].id,
        locale,
      })
    );
  };

export const changeQuantityAction: ChangeQuantityAction =
  ({ dispatch, lineItemId, locale }) =>
  e => {
    if (!e.selectedItem) {
      return;
    }

    dispatch(
      changeLineItemQuantity({
        lineItemId,
        quantity: e.selectedItem,
        locale,
      })
    );
  };

export const removeAction: RemoveAction =
  ({
    dispatch,
    lineItemId,
    productName,
    variantSku,
    price,
    quantity,
    locale,
  }) =>
  async () => {
    const response = await dispatch(
      changeLineItemQuantity({
        lineItemId,
        quantity: 0,
        locale,
      })
    );

    if (response.meta.requestStatus === 'rejected') {
      return;
    }

    triggerRemoveFromCart(
      lineItemId,
      productName,
      variantSku,
      price,
      quantity,
      global.window
    );
  };
