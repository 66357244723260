import * as t from 'io-ts';
import { flashBannerModel } from 'models/contentful/flashBanner/types';
import { productListModel } from 'models/contentful/productList/types';
import { imageModel } from 'models/contentful/shared/types';

const paymentMethod = t.keyof(
  {
    alipay: null,
    amex: null,
    applepay: null,
    jcb: null,
    klarna: null,
    maestro: null,
    mastercard: null,
    paypal: null,
    unionpay: null,
    visa: null,
    wechatpay: null,
  },
  'PaymentMethod'
);

export const siteSettingsModel = t.exact(
  t.interface({
    searchSuggestions: t.array(t.string),
    browserTitle: t.string,
    metaTitle: t.string,
    metaDescription: t.string,
    paymentMethods: t.array(paymentMethod),
    flashBanner: t.union([flashBannerModel, t.null]),
    metaImage: t.union([imageModel, t.null]),
    favicon: t.union([imageModel, t.null]),
    cartProductRecomendations: t.union([productListModel, t.null]),
  }),
  'SiteSettings'
);

export type PaymentMethod = t.TypeOf<typeof paymentMethod>;
export type SiteSettingsModel = t.TypeOf<typeof siteSettingsModel>;
