import * as t from 'io-ts';

const mediaAssetModel = t.exact(
  t.interface({
    url: t.string,
    description: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
    width: t.union([t.number, t.null]),
    height: t.union([t.number, t.null]),
  }),
  'Media Asset'
);

const mediaSourceModel = t.exact(
  t.interface({
    image: mediaAssetModel,
    lowResolutionVideo: t.union([mediaAssetModel, t.null]),
    highResolutionVideo: t.union([mediaAssetModel, t.null]),
  }),
  'Media Source'
);

export const mediaModel = t.exact(
  t.interface({
    xs: mediaSourceModel,
    s: t.union([mediaSourceModel, t.null]),
    m: t.union([mediaSourceModel, t.null]),
    l: t.union([mediaSourceModel, t.null]),
    xl: t.union([mediaSourceModel, t.null]),
    xxl: t.union([mediaSourceModel, t.null]),
    showVideoControls: t.boolean,
  }),
  'Media'
);

export type MediaAssetModel = t.TypeOf<typeof mediaAssetModel>;
export type MediaSourceModel = t.TypeOf<typeof mediaSourceModel>;
export type MediaModel = t.TypeOf<typeof mediaModel>;
