import * as t from 'io-ts';

export const paymentMethodType = t.keyof({
  paypal: null,
  scheme: null,
  applepay: null,
  klarna: null,
  klarna_account: null,
  alipay: null,
  wechatpayQR: null,
  directEbanking: null,
  ideal: null,
  afterpaytouch: null,
});

export const transactionType = t.keyof({
  Authorization: null,
  CancelAuthorization: null,
  Charge: null,
  Chargeback: null,
  Refund: null,
});

export const transactionState = t.keyof({
  Failure: null,
  Initial: null,
  Pending: null,
  Success: null,
});

export const adyenResponseDataModel = t.intersection([
  t.type({
    paymentMethod: t.intersection([
      t.type({ type: paymentMethodType }),
      t.record(t.string, t.unknown),
    ]),
  }),
  t.partial({
    riskData: t.type({
      clientData: t.string,
    }),
    browserInfo: t.record(t.string, t.unknown),
    clientStateDataIndicator: t.boolean,
  }),
]);

export const adyenResponseModel = t.type({
  data: adyenResponseDataModel,
  isValid: t.boolean,
});

export const adyenPaymentMethodModel = t.intersection([
  t.type({
    name: t.string,
    type: t.string,
  }),
  t.partial({
    configuration: t.record(t.string, t.unknown),
    supportsRecurring: t.boolean,
    brands: t.array(t.string),
    details: t.array(
      t.intersection([
        t.type({ key: t.string, type: t.string }),
        t.partial({ optional: t.boolean }),
      ])
    ),
  }),
]);

export const centAmountModel = t.type({
  type: t.keyof({ centPrecision: null }),
  currencyCode: t.string,
  centAmount: t.number,
  fractionDigits: t.number,
});

export const preciseAmountModel = t.type({
  type: t.keyof({ highPrecision: null }),
  currencyCode: t.string,
  preciseAmount: t.number,
  centAmount: t.number,
  fractionDigits: t.number,
});

export const amountModel = t.union([centAmountModel, preciseAmountModel]);

export const adyenAmountModel = t.type({
  currency: t.string,
  value: t.number,
});

export const transactionModel = t.intersection([
  t.type({
    id: t.string,
    type: transactionType,
    amount: amountModel,
  }),
  t.partial({
    timestamp: t.string,
    interactionId: t.string,
    state: transactionState,
  }),
]);

export const getPaymentMethodsRequestModel = t.type({
  countryCode: t.string,
  shopperLocale: t.string,
  amount: adyenAmountModel,
});

export const getPaymentMethodsResponseModel = t.intersection([
  t.type({
    paymentMethods: t.array(adyenPaymentMethodModel),
  }),
  t.partial({
    groups: t.array(t.type({ name: t.string, types: t.array(t.string) })),
  }),
]);

export const makePaymentRequestLineItem = t.intersection([
  t.type({
    amountIncludingTax: t.number,
    description: t.string,
    id: t.string,
    quantity: t.number,
    taxPercentage: t.number,
  }),
  t.partial({
    imageUrl: t.string,
    itemCategory: t.string,
    productUrl: t.string,
  }),
]);

export const shopperName = t.type({
  firstName: t.string,
  lastName: t.string,
});

export const avsAddress = t.type({
  street: t.string,
  houseNumberOrName: t.string,
  postalCode: t.string,
  city: t.string,
  stateOrProvince: t.string,
  country: t.string,
});

export const antiFraudCustomerData = t.partial({
  shopperEmail: t.string,
  shopperReference: t.string,
  billingAddress: avsAddress,
  deliveryAddress: avsAddress,
  telephoneNumber: t.string,
  shopperName,
});

export const createSessionRequestModel = t.type({
  merchantAccount: t.string,
  amount: adyenAmountModel,
  returnUrl: t.string,
  reference: t.string,
  countryCode: t.string,
  shopperLocale: t.string,
  shopperEmail: t.string,
  // Customer ID if available, otherwise Anonymous ID
  shopperReference: t.string,
});

export const createSessionResponseModel = t.type({
  id: t.string,
  merchantAccount: t.string,
  amount: adyenAmountModel,
  returnUrl: t.string,
  reference: t.string,
  sessionData: t.string,
});

export const makePaymentRequestModel = t.intersection([
  t.type({
    amount: adyenAmountModel,
    paymentMethod: t.intersection([
      t.type({ type: paymentMethodType }),
      t.record(t.string, t.unknown),
    ]),
    reference: t.string,
    channel: t.string,
    origin: t.string,
    merchantAccount: t.string,
    additionalData: t.record(t.string, t.union([t.string, t.boolean])),
  }),
  t.partial({
    returnUrl: t.string,
    browserInfo: t.record(t.string, t.unknown),
    countryCode: t.string,
    shopperLocale: t.string,
    lineItems: t.array(makePaymentRequestLineItem),
  }),
  antiFraudCustomerData,
]);

export const paymentErrorModel = t.type({
  status: t.number,
  errorCode: t.string,
  message: t.string,
  errorType: t.string,
});

export const paymentAuthorisedModel = t.intersection([
  t.type({
    pspReference: t.string,
    resultCode: t.keyof({ Authorised: null }),
    merchantReference: t.string,
  }),
  t.partial({ amount: adyenAmountModel }),
]);

export const paymentRejectedModel = t.intersection([
  t.type({
    pspReference: t.string,
    resultCode: t.string,
    refusalReason: t.string,
    refusalReasonCode: t.string,
    merchantReference: t.string,
  }),
  t.partial({ amount: adyenAmountModel }),
]);

export const paymentRefusedModel = t.type({
  pspReference: t.string,
  resultCode: t.keyof({ Refused: null }),
  merchantReference: t.string,
});

export const paymentCancelledModel = t.type({
  ...paymentRefusedModel.props,
  resultCode: t.keyof({ Cancelled: null }),
});

export const makePaymentResponseAdditionalActionModel = t.type({
  action: t.intersection([
    t.type({
      type: t.string,
      paymentMethodType: t.string,
    }),
    t.record(t.string, t.unknown),
  ]),
});

export const failedPaymentResponseModel = t.union([
  paymentErrorModel,
  paymentRejectedModel,
  paymentRefusedModel,
  paymentCancelledModel,
]);

export const makePaymentResponseModel = t.union([
  paymentAuthorisedModel,
  failedPaymentResponseModel,
  makePaymentResponseAdditionalActionModel,
]);

export const submitAdditionalPaymentDetailsResponseModel = t.union([
  paymentAuthorisedModel,
  failedPaymentResponseModel,
  t.record(t.string, t.unknown),
]);

export const paymentModel = t.exact(
  t.intersection([
    t.type({
      id: t.string,
      version: t.number,
      paymentMethodInfo: t.type({
        paymentInterface: t.string,
      }),
      custom: t.type({
        type: t.type({
          typeId: t.string,
          id: t.string,
        }),
        fields: t.intersection([
          t.type({
            cart: t.type({
              typeId: t.keyof({ cart: null }),
              id: t.string,
            }),
          }),
          t.partial({
            commercetoolsProjectKey: t.string,
            adyenMerchantAccount: t.string,
            getPaymentMethodsRequest: getPaymentMethodsRequestModel,
            getPaymentMethodsResponse: getPaymentMethodsResponseModel,
            makePaymentRequest: makePaymentRequestModel,
            makePaymentResponse: makePaymentResponseModel,
            submitAdditionalPaymentDetailsRequest: t.string,
            submitAdditionalPaymentDetailsResponse:
              submitAdditionalPaymentDetailsResponseModel,
          }),
        ]),
      }),
      transactions: t.array(transactionModel),
      amountPlanned: amountModel,
    }),
    t.partial({
      customer: t.type({
        typeId: t.keyof({ customer: null }),
        id: t.string,
      }),
    }),
  ])
);

// Literals
export type PaymentMethodType = t.TypeOf<typeof paymentMethodType>;
export type TransactionType = t.TypeOf<typeof transactionType>;

// Adyen Payment Submission Arguments
export type AdyenResponseDataModel = t.TypeOf<typeof adyenResponseDataModel>;
export type AdyenResponseModel = t.TypeOf<typeof adyenResponseModel>;

// Upstream Payment Methods Schemas
export type GetPaymentMethodsRequestModel = t.TypeOf<
  typeof getPaymentMethodsRequestModel
>;
export type GetPaymentMethodsResponseModel = t.TypeOf<
  typeof getPaymentMethodsResponseModel
>;

// Payment Submission Responses
export type PaymentErrorModel = t.TypeOf<typeof paymentErrorModel>;
export type PaymentAuthorisedModel = t.TypeOf<typeof paymentAuthorisedModel>;
export type PaymentRejectedModel = t.TypeOf<typeof paymentRejectedModel>;
export type PaymentRefusedModel = t.TypeOf<typeof paymentRefusedModel>;
export type MakePaymentResponseAdditionalActionModel = t.TypeOf<
  typeof makePaymentResponseAdditionalActionModel
>;
export type SubmitAdditionalPaymentDetailsResponseModel = t.TypeOf<
  typeof submitAdditionalPaymentDetailsResponseModel
>;

// Payment Session Schemas
export type CreateSessionRequestModel = t.TypeOf<
  typeof createSessionRequestModel
>;
export type CreateSessionResponseModel = t.TypeOf<
  typeof createSessionResponseModel
>;

// Payment Submission Schemas
export type MakePaymentRequestLineItem = t.TypeOf<
  typeof makePaymentRequestLineItem
>;
export type MakePaymentRequestModel = t.TypeOf<typeof makePaymentRequestModel>;
export type FailedPaymentResponseModel = t.TypeOf<
  typeof failedPaymentResponseModel
>;
export type MakePaymentResponseModel = t.TypeOf<
  typeof makePaymentResponseModel
>;
export type AvsAddress = t.TypeOf<typeof avsAddress>;
export type ShopperName = t.TypeOf<typeof shopperName>;
export type AntiFraudCustomerData = t.TypeOf<typeof antiFraudCustomerData>;

// Payment State Atom
export type PaymentModel = t.TypeOf<typeof paymentModel>;
