import type { TypedMoney } from '@commercetools/platform-sdk';
import type { PriceModel } from 'models/variants/types';

export const deriveCartItemDisplayPrice = (
  price?: TypedMoney
): PriceModel | null => {
  if (!price) {
    return null;
  }

  return {
    currencyCode: price.currencyCode,
    regularPrice: price.centAmount / Math.pow(10, price.fractionDigits),
    discountedPrice: price.centAmount / Math.pow(10, price.fractionDigits),
  };
};
