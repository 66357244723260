import * as t from 'io-ts';
import { linkModel } from 'models/contentful/link/types';
import { socialLinkModel } from 'models/contentful/socialLink/types';

export const footerModel = t.exact(
  t.type({
    socialLinks: t.array(socialLinkModel),
    columnOneTitle: t.string,
    columnOneLinks: t.array(linkModel),
    columnTwoTitle: t.string,
    columnTwoLinks: t.array(linkModel),
    joinTitle: t.string,
    joinDescription: t.string,
  }),
  'Footer'
);

export type FooterModel = t.TypeOf<typeof footerModel>;
