import unparsedCountries from 'countries.json';
import * as t from 'io-ts';
import { paymentMethodType } from 'models/payments/types';

export const language = t.keyof({
  en: null,
});

export const ometriaStoreId = t.keyof({
  cn: null,
  eu: null,
  gb: null,
  us: null,
  row: null,
  oce: null,
});

const currency = t.keyof({
  GBP: null,
  EUR: null,
  USD: null,
  CNY: null,
});

const storeId = t.keyof({
  'cn-store': null,
  'eu-store': null,
  'row-store': null,
  'uk-store': null,
  'us-store': null,
  'oce-store': null,
});

const country = t.interface({
  name: t.string,
  code: t.string,
  currency,
  displayCurrency: t.string,
  ometria: ometriaStoreId,
  store: storeId,
  paymentMethods: t.record(paymentMethodType, t.boolean),
});

export const localeModel = t.keyof(
  Object.keys(unparsedCountries).reduce<Record<string, null>>(
    (acc, countryCode) => {
      acc[`en-${countryCode}`] = null;
      return acc;
    },
    {}
  )
);

export const countryMap = t.record(t.string, country);

export type OmetriaStoreId = t.TypeOf<typeof ometriaStoreId>;
export type StoreId = t.TypeOf<typeof storeId>;
export type Language = t.TypeOf<typeof language>;
export type Currency = t.TypeOf<typeof currency>;
export type Country = t.TypeOf<typeof country>;
export type CountryMap = t.TypeOf<typeof countryMap>;
export type LocaleModel = t.TypeOf<typeof localeModel>;
