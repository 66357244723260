import * as t from 'io-ts';

export const flashBannerModel = t.exact(
  t.interface({
    title: t.string,
    link: t.string,
  }),
  'FlashBanner'
);

export type FlashBannerModel = t.TypeOf<typeof flashBannerModel>;
