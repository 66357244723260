import { Address, MyPayment } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  AvsAddress,
  PaymentModel,
  ShopperName,
  createSessionRequestModel,
  createSessionResponseModel,
  getPaymentMethodsRequestModel,
  getPaymentMethodsResponseModel,
  makePaymentRequestModel,
  makePaymentResponseModel,
  submitAdditionalPaymentDetailsResponseModel,
} from 'models/payments/types';

type PaymentDatumValidator =
  | typeof getPaymentMethodsRequestModel
  | typeof getPaymentMethodsResponseModel
  | typeof createSessionRequestModel
  | typeof createSessionResponseModel
  | typeof makePaymentRequestModel
  | typeof makePaymentResponseModel
  | typeof submitAdditionalPaymentDetailsResponseModel;

type AssignFieldsToModel = (args: {
  model: MyPayment;
  fields: Partial<PaymentModel['custom']['fields']>;
}) => PaymentModel;

export const toPaymentDatum = <T extends PaymentDatumValidator>(args: {
  field?: string;
  validator: T;
  id: string;
}): t.TypeOf<T> | undefined => {
  try {
    const parsed = JSON.parse([args.field].join(''));
    return pipe(
      (args.validator as t.Any).decode(parsed),
      fold(
        reportTypeErrors({
          id: args.id,
          model: args.validator.name,
          fallback: undefined,
        }),
        returnValidModel
      )
    );
  } catch (e) {
    return undefined;
  }
};

export const assignFieldsToModel: AssignFieldsToModel = ({ model, fields }) => {
  const intermediateModel = {
    ...model,
    custom: {
      ...model.custom,
      fields: {
        ...model.custom?.fields,
      },
    },
  } as PaymentModel;

  for (const key in fields) {
    const k = key as keyof PaymentModel['custom']['fields'];

    if (fields[k]) {
      (intermediateModel.custom.fields as Record<string, unknown>)[key] =
        fields[k];
    }
  }

  return intermediateModel;
};

export const deriveShopperName = (
  address?: Address | null
): ShopperName | undefined => {
  if (!address?.firstName || !address?.lastName) {
    return;
  }

  return {
    firstName: address.firstName,
    lastName: address.lastName,
  };
};

export const deriveAvsAddress = (
  address?: Address | null
): AvsAddress | undefined => {
  if (!address) {
    return;
  }

  const stateOrProvince: string = [
    address?.country.toLowerCase() === 'us' ? address.state : address?.region,
  ].join('');

  return {
    houseNumberOrName: [address.streetNumber].join(''),
    street: [address.streetName].join(''),
    postalCode: [address.postalCode].join(''),
    city: [address.city].join(''),
    country: address.country,
    stateOrProvince,
  };
};
