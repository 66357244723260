import type { TextInputProps } from 'components/Input/helpers';
import TextInput from 'components/Input/TextInput';
import { FC } from 'react';

type SearchInputProps = Omit<TextInputProps, 'type' | 'name'> &
  Partial<Pick<TextInputProps, 'name'>>;

const SearchInput: FC<SearchInputProps> = ({
  actionTitle = 'Search',
  name = 'search',
  ...props
}) => (
  <TextInput {...props} name={name} actionTitle={actionTitle} type="search" />
);

export default SearchInput;
