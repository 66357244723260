import Gap from 'components/Gap';
import { WarningFailureSmall } from 'components/Icons';
import { Statement } from 'components/Typography';
import { FC } from 'react';
import { toast as Toaster } from 'react-toastify';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background-color: ${theme.colors.pink};
    padding: 16px 32px;
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.1);
  `
);

export const ToastMessage: FC<{ message: string }> = ({ message }) => (
  <Wrapper>
    <WarningFailureSmall />
    <Gap size={8} />
    <Statement color="grey7" size="2XS" variant="1">
      {message}
    </Statement>
  </Wrapper>
);

export const notifyError = (message: string) => {
  Toaster.error(<ToastMessage message={message} />);
};
