import { MAXIMUM_CART_QUANTITY } from 'components/CartItem/helpers';
import { notifyError } from 'components/Toast';
import { CartItemModel } from 'models/cartItems/types';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { selectCartItem, selectCartProduct } from 'store/cart/selectors';
import { changeLineItemQuantity } from 'store/cart/thunks';
import { useDispatch, useSelector } from 'store/hooks';
import { Dispatch } from 'store/types';

interface CartQuantityAdjusterProps {
  id: string;
}

type ReduceToAvailableQuantity = (args: {
  dispatch: Dispatch;
  locale: string;
  lineItemId: string;
  name: string;
  quantityInCart: number;
  availableQuantity?: number;
  preorder?: boolean;
}) => () => void;

export const reduceToAvailableQuantity: ReduceToAvailableQuantity =
  ({
    dispatch,
    locale,
    lineItemId,
    name,
    quantityInCart,
    availableQuantity = 0,
    preorder,
  }) =>
  () => {
    const maximumAllowed = Math.min(availableQuantity, MAXIMUM_CART_QUANTITY);

    if (quantityInCart <= maximumAllowed) {
      return;
    }

    dispatch(
      changeLineItemQuantity({
        lineItemId,
        locale,
        quantity: maximumAllowed,
      })
    );

    const availableText = preorder ? 'available to preorder' : 'in stock';

    if (!availableQuantity) {
      notifyError(
        `${name} is no longer ${availableText}. We’ve removed this item from your bag.`
      );
      return;
    }

    if (availableQuantity === 1) {
      notifyError(
        `${name} has only 1 item ${availableText}. We’ve adjusted your total.`
      );
      return;
    }

    if (availableQuantity > MAXIMUM_CART_QUANTITY) {
      notifyError(
        `Only ${MAXIMUM_CART_QUANTITY} of ${name} can be added to your bag. We’ve adjusted your total.`
      );
      return;
    }

    notifyError(
      `${name} has only ${availableQuantity} items ${availableText}. We’ve adjusted your total.`
    );
  };

const CartQuantityAdjuster: FC<CartQuantityAdjusterProps> = ({ id }) => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const cartItem = useSelector(
    state => selectCartItem(state, { id }) as CartItemModel
  );
  const product = useSelector(state => selectCartProduct(state, { id }));

  const availableQuantity =
    product?.variants[cartItem.variantSku]?.availableQuantity;
  const preorder = product?.variants[cartItem.variantSku]?.preorder;

  useEffect(
    reduceToAvailableQuantity({
      dispatch,
      locale: [query.locale].join(''),
      lineItemId: id,
      name: cartItem.name,
      quantityInCart: cartItem.quantity,
      availableQuantity,
      preorder,
    }),
    [cartItem.quantity, availableQuantity, preorder]
  );

  return null;
};

export default CartQuantityAdjuster;
