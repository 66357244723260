import { Price, ShippingRate } from '@commercetools/platform-sdk';
import { PriceModel } from 'models/variants/types';
import { toPrice } from 'models/variants/utilities';

type ToShippingMethodPrice = (args: {
  shippingRates?: ShippingRate[];
  currency?: string;
}) => PriceModel | null;

export const toShippingMethodPrice: ToShippingMethodPrice = ({
  shippingRates = [],
  currency,
}) => {
  if (!currency) {
    return null;
  }

  const rate = shippingRates.find(r => r.price.currencyCode === currency);

  if (!rate) {
    return null;
  }

  return toPrice({ value: rate.price } as Price);
};
