export type ClientSideFeatureFlags = {
  isStaging: boolean;
  isPlacesEnabled: boolean;
  isAvalaraEnabled: boolean;
  isRerouterEnabled: boolean;
};

export type ServerSideFeatureFlags = {
  isPrivate: boolean;
};

export const getClientSideFeatureFlags = (): ClientSideFeatureFlags => {
  return {
    isStaging: process.env.NEXT_PUBLIC_DATA_MODE === 'staging',
    isPlacesEnabled: process.env.NEXT_PUBLIC_ENABLE_PLACES === 'true',
    isAvalaraEnabled: process.env.NEXT_PUBLIC_ENABLE_AVALARA === 'true',
    isRerouterEnabled: process.env.NEXT_PUBLIC_ENABLE_REROUTER === 'true',
  };
};

export const getAllFeatureFlags = (): ClientSideFeatureFlags &
  ServerSideFeatureFlags => {
  return {
    ...getClientSideFeatureFlags(),
    isPrivate: process.env.PRIVACY === 'private',
  };
};
