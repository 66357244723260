import { createGlobalStyle, css } from 'styled-components';

const MagentoContentStyles = createGlobalStyle(
  ({ theme: { bp, colors } }) => css`
    .c-information__wrapper {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: flex-start;
      margin: 0 auto;
    }

    .c-information__links {
      display: block;
      position: sticky;
      top: 0;
      flex-grow: 1;
      min-width: 376px;
      max-width: 376px;
      margin-right: 32px;
    }

    @media screen and (max-width: ${bp.l}px) {
      .c-information__links {
        display: none;
      }
    }

    .c-information__links__wrapper {
      border: 1px solid #c6c6c6;
    }

    .c-information__links__wrapper .items {
      color: #000;
      text-transform: uppercase;
      list-style: none;
      margin: 0;
      padding: 0 8px;
    }

    .c-information__links__wrapper .items .item {
      margin: 0;
      padding: 8px 0;
      border-bottom: 1px solid ${colors.grey2};
    }

    .c-information__links__wrapper .items .item:last-child {
      border-bottom: none;
    }

    .c-information__links__wrapper .items .item a {
      display: block;
      padding: 0 4px;
      text-decoration: none;
      color: ${colors.grey7};
    }

    .c-information__links__title {
      margin-top: 32px;
      padding: 0 0 16px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
    }

    .c-information__content {
      flex-grow: 1;
      color: ${colors.black};
      font-size: 12px;
      line-height: 18px;
      font-size: 14px;
      line-height: 1.25em;
      text-transform: uppercase;
      padding: 32px 0 0;
    }

    .c-information__content table {
      width: 100%;
      margin: 32px 0 16px;
    }

    .c-information__content p,
    .c-information__content li,
    .c-information__content th,
    .c-information__content td {
      text-transform: none;
    }

    .c-information__content a {
      text-decoration: underline;
    }

    .c-information__content h2 {
      margin: 0 !important;
      padding: 32px 0 8px !important;
    }

    .c-information__content > h2:first-child {
      padding-top: 0 !important;
    }

    .c-information__content a {
      color: ${colors.grey7};
    }

    .instagram-widget,
    .snpt-wdgt--ppg,
    .c-information__content h2:empty,
    .c-information__content p:empty {
      display: none;
    }
  `
);

export default MagentoContentStyles;
