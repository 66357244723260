import Button from 'components/Button';
import Gap from 'components/Gap';
import { Select } from 'components/Input';
import { Longform, Title } from 'components/Typography';
import {
  countries,
  countryList,
  getCountryFromPath,
  redirectToLocale,
} from 'lib/locale';
import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';
import { resetCart } from 'store/cart';
import { useDispatch, useSelector } from 'store/hooks';
import type { Dispatch } from 'store/types';
import styled, { css } from 'styled-components';

type RedirectAndChangeCartCountryOnCommit = (args: {
  dispatch: Dispatch;
  country: string;
  cartCountry: string;
}) => () => void;

export const redirectAndChangeCartCountryOnCommit: RedirectAndChangeCartCountryOnCommit =

    ({ dispatch, country, cartCountry }) =>
    () => {
      if (cartCountry.toLowerCase() !== country.toLowerCase()) {
        dispatch(resetCart());
      }
      redirectToLocale(country, global.window);
    };

const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 16px;
    max-width: 738px;

    ${theme.bp.m} {
      padding: 64px;
    }
  `
);

const StoreManager: FC = () => {
  const { asPath } = useRouter();
  const dispatch = useDispatch();
  const cartCountry = useSelector(state => state.cart.country);

  const currentCountryCode = getCountryFromPath(asPath, 'us');
  const [country, setCountry] = useState<string>(currentCountryCode);

  const onCommit = useCallback(
    redirectAndChangeCartCountryOnCommit({ dispatch, country, cartCountry }),
    [dispatch, country, cartCountry]
  );

  const currentCountry = countries[currentCountryCode];

  return (
    <Wrapper>
      <Title size="XS" variant="2">
        We ship worldwide
      </Title>
      <Gap size={16} />
      <Longform size="2XS" variant="1">
        {`You are currently in the ${currentCountry.name} (${currentCountry.displayCurrency}) store.`}
      </Longform>
      <Gap size={16} />
      <Longform size="2XS" variant="1">
        For international orders duties & taxes* are included.
      </Longform>
      <Gap size={16} />
      <Longform size="2XS" variant="1">
        To ship to a different location, please select from the list below.
      </Longform>
      <Gap size={24} />
      <Select
        name="country"
        value={country}
        onChange={setCountry}
        options={countryList.map(({ code, name, displayCurrency }) => ({
          value: code.toLowerCase(),
          label: `${name} (${displayCurrency})`,
        }))}
      />
      <Gap size={24} />
      <Button variant="primary" onClick={onCommit}>
        Update shipping location
      </Button>
    </Wrapper>
  );
};

export default StoreManager;
