import * as t from 'io-ts';

export const cartDiscountModel = t.intersection([
  t.interface({
    label: t.string,
    amount: t.string,
    id: t.string,
    order: t.number,
    name: t.string,
  }),
  t.partial({ target: t.string }),
]);

export const discountModel = t.interface({
  id: t.string,
  name: t.string,
  state: t.string,
  cartDiscounts: t.array(cartDiscountModel),
});

export type CartDiscountModel = t.TypeOf<typeof cartDiscountModel>;
export type DiscountModel = t.TypeOf<typeof discountModel>;
