import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import announcer from 'store/announcer';
import auth from 'store/auth';
import { AuthState } from 'store/auth/types';
import campaigns from 'store/campaigns';
import cart, { CartState } from 'store/cart';
import checkout from 'store/checkout';
import confirmation from 'store/confirmation';
import customer from 'store/customer';
import dimensions from 'store/dimensions';
import overlays from 'store/overlays';
import recent from 'store/recent';
import returns from 'store/returns';
import search from 'store/search';
import sidebar from 'store/sidebar';

// This value must be incremented each time a breaking change is made to persisted state.
const VERSION = 1;

const PERSISTED_AUTH_FIELDS: (keyof AuthState)[] = [
  'accessToken',
  'refreshToken',
  'anonymousId',
  'expiresAt',
];

const PERSISTED_CART_FIELDS: (keyof CartState)[] = ['id'];

const rootReducer = combineReducers({
  auth: persistReducer(
    {
      whitelist: PERSISTED_AUTH_FIELDS,
      key: 'auth',
      storage,
      version: VERSION,
    },
    auth
  ),
  campaigns,
  cart: persistReducer(
    {
      whitelist: PERSISTED_CART_FIELDS,
      key: 'cart',
      storage,
      version: VERSION,
    },
    cart
  ),
  recent: persistReducer(
    {
      key: 'recent',
      storage,
      version: VERSION,
    },
    recent
  ),
  checkout,
  customer,
  confirmation,
  dimensions,
  sidebar,
  overlays,
  search,
  returns,
  announcer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
