import * as t from 'io-ts';
import { attributeModel } from 'models/attributes/types';
import { cartDiscountModel } from 'models/discounts/types';
import { priceModel } from 'models/variants/types';

export const cartItemModel = t.type({
  lineItemId: t.string,
  productId: t.string,
  slug: t.string,
  name: t.string,
  quantity: t.number,
  price: priceModel,
  totalPrice: priceModel,
  subtotal: t.union([priceModel, t.null]),
  taxPercentage: t.number,
  amountIncludingTax: t.number,
  variant: t.number,
  variantSku: t.string,
  image: t.string,
  size: attributeModel,
  swatch: attributeModel,
  productType: t.string,
  isGift: t.boolean,
  isPreorder: t.boolean,
  isFinalSale: t.boolean,
  discounts: t.record(t.string, cartDiscountModel),
});

export const addExistingCartItemModel = t.type({
  action: t.keyof({ addLineItem: null }),
  productId: cartItemModel.props.productId,
  variantId: cartItemModel.props.variant,
  quantity: t.number,
});

export const addExistingCartItemsModel = t.array(addExistingCartItemModel);

export type CartItemModel = t.TypeOf<typeof cartItemModel>;
export type AddExistingCartItemsModel = t.TypeOf<
  typeof addExistingCartItemsModel
>;
