import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  retrieveProductsFromOrderRequest,
  returnProductsRequest,
} from 'store/returns/api';
import {
  DownstreamReturnsState,
  RetrieveProductsFromOrder,
  ReturnProducts,
} from 'store/returns/types';
import { ThunkApi } from 'store/types';

export const retrieveProductsFromOrder = createAsyncThunk<
  DownstreamReturnsState,
  RetrieveProductsFromOrder,
  ThunkApi
>('returns/retrieveProductsFromOrder', retrieveProductsFromOrderRequest);

export const returnProducts = createAsyncThunk<
  DownstreamReturnsState,
  ReturnProducts,
  ThunkApi
>('returns/returnProducts', returnProductsRequest);
