import { ProductListItem } from 'components/ProductList/ProductList';
import type {
  GTagWindow,
  Ga4Item,
  ProductDetails,
  UaCartProduct,
  UaCheckout,
  UaListingProduct,
  UaProduct,
  UaPurchase,
  UaRelatedProduct,
} from 'lib/gtag/helpers';
import type { CartItemModel } from 'models/cartItems/types';
import { LinkModel } from 'models/contentful/link/types';
import type { SizeModel } from 'models/productDetails/types';
import type { ProductListingModel } from 'models/productListing/types';
import { PriceModel } from 'models/variants/types';
import type { CartState } from 'store/cart';
import { ConfirmationState } from 'store/confirmation/types';

export enum FunnelStep {
  None,
  Bag,
  Login,
  Shipping,
  Payment,
}

export const productFromCartItem = (
  cartItem: CartItemModel
): UaCartProduct => ({
  id: cartItem.variantSku,
  name: cartItem.name,
  quantity: cartItem.quantity,
  variant: cartItem.size.value,
  price: cartItem.price.discountedPrice.toFixed(2),
});

const productfromOrderItem = (orderItem: CartItemModel): UaProduct => ({
  id: orderItem.variantSku,
  name: orderItem.name,
  quantity: orderItem.quantity,
  price: orderItem.price.discountedPrice?.toFixed(2),
});

export const createPurchase = (order: ConfirmationState): UaPurchase => ({
  currencyCode: order.displayPrice?.currencyCode as string,
  purchase: {
    actionField: {
      id: order.id,
      revenue: order.displayPrice?.discountedPrice.toFixed(2),
      shipping: order.shippingMethod?.price.discountedPrice.toFixed(2),
    },
    products: Object.values(order.items).map(productfromOrderItem),
  },
});

export const createCheckout = (
  cart: CartState,
  step: FunnelStep
): UaCheckout => ({
  currencyCode: cart.displayPrice?.currencyCode as string,
  checkout: {
    actionField: {
      revenue: cart.displayPrice?.discountedPrice.toFixed(2),
      step: step,
    },
    products: Object.values(cart.items).map(productFromCartItem),
  },
});

export const ga4CheckoutStep = (funnelStep: FunnelStep) => {
  switch (funnelStep) {
    case FunnelStep.Bag: {
      return 'view_cart';
    }
    case FunnelStep.Login: {
      return 'begin_checkout';
    }
    case FunnelStep.Shipping: {
      return 'begin_checkout'; // no separate step for this
    }
    case FunnelStep.Payment: {
      return 'add_shipping_info';
    }
    default:
      return 'begin_checkout';
  }
};

export const triggerCheckoutStep = (
  cartState: CartState,
  funnelStep: FunnelStep,
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    w.dataLayer.push({ ecommerce: null });

    w.dataLayer.push({
      event: 'checkout',
      checkout_style: 'native',
      ecommerce: createCheckout(cartState, funnelStep),
    });

    const promoCode: string = Object.values(cartState.discountCodes)
      .map(discount =>
        discount.cartDiscounts.map(cartDiscount => cartDiscount.name).join(', ')
      )
      .join(', ');

    w.dataLayer.push({
      event: ga4CheckoutStep(funnelStep),
      checkout_style: 'native',
      ecommerce: {
        value: cartState.displayPrice?.discountedPrice.toFixed(2) ?? '',
        shipping:
          cartState.shippingMethod?.price.discountedPrice.toFixed(2) ?? '',
        shipping_tier: cartState.shippingMethod?.name ?? '',
        currency: cartState.displayPrice?.currencyCode ?? '',
        tax: cartState.displayTax?.discountedPrice.toFixed(2) ?? '',
        coupon: promoCode,
        items: Object.values(cartState.items).map(ga4Product),
      },
    });
  }
};

const ga4Product = (product: CartItemModel) => ({
  currency: product.price.currencyCode,
  item_id: product.variantSku,
  item_name: product.name,
  price: product.price.discountedPrice.toFixed(2),
  quantity: product.quantity,
});

export const triggerPurchase = (
  order: ConfirmationState,
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    const orderPromoCode: string = Object.values(order.discountCodes)
      .map(discount =>
        discount.cartDiscounts.map(cartDiscount => cartDiscount.name).join(', ')
      )
      .join(', ');

    w.dataLayer.push({ ecommerce: null });

    w.dataLayer.push({
      event: 'purchase',
      checkout_style: 'native',
      ecommerce: createPurchase(order),
    });
    w.dataLayer.push({
      event: 'ga4purchase',
      checkout_style: 'native',
      ecommerce: {
        transaction_id: order.id,
        value: order.displayPrice?.discountedPrice.toFixed(2) ?? '',
        shipping: order.shippingMethod?.price.discountedPrice.toFixed(2) ?? '',
        shipping_tier: order.shippingMethod?.name ?? '',
        currency: order.displayPrice?.currencyCode ?? '',
        tax: order.taxPrice?.totalTax?.discountedPrice.toFixed(2) ?? '',
        coupon: orderPromoCode,
        items: Object.values(order.items).map(ga4Product),
      },
    });
  }
};

export const triggerAddToCart = (
  id: string,
  name: string,
  variantSku: string,
  price: PriceModel,
  quantity: number,
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    w.dataLayer.push({ ecommerce: null });
    w.dataLayer.push({
      event: 'addToCart',
      checkout_style: 'native',
      ecommerce: {
        currencyCode: price.currencyCode,
        add: {
          products: [
            {
              id: [variantSku].join(''),
              name,
              variant: [variantSku].join(''),
              quantity: quantity,
              price: price.discountedPrice.toFixed(2),
            },
          ],
        },
      },
    });
    w.dataLayer.push({
      event: 'add_to_cart',
      checkout_style: 'native',
      ecommerce: {
        currency: price.currencyCode,
        value: (price.discountedPrice * quantity).toFixed(2),
        items: [
          {
            currency: price.currencyCode,
            item_id: variantSku,
            item_name: name,
            price: price.discountedPrice.toFixed(2),
            quantity: quantity,
          },
        ],
      },
    });
  }
};

export const triggerRemoveFromCart = (
  id: string,
  name: string,
  variantSku: string,
  price: PriceModel,
  quantity: number,
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    w.dataLayer.push({ ecommerce: null });
    w.dataLayer.push({
      event: 'removeFromCart',
      checkout_style: 'native',
      ecommerce: {
        currencyCode: price.currencyCode,
        remove: {
          products: [
            {
              id: variantSku,
              name: name,
              variant: variantSku,
              price: price.discountedPrice.toFixed(2),
              quantity: quantity,
            },
          ],
        },
      },
    });
    w.dataLayer.push({
      event: 'remove_from_cart',
      checkout_style: 'native',
      ecommerce: {
        currency: price.currencyCode,
        value: (price.discountedPrice * quantity).toFixed(2),
        items: [
          {
            currency: price.currencyCode,
            item_id: variantSku,
            item_name: name,
            price: price.discountedPrice.toFixed(2),
            quantity: quantity,
          },
        ],
      },
    });
  }
};

export const triggerProductDetailsImpression =
  (
    product: SizeModel & { name: string },
    relatedProducts: ProductListItem[],
    win: Window | undefined
  ) =>
  (): void => {
    const w = win as GTagWindow | undefined;

    if (!w?.dataLayer?.push) {
      return;
    }

    const uaRelatedProducts: UaRelatedProduct[] = relatedProducts.map(
      ({ listing }, index) => ({
        id: listing.sku,
        name: listing.name,
        price: listing.price.discountedPrice.toFixed(2),
        position: index + 1,
        list: 'Related Products' as const,
      })
    );

    const productDetails: ProductDetails = {
      id: product.sku,
      name: product.name,
      price: product.price.discountedPrice.toFixed(2),
    };

    w.dataLayer.push({ ecommerce: null });
    w.dataLayer.push({
      event: 'productDetailsImpression',
      checkout_style: 'native',
      ecommerce: {
        currencyCode: product.price.currencyCode,
        impressions: uaRelatedProducts,
        detail: {
          products: [productDetails],
        },
      },
    });

    const ga4Item: Ga4Item = {
      currency: product.price.currencyCode,
      item_id: product.sku,
      item_name: product.name,
      price: product.price.discountedPrice.toFixed(2),
      quantity: 1,
    };
    w.dataLayer.push({
      event: 'view_item',
      checkout_style: 'native',
      ecommerce: {
        items: [ga4Item],
      },
    });
  };

export const triggerProductListingImpression =
  (
    products: ProductListingModel[],
    category: string,
    list: string,
    win: Window | undefined
  ) =>
  () => {
    const w = win as GTagWindow | undefined;

    if (!Array.isArray(products) || products.length === 0) {
      return;
    }

    if (!w?.dataLayer?.push) {
      return;
    }

    const uaListingProducts: UaListingProduct[] = products.map(
      (product, index) => ({
        id: product.sku,
        name: product.name,
        price: product.price.discountedPrice.toFixed(2),
        position: index + 1,
        category,
        list,
      })
    );

    w.dataLayer.push({ ecommerce: null });
    w.dataLayer.push({
      event: 'productListingImpression',
      checkout_style: 'native',
      ecommerce: {
        currencyCode: products[0].price.currencyCode,
        impressions: uaListingProducts,
      },
    });

    const ga4ProductListing: Ga4Item[] = products.map((product, i) => ({
      currency: products[0].price.currencyCode,
      item_id: product.sku,
      item_name: product.name,
      item_category: category,
      price: product.price.discountedPrice.toFixed(2),
      quantity: 1,
      index: i,
      item_list_name: list,
    }));

    w.dataLayer.push({
      event: 'view_item_list',
      checkout_style: 'native',
      ecommerce: {
        items: ga4ProductListing,
      },
    });
  };

export const triggerSearch = (searchTerm: string, win: Window | undefined) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    w.dataLayer.push({
      event: 'search',
      search_term: searchTerm,
    });
  }
};

export const triggerMainNavigation = (
  { link, title }: LinkModel,
  path: string[],
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    const fullPath = path.concat([title]);
    w.dataLayer.push({
      event: 'navigation',
      nav_category: fullPath[0],
      nav_sub_category: fullPath[1],
      item_list_name: fullPath[2],
      destination_url: `${w.location.origin}${link}`,
    });
  }
};

export const triggerSelectContent = (
  contentType: string,
  contentName: string,
  { link, title }: LinkModel,
  win: Window | undefined
) => {
  const w = win as GTagWindow | undefined;

  if (typeof w !== 'undefined' && w.dataLayer?.push) {
    w.dataLayer.push({
      event: 'select_content',
      content_type: contentType,
      content_name: contentName,
      call_to_action: title,
      destination_url: `${w.location.origin}${link}`,
    });
  }
};
