import unparsedCountries from 'countries.json';
import { Country, countryMap, CountryMap } from 'models/locales/types';

export const parseCountryMap = (c: unknown): CountryMap => {
  if (countryMap.is(c)) {
    return c;
  }

  throw new TypeError('Invalid format in countries.json');
};

export const validateCountryFactory =
  (countries: CountryMap) =>
  <T extends string | undefined>(
    country: unknown,
    fallback?: T
  ): string | T => {
    if (typeof country === 'string' && countries[country]) {
      return country;
    }

    return fallback as T;
  };

export const getCountryList = (countries: CountryMap): Country[] => {
  const { us, gb, ...row } = countries;

  return [us, gb].concat(
    Object.values(row).sort((a: Country, b: Country) =>
      a.name.localeCompare(b.name)
    )
  );
};

export const getCountrySelectOptionsFactory =
  (countries: Record<string, Country>, countryList: Country[]) =>
  (countryFromWhichToDeriveStore = '') => {
    const store = countries[countryFromWhichToDeriveStore.toLowerCase()]?.store;

    return countryList.reduce<{ label: string; value: string }[]>(
      (acc, country) => {
        if (!store || country.store === store) {
          acc.push({ label: country.name, value: country.code });
        }

        return acc;
      },
      [{ label: 'Please select', value: '' }]
    );
  };

export const defaultCountry = 'us';
export const countries = parseCountryMap(unparsedCountries);
export const countryList = getCountryList(countries);
export const validateCountry = validateCountryFactory(countries);
export const getCountrySelectOptions = getCountrySelectOptionsFactory(
  countries,
  countryList
);
