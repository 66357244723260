import fetch from 'cross-fetch';
import { dot } from 'dot-object';
import { ometriaIdentify } from 'lib/ometria';
import {
  CampaignFormValues,
  CampaignValues,
  SegmentIds,
} from 'store/campaigns/types';

export const OMETRIA_DATE_FORMAT = 'yyyy-MM-dd';

const DEFAULT_CAMPAIGN_VALUES = {
  __form_id: '8b0543f94465412d8905eb7b5c57393b',
  email: '',
  __email: '',
  '@return_url': '',
  '@subscription_status': 'SUBSCRIBED',
  ue: '',
  firstname: '',
  lastname: '',
  pm_form: '',
  submit: 'Submit form',
} as const;

const getSegmentIds = (): SegmentIds => {
  if (
    typeof process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_MEN === 'undefined' ||
    typeof process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_WOMEN === 'undefined' ||
    typeof process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_KIDS === 'undefined'
  ) {
    console.error('Segment ids env variables missing');
  }

  return {
    men: process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_MEN
      ? `${process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_MEN}`
      : '9997',
    women: process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_WOMEN
      ? `${process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_WOMEN}`
      : '9998',
    kids: process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_KIDS
      ? `${process.env.NEXT_PUBLIC_OMETRIA_SEGMENT_IDS_KIDS}`
      : '9999',
  };
};

export const joinCampaignRequest = async (values: CampaignFormValues) => {
  ometriaIdentify(values.ue);

  const segmentIds = getSegmentIds();

  const preferenceValues = {
    '@add_to_lists': values.preferences
      ? values.preferences.map((key: keyof SegmentIds) =>
          Number(segmentIds[key])
        )
      : undefined,
    men: values.preferences?.includes('men') ? 1 : undefined,
    women: values.preferences?.includes('women') ? 1 : undefined,
    kids: values.preferences?.includes('kids') ? 1 : undefined,
    preferences: undefined,
  };

  const smsValues = {
    phone_number: values.phone_number === '' ? undefined : values.phone_number,
  };

  const campaignParameters: CampaignValues = {
    ...DEFAULT_CAMPAIGN_VALUES,
    ...values,
    ...preferenceValues,
    ...smsValues,
    '@account': process.env.NEXT_PUBLIC_OMETRIA_ACCOUNT as string,
  };

  Object.keys(campaignParameters).forEach(key => {
    if (campaignParameters[key as keyof CampaignValues] === undefined)
      delete campaignParameters[key as keyof CampaignValues];
  });

  const body = new URLSearchParams(dot(campaignParameters)).toString();

  try {
    await fetch('https://api.ometria.com/forms/signup/ajax', {
      body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
  } catch (error) {
    throw error;
  }
};
