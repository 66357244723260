import { commerceToolsAuth } from 'lib/commerceTools';
import { commitAuthCredentials } from 'store/auth';
import { WithAuth } from 'store/auth/types';

export const withAuth: WithAuth =
  ({ getState, dispatch }, date = new Date()) =>
  async callback => {
    const { auth } = getState();

    if (auth.expiresAt > date.getTime()) {
      try {
        return await callback();
      } catch (e) {}
    }

    if (auth.refreshToken) {
      try {
        const response = await commerceToolsAuth.refreshTokenFlow(
          auth.refreshToken
        );

        dispatch(
          commitAuthCredentials({
            accessToken: response.access_token,
            expiresAt: response.expires_at,
          })
        );

        return await callback();
      } catch (e) {}
    }

    const response = await commerceToolsAuth.anonymousFlow(auth.anonymousId);

    dispatch(
      commitAuthCredentials({
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresAt: response.expires_at,
        anonymousId: auth.anonymousId,
      })
    );

    return await callback();
  };
