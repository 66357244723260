import { ProductProjection } from '@commercetools/platform-sdk';
import search from 'lib/commerceTools/search';
import { exclude } from 'lib/util';

type SearchBySkus = (args: {
  skus: string[];
  locale: string;
  exclude?: string[];
  allowOutOfStock?: boolean;
}) => Promise<Record<string, ProductProjection>>;

const searchBySkus: SearchBySkus = async ({
  skus,
  locale,
  exclude: excludedSkus = [],
  allowOutOfStock = false,
}) => {
  const keys = exclude(skus, excludedSkus);

  if (!keys.length) {
    return {};
  }

  const results = await search({
    query: {
      'filter.query': [`key:${keys.map(key => `"${key}"`).join(',')}`],
    },
    locale,
    allowOutOfStock,
  });

  return results;
};

export default searchBySkus;
