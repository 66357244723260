import {
  CartDiscountValueDraft,
  CentPrecisionMoney,
  TypedMoney,
} from '@commercetools/platform-sdk';
import { formatAsCurrency } from 'lib/util';

export const deriveDiscountAmount = (
  value: CartDiscountValueDraft,
  currency: string
): string => {
  switch (value.type) {
    case 'relative':
      return `-${value.permyriad / 100}%`;
    case 'absolute':
    case 'fixed':
      const price = (value.money as CentPrecisionMoney[]).find(
        v => v.currencyCode === currency
      ) as TypedMoney;
      return `-${formatAsCurrency(
        currency,
        (price?.centAmount ?? 0) / Math.pow(10, price?.fractionDigits ?? 2)
      )}`;
    default:
      return '';
  }
};
