import * as t from 'io-ts';

export const productListModel = t.exact(
  t.type({
    listTitle: t.string,
    skus: t.array(t.string),
  }),
  'ProductList'
);

export type ProductListModel = t.TypeOf<typeof productListModel>;
