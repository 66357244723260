import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useId,
} from 'react';

interface UseTextInputArgs {
  action?: (value: string) => void;
  onChange: (value: string) => void;
}

interface UseTextInputResult {
  htmlFieldId: string;
  changeEvent: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  clearEvent: MouseEventHandler<HTMLButtonElement | HTMLSelectElement>;
  actionEvent: (value: string) => MouseEventHandler<HTMLButtonElement>;
}

type UseTextInput = (args: UseTextInputArgs) => UseTextInputResult;

const useTextInput: UseTextInput = ({ action, onChange }) => {
  const htmlFieldId = useId();

  const changeEvent: UseTextInputResult['changeEvent'] = useCallback(
    event => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const clearEvent: UseTextInputResult['clearEvent'] = useCallback(() => {
    onChange('');
  }, [onChange]);

  const actionEvent: UseTextInputResult['actionEvent'] = useCallback(
    value => () => {
      action?.(value);
    },
    [action]
  );

  return {
    htmlFieldId,
    changeEvent,
    clearEvent,
    actionEvent,
  };
};

export default useTextInput;
